import React from 'react'
import { connectMenu } from 'react-instantsearch-dom'
import I18n from '@/src/i18n.js.erb'

import FilterBubble from '../common/FilterBubble'
import RadioCircle from '../common/RadioCircle'

const CategoryFilter = ({ items, currentRefinement, refine, translations }) => {
  const click = item => e => {
    e.preventDefault()

    refine(item.value)
  }

  const reset = e => {
    e.preventDefault()

    refine(null)
  }

  const totalCount = items.reduce((count, item) => count + item.count, 0)

  return <>
    <ul className='algolia-menu'>

      <li>
        <a hreF={''} style={{ fontWeight: currentRefinement ? '' : 'bold' }} onClick={reset}>
          <div className='d-flex align-items-center justify-content-between flex-greed'>
            <div>
              <RadioCircle small current={!currentRefinement} />
              {(translations && translations['all']) || I18n.t('frontend.general.all')}
            </div>
            <div className='refinement-list__count text-small'>{totalCount}</div>
          </div>
        </a>
      </li>

      {items.map(item => {
        return (
          <>
            <li key={item.value}>
              <a href={'#'} style={{ fontWeight: item.isRefined ? 'bold' : '' }} onClick={click(item)}>
                <div className='d-flex align-items-center justify-content-between flex-greed'>
                  <div>
                    <RadioCircle small current={item.isRefined} />
                    {translations ? translations[item.label] : item.label}
                  </div>
                  <div className='refinement-list__count text-small'>{item.count}</div>
                </div>
              </a>
            </li>
          </>
        )
      })}
    </ul>
  </>
}

export default connectMenu(CategoryFilter)
