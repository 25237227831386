import React, { useState, useEffect, useRef } from 'react'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import Rails from '@rails/ujs'

import useAlgoliaClient from './hooks/useAlgoliaClient'
import useUrlSearchState from './InquiriesIndex/hooks/useUrlSearchState'

import I18n from '@/src/i18n.js.erb'

import FlashProvider from './common/FlashProvider'
import FilterOption from './MyOffersIndex/FilterOption'
import CategoryFilter from './MyOffersIndex/CategoryFilter'
import ClearRefinements from './algolia/ClearRefinements'
import RefinementList from "./algolia/RefinementList"
import SearchContextProvider from './OffersIndex/SearchContextProvider'
import PaymentMethodFilter from './InquiriesIndex/PaymentMethodFilter'
import MixedFilter from './InquiriesIndex/MixedFilter'

import SearchBox from './algolia/SearchBox'
import Pagination from './algolia/Pagination'

import Hits from './InquiriesIndex/Hits'
import ActiveFilter from './InquiriesIndex/ActiveFilter'

const ROLES = {
  buyer: "buyer",
  vendor: "vendor"
}

const baseUrl = role => {
  if (role === ROLES.vendor) return '/vendor/inquiries'

  return '/inquiries'
}

const filterOptionsByRole = (userId, role) => {
  if (role === ROLES.buyer) return `buyer_id:${userId}`

  return `vendor_id:${userId}`
}

const InquiriesIndex = ({ appId, apiKey, defaultIndex, locale, isAdmin, userId, role, offerNumber }) => {
  const searchClient = useAlgoliaClient(appId, apiKey)
  const [state, stateChanged] = useUrlSearchState(locale, baseUrl(role))
  const [refresh, setRefresh] = useState(false)
  const [clearedIssues] = useState(false)
  const setFilter = useRef(null)
  const isVendor = role === ROLES.vendor
  let filters = 'NOT status:user_not_approved AND ' + filterOptionsByRole(userId, role)

  if (offerNumber) { filters = `${filters} AND offer_number:"${offerNumber}"` }

  useEffect(() => {
    if (refresh) setRefresh(false)
  })

  const refreshSearch = () => {
    setTimeout(() => setRefresh(true), 1000)
  }

  const closeInquiry = id => {
    Rails.ajax({
      type: 'POST',
      url: '/' + I18n.locale + '/vendor/inquiries/' + id + '/denial',
      data: 'inquiry[denial_reason]=between',
      success: () => { refreshSearch() },
      error: () => { window.alert('An error occured :(') }
    })
  }

  return <FlashProvider>
    <InstantSearch searchClient={searchClient} indexName={defaultIndex} searchState={state} onSearchStateChange={stateChanged} refresh={refresh}>
      <Configure hitsPerPage={100} filters={filters} />
      <div className="row my-offers">
        <div className="col-md-3 col-xl-2">
          <div className='my-offers__filter filters'>
            <SearchContextProvider>
              <FilterOption label={'Status'} collapsible>
                <ActiveFilter role={role} attribute='filter' withIssues={role === 'vendor'} clearedIssues={clearedIssues} setFilter={setFilter} />
              </FilterOption>

              <FilterOption label={'Payments'} collapsible>
                <PaymentMethodFilter attribute='payment_method' setFilter={setFilter} />
                <MixedFilter attribute='mixed_filters' isVendor={isVendor} />
              </FilterOption>

              <FilterOption label={I18n.t("frontend.my_offers_index.filter.category")} collapsible>
                <CategoryFilter attribute="offers.offer_type" translations={I18n.t("frontend.my_offers_index.filter")} />
              </FilterOption>

              {isVendor && <FilterOption label={I18n.t("frontend.my_offers_index.filter.warehouse")} collapsible>
                <RefinementList attribute='offers.warehouse_name' />
              </FilterOption>}

              <FilterOption label={I18n.t("frontend.my_offers_index.filter.brand")} collapsible>
                <RefinementList searchable attribute='offers.producer_name' limit={100} maxItems={8} />
              </FilterOption>

              <ClearRefinements>
                <button className='btn d-block mt-4 my-offers__filter-clear'>{I18n.t("frontend.my_offers_index.filter.clear_all")}</button>
              </ClearRefinements>
            </SearchContextProvider>
          </div>
        </div>
        <div className="col-md-9 col-xl-10">
          <div className='row mb-4'>
            <div className='col-md-8'>
              <SearchBox />
            </div>
          </div>

          <Hits locale={locale} role={role} isAdmin={isAdmin} closeInquiry={closeInquiry} clearedIssues={clearedIssues} />
          <Pagination />
        </div>
      </div>

    </InstantSearch>
  </FlashProvider>
}

export default InquiriesIndex
