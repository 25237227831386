import React           from 'react'
import { connectHits } from 'react-instantsearch-dom'

import useHitsScrollPos from '../hooks/useHitsScrollPos'

import NoResults from './NoResults'

const Hits = ({hits, Hit, colspan = null, ...props}) => {
  useHitsScrollPos(hits)

  if(hits.length > 0)
    return hits.map(hit => <Hit key={hit.uuid} hit={hit} {...props} />)
  else
    return <NoResults clearsQuery colspan={colspan} />
}

export default connectHits(Hits)
