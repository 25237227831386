import algoliaHelper from "algoliasearch-helper";
import { connectStateResults } from "react-instantsearch-dom";

let currentSerializedState = null

const serializeSearchState = (searchClient, searchResults) => {
  const state = searchResults?._state
  if (!state) { return null }

  const helper = algoliaHelper(searchClient, state.index, state)
  const rawQuery = helper.getQuery()

  return rawQuery
}

const SearchResultSerializer = ({searchResults, searchClient}) => {
  const serializedState = serializeSearchState(searchClient, searchResults)

  if (serializedState !== currentSerializedState) {
    currentSerializedState = serializedState

    const targetElement = document.querySelector("[data-saved-search-query-state]")
    if (!targetElement) { return }

    targetElement.dataset.savedSearchQueryState = JSON.stringify(serializedState)
  }
}

export default connectStateResults(SearchResultSerializer)
