import React from 'react'
import { InstantSearch, Configure } from 'react-instantsearch-dom'

import useAlgoliaClient from './hooks/useAlgoliaClient'
import useUrlSearchState from './hooks/useUrlSearchState'

import FilterOption from './OffersIndex/FilterOption'
import CategoryFilter from './OffersIndex/CategoryFilter'
import SearchContextProvider from './OffersIndex/SearchContextProvider'

import Hits from './FavoritesIndex/Hits'

const CATEGORY_ATTRIBUTES = ['categories.lvl0', 'categories.lvl1', 'categories.lvl2']

const FavoritesIndex = ({ appId, apiKey, index, locale, rootCategories, categorySlugs = {}, initialState = {}, favoriteUuids = [], fixedCategory = null }) => {
  const searchClient = useAlgoliaClient(appId, apiKey)
  const [state, stateChanged] = useUrlSearchState('/favorites', categorySlugs, initialState)

  const filters = favoriteUuids.map(uuid => `uuid:${uuid}`).join(' OR ')

  return <InstantSearch searchClient={searchClient} indexName={index} searchState={state} onSearchStateChange={stateChanged}>
    <Configure hitsPerPage={100} filters={filters} distinct />

    <SearchContextProvider searchState={state} categorySlugs={categorySlugs}>
      <div className='row'>
        <div className='col-md-4'>
          <div className='filters is--expanded mb-3'>
            <FilterOption>
              <CategoryFilter attributes={CATEGORY_ATTRIBUTES} rootCategories={rootCategories} fixedCategory={fixedCategory} />
            </FilterOption>
          </div>
        </div>
        <div className='col-md-8'>
          <Hits locale={locale} view='card' />
        </div>
      </div>
    </SearchContextProvider>
  </InstantSearch>
}

export default FavoritesIndex
