import { baseApi } from "./base/base";

export const commentsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createComment: builder.mutation({
      query: (data) => ({ url: `/comments`, method: 'POST', body: data }),
      invalidatesTags: ['Comment', 'Ticket']
    }),
  }),
  overrideExisting: false
})

export const { useCreateCommentMutation } = commentsApi;
