import { ResponsiveContainer, ComposedChart, CartesianGrid, Area, Line, XAxis, YAxis, Tooltip } from 'recharts'

const CustomTooltip = ({ active, label, payload }) => {
  if(active && payload && payload.length) return <div className='product-price-index-tooltip'>
    <div className='text-small text-muted'>{label}</div>
    <div className='price'>{payload[1].value}€</div>
    <div className='quantity'>{payload[0].value} Pcs.</div>
  </div>
}

function calculateDomainForQuantity([ dataMin, dataMax ]) {
  return [dataMin - dataMin * 0.025, dataMax * 1.025]
}

function calculateDomainForPrice([ dataMin, dataMax ]) {
  return [dataMin - dataMin * 0.025, dataMax * 1.025]
}

function findPriceTicks(values) {
  let min = values[0].p
  let max = values[0].p

  for(const value of values) {
    if(value.p < min) min = value.p
    if(value.p > max) max = value.p
  }

  return [min, max]
}

const ProductPriceIndexChart = ({ data, ...props }) => {
  if(!data || data.length === 0) return null
  const type = 'monotone'
  const priceTicks = findPriceTicks(data);

  return <ResponsiveContainer {...props}>
    <ComposedChart data={data}>
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="50%" stopColor="#3386C3" stopOpacity={0.3}/>
          <stop offset="95%" stopColor="#3386C3" stopOpacity={0.1}/>
        </linearGradient>
      </defs>

      <CartesianGrid stroke="#CCC" strokeDasharray="3 3" />
      <Tooltip position={{y: 10}} content={<CustomTooltip />} />
      <Area name='Quantity' yAxisId='quantity' type={type} dataKey='q' stroke='#3386C3' fill="url(#colorUv)" strokeWidth={1} dot={false} />
      <Line name='Price' yAxisId='price' type={type} dataKey='p' stroke='orange' fillOpacity={1} strokeWidth={1} dot={false} />

      <XAxis hide dataKey="date" />
      <YAxis yAxisId='price' ticks={priceTicks} domain={calculateDomainForPrice} style={{fontSize: '12px'}} unit='€' />
      <YAxis hide yAxisId='quantity' domain={calculateDomainForQuantity} />
    </ComposedChart>
  </ResponsiveContainer>
}

export default ProductPriceIndexChart
