import { useEffect } from 'react'

const useHitsScrollPos = hits => useEffect(() => {
  if(hits.length > 0 && window.prevScrollY) {
    window.scroll(0, window.prevScrollY)
    window.prevScrollY = null
  }
}, [hits])

export default useHitsScrollPos
