import React, { useState } from 'react'
import dayjs from 'dayjs/esm/index.js'

import { expandOfferObject } from '../helpers'

import SolarModuleHit from './SolarModuleHit'
import InverterHit    from './InverterHit'
import BatterHit      from './BatteryHit'
import AccessoryHit   from './AccessoryHit'

const actualOffers = offers => {
  const actualOffers = []

  const now = dayjs()
  const today  = now.startOf('day')
  const now_ts = now.unix()

  for(const o of offers) {
    const offer = expandOfferObject(o)

    if(!offer.price_ranges || offer.price_ranges.length === 0) continue
    if(offer.published_until_ts < now_ts) continue

    if(!offer.in_stock) {
      offer.available_starting_at = now.unix(offer.available_starting_at_ts)
      offer.in_stock = offer.available_starting_at <= today
    }

    actualOffers.push(offer)
  }

  return actualOffers
}

const Hit = ({ hit, ...props }) => {
  const [ memoizedHit ] = useState({...hit, offers: actualOffers(hit.offers)})

  switch(hit.offer_type) {
    case 'solar_module': return <SolarModuleHit hit={memoizedHit} {...props} />
    case 'inverter':     return <InverterHit hit={memoizedHit} {...props} />
    case 'battery':      return <BatterHit hit={memoizedHit} {...props} />
    case 'accessory':    return <AccessoryHit hit={memoizedHit} {...props} />
  }
}

export default Hit
