import React from 'react'

import I18n from '@/src/i18n.js.erb'

import ProductImage from '../common/ProductImage'
import FormattedPrice from '../common/FormattedPrice'

import Availability from './Availability'

const InquiryOffers = ({ inquiry }) => {
  // const offers = [{
  //   offer_type: inquiry.offer_type,
  //   product_image: inquiry.product_image,
  //   product_name: inquiry.product_name,
  //   producer_name: inquiry.producer_name,
  //   potential_id: inquiry.potential_id,
  //   quantity: inquiry.quantity,
  //   quantity_kw: inquiry.quantity_kw,
  //   available_starting_at_ts: inquiry.available_starting_at_ts,
  //   in_stock: inquiry.in_stock,
  //   stock: inquiry.stock,
  //   single_price: inquiry.single_price
  // }]

  return inquiry.offers.map((offer, i) => {
    // Explicitly no three === comparison operator, as we want to catch everything that is more-or-less zero.
    const actualVatRate = (inquiry.buyer_vat_rate || 0)
    const showExclVatHint = actualVatRate != 0
    const single_price = parseFloat(offer.single_price)
    let total_price

    if (offer.offer_type === "solar_module") {
      total_price = offer.quantity_kw * 1000 * single_price
    } else {
      total_price = offer.quantity * single_price
    }

    return <div key={offer.potential_id}>
      <div className='row'>
        <div className='col-10-percent'>
          <ProductImage hit={offer} width='80' height='100' />
        </div>

        <div className='col-30-percent pl-5'>
          <div className='card__value text-truncate'>
            {offer.product_name}
          </div>
          <div className='card__value card__value--bold text-truncate'>
            {offer.producer_name}
          </div>
          <div className='card__value card__value--rounded'>
            {offer.offer_number}
          </div>
        </div>

        <div className='col-20-percent '>
          <div className='card__value'>
            <span className='card__value--large card__value--bold'>{offer.quantity}</span> {I18n.t('frontend.general.pcs')}
            {offer.quantity_kw && <> ({offer.quantity_kw.toFixed(2).replace(',', '.')} kWp)</>}
          </div>
          <div className='card__value'>
            <Availability hit={offer} />
          </div>
        </div>

        <div className='col-20-percent'>
          <div className='card__value'><FormattedPrice price={offer.single_price} precision={offer.offer_type === 'solar_module' ? 3 : 2} per={offer.offer_type === 'solar_module' ? '/Wp' : ''} /></div>
          <div className='card__desc'>{I18n.t('frontend.inquiries_index.per_unit')}</div>
        </div>
        <div className='card-payment-status col-20-percent text-right'>
          <div className='card__value--large card__value--bold'>
            <FormattedPrice price={total_price} />
          </div>
          <div className='d-flex align-items-center justify-content-end flex-gap-1'>
            {showExclVatHint && <span>{I18n.t('frontend.inquiries_index.hit.excl_vat')}</span>}
            <span>{actualVatRate}%</span>
            <span>{I18n.t('frontend.inquiries_index.hit.vat')}</span>
          </div>
        </div>
      </div>

      {i < inquiry.offers.length - 1 && <hr />}
    </div>
  })
}

export default InquiryOffers
