import React, { useEffect } from 'react'
import { connectToggleRefinement, ClearRefinements } from 'react-instantsearch-dom'
import I18n from '@/src/i18n.js.erb'

import Checkbox from '../common/Checkbox'

const ToggleRefinementCheckbox = ({ currentRefinement, refine, value, count, selected, setSelected, selectedFilter }) => {
  const toggle = () => {
    setSelected(!selected);

    if (currentRefinement)
      refine(null)
    else {
      refine(value);
    }
  }

  useEffect(() => {
    refine(null);
  }, [selectedFilter])

  return <ul class='refinement-list'>
    <li>
      <Checkbox checked={currentRefinement} onChange={toggle}>
        <div className='d-flex align-items-center justify-content-between flex-greed'>
          <div className='truncate'>{I18n.t('frontend.my_offers_index.filter.archived')}</div>
          <div className='refinement-list__count text-small'>{count.unchecked || 0}</div>
        </div>
      </Checkbox>
    </li>
  </ul>
}

export default connectToggleRefinement(ToggleRefinementCheckbox)
