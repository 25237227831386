import React from "react"
import I18n from "@/src/i18n.js.erb"

const ActionsDropdownContent = ({ republish, deactivate, destroy, archive = null, additionalDropdownClassName = "", useExternalDisplayControl = false, isOpen = false }) => {
  const openCssClass = useExternalDisplayControl && isOpen ? "show" : ""

  return <div className={`dropdown-menu ${additionalDropdownClassName} ${openCssClass}`}>
    {republish && <>
      <a href="#" className="dropdown-item" onClick={republish(1)}>{I18n.t("frontend.my_offers_index.actions.republish_1_week")}</a>
      <a href="#" className="dropdown-item" onClick={republish(2)}>{I18n.t("frontend.my_offers_index.actions.republish_2_week")}</a>
      <a href="#" className="dropdown-item" onClick={republish(3)}>{I18n.t("frontend.my_offers_index.actions.republish_3_week")}</a>
      <a href="#" className="dropdown-item" onClick={republish(4)}>{I18n.t("frontend.my_offers_index.actions.republish_4_week")}</a>
    </>}

    {(republish && (deactivate || destroy || archive)) && <div class="dropdown-divider"></div>}

    {deactivate && <>
      <a href="#" className="dropdown-item dropdown-item-warning" onClick={deactivate}>{I18n.t("frontend.my_offers_index.actions.deactivate")}</a>
    </>}

    {destroy && <>
      <a href="#" className="dropdown-item dropdown-item-danger" onClick={destroy}>{I18n.t("frontend.my_offers_index.actions.delete")}</a>
    </>}

    {archive && <>
      <a href="#" className="dropdown-item dropdown-item-danger" onClick={archive}>{I18n.t("frontend.my_offers_index.actions.archive")}</a>
    </>}
  </div>
}

export default ActionsDropdownContent
