import React from 'react'

const Checkbox = ({ checked, onChange, name, children = null, disabled = false, ...props }) => {
  const className = props.className ? props.className + ' checkbox-control checkbox-control--small' : 'checkbox-control checkbox-control--small'

  return <label for={name} className={className} {...props}>
    <input type='checkbox' name={name} checked={checked} onChange={onChange} className='control' disabled={disabled} />
    <span class='control' />
    {children}
  </label>
}

export default Checkbox
