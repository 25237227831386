import { render, h } from "preact"

import AdminPriceIndex from "@/components/AdminPriceIndex"
import FavoritesIndex from "@/components/FavoritesIndex"
import InquiriesIndex from "@/components/InquiriesIndex"
import MyOffersIndex from "@/components/MyOffersIndex"
import OffersAutocomplete from "@/components/OffersAutocomplete"
import OffersIndex from "@/components/OffersIndex"
import OffersNew from "@/components/OffersNew"
import ProductPriceIndexChart from "@/components/ProductPriceIndexChart"
import PriceRangesForm from "@/components/PriceRangesForm"
import ShippingItemsIndex from "@/components/ShippingItemsIndex"
import ShippingRatesTables from "@/components/ShippingRatesTables"
import ProductsIndex from "@/components/ProductsIndex"
import SemiCircleProgress from "@/components/SemiCircleProgress"
import TicketsDashboard from "@/components/Tickets/TicketsDashboard"
import VendorProductAssignment from "@/components/VendorProductAssignment"

import I18n from "@/src/i18n.js.erb"

const CLASS_NAME_ATTR = "data-preact-class"
const PROPS_ATTR = "data-preact-props"

const moduleLookup = {
  "AdminPriceIndex": AdminPriceIndex,
  "FavoritesIndex": FavoritesIndex,
  "InquiriesIndex": InquiriesIndex,
  "MyOffersIndex": MyOffersIndex,
  "OffersAutocomplete": OffersAutocomplete,
  "OffersIndex": OffersIndex,
  "OffersNew": OffersNew,
  "ProductPriceIndexChart": ProductPriceIndexChart,
  "PriceRangesForm": PriceRangesForm,
  "ShippingItemsIndex": ShippingItemsIndex,
  "ShippingRatesTables": ShippingRatesTables,
  "ProductsIndex": ProductsIndex,
  "SemiCircleProgress": SemiCircleProgress,
  "Tickets/TicketsDashboard": TicketsDashboard,
  "VendorProductAssignment": VendorProductAssignment,
}

const findDOMNodes = (scope) => {
  return scope.querySelectorAll(`[${CLASS_NAME_ATTR}]`)
}

const mountComponents = (scope = document) => {
  let nodes = findDOMNodes(scope)

  nodes.forEach(node => {
    const className = node.getAttribute(CLASS_NAME_ATTR)
    const importedClass = moduleLookup[className]
    const propsJson = node.getAttribute(PROPS_ATTR)
    const props = propsJson && JSON.parse(propsJson)

    render(h(importedClass, props), node)
  })
}

const initializePreact = () => {
  mountComponents()
  I18n.locale = document.body.dataset.locale
}

const initializePreactFrame = (event) => {
  mountComponents(event.target)
}

document.addEventListener("turbo:load", initializePreact)
document.addEventListener("DOMContentLoaded", initializePreact)
document.addEventListener("turbo:frame-render", initializePreactFrame)
