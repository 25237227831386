import React                         from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom'

const ActiveFilters = ({ items, refine }) => {
  const handleReset = () => refine(items)

  if(items.length === 0) return null

  return <div className='filters__active-filters d-flex justify-content-between'>
    <div>{items.length} Filter aktiv</div>
    <div><button className='link text-decoration-unterline' onClick={handleReset}>Reset</button></div>
  </div>
}

export default connectCurrentRefinements(ActiveFilters)
