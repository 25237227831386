import React, { useState, useEffect, useContext } from 'react'
import Rails from "@rails/ujs"

import { context } from '../PriceRangesForm'

function formatQuantity(quantity, decimalSeparator) {
  if(isNaN(quantity)) return ''

  return quantity.toString().replace('.', decimalSeparator)
}

function getMultiplier(salesUnit) {
  if(salesUnit.volumeType === 'per_pallet') return salesUnit.palletSize
  if(salesUnit.volumeType === 'per_container') return salesUnit.containerSize

  return 1
}

function cacheKeyFor(priceRange, salesUnit) {
  return `${priceRange.quantity_from}${priceRange.quantity_to}-${priceRange.price_per_quantity}-${salesUnit.containerSize}`
}

const PriceVisualization = ({ priceRange, salesUnit }) => {
  const { offerId, productType, singlePower, text, decimalSeparator } = useContext(context)
  const [ fetchedPrices, setFetchedPrices ] = useState({})

  const cacheKey = cacheKeyFor(priceRange, salesUnit)

  useEffect(() => {
    if(!priceRange.quantity_to || !priceRange.price_per_quantity) return
    if(fetchedPrices[cacheKey]) return

    const quantityMultiplier = getMultiplier(salesUnit)
    const quantity = priceRange.quantity_to * quantityMultiplier

    Rails.ajax({
      url: `/api/v1/vendor/offers/${offerId}/price_preview?quantity=${quantity}&price_per_quantity=${priceRange.price_per_quantity}&container_size=${salesUnit.containerSize}`,
      type: 'GET',
      success: (response) => {
        setFetchedPrices(p => ({...p, [cacheKey]: response}))
      }
    })
  }, [priceRange.quantity_to, priceRange.price_per_quantity, cacheKey, offerId, salesUnit])

  let quantityMultiplier = getMultiplier(salesUnit)
  let quantityUnit = text['common']['units']['piece_short']
  let priceUnit = `EUR/${quantityUnit}`

  let quantityFrom = priceRange.quantity_from * quantityMultiplier
  let quantityTo = priceRange.quantity_to * quantityMultiplier

  if(productType === 'SolarModule') {
    quantityUnit = 'KW'
    priceUnit = 'EUR/Wp'
    quantityFrom = (quantityFrom * singlePower) / 1000
    quantityTo = (quantityTo * singlePower) / 1000

    if(priceRange.quantity_from === 1 && quantityMultiplier === 1) quantityFrom = 0
  }

  return <div className='offer-price-range-visualization d-flex justify-content-between w-100'>
    <div className='offer-price-range-visualization__from'>
      {formatQuantity(quantityFrom, decimalSeparator)}
      <span>{quantityUnit}</span>
    </div>
    <div className='offer-price-range-visualization__price w-100'>
      {!fetchedPrices[cacheKey] && '...'}
      {fetchedPrices[cacheKey] && <>
        {fetchedPrices[cacheKey]} <span>{priceUnit}</span>
      </>}
    </div>
    <div className='offer-price-range-visualization__to ml-2'>
      {formatQuantity(quantityTo, decimalSeparator)}
      <span>{quantityUnit}</span>
    </div>
  </div>
}

export default PriceVisualization
