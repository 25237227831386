import React, { useState }          from "react"
import { InstantSearch, Configure } from "react-instantsearch-dom"

import useAlgoliaClient  from "./hooks/useAlgoliaClient"

import SearchBox        from "./algolia/SearchBox"
import MenuSelect       from "./algolia/MenuSelect"
import Pagination       from "./algolia/Pagination"

import Hits         from "./VendorProductAssignment/Hits"
import Hit          from "./VendorProductAssignment/Hit"

import I18n from "../src/i18n.js.erb"

const ATTRIBUTES_TO_RETRIEVE = ['id', 'type', 'color', 'single_power', 'cell_type_name', 'pnom_dc', 'no_of_phases', 'capacity', 'name']

const onClick = (hit) => {
  const productIdInput = document.getElementById("vendor-offer-entry-input-product-id")
  const productTypeInput = document.getElementById("vendor-offer-entry-input-product-type")
  if (!productIdInput || !productTypeInput) { return }

  const form = productIdInput.closest("form")
  if (!form) { return }

  productIdInput.value = hit.id
  productTypeInput.value = hit.type
  form.requestSubmit()
}

const VendorProductAssignment = ({appId, apiKey, indexName}) => {
  const searchClient      = useAlgoliaClient(appId, apiKey)
  const [state, setState] = useState(null)

  const typeFilter = state && state.menu ? state.menu.type : null

  return <>
    <InstantSearch indexName={indexName} searchClient={searchClient} onSearchStateChange={setState}>
      <Configure hitsPerPage={50} attributesToRetrieve={ATTRIBUTES_TO_RETRIEVE} />

      <div className="row">
        <div className="col-md-4">
          <MenuSelect
            attribute="type"
            label={I18n.t("frontend.vendor.offer_import_entry_products.show.product_type_label")}
            locales={I18n.t("frontend.general.product_type")}
          />
        </div>
        <div className="col-md-8">
          <SearchBox />
        </div>
      </div>

      <table className="table table-hover table-top-borderless mt-5">
        <thead>
          <tr>
            <th className="name">{I18n.t("frontend.vendor.offer_import_entry_products.show.table.product_name")}</th>
            <th className="details">{I18n.t("frontend.vendor.offer_import_entry_products.show.table.product_details")}</th>
          </tr>
        </thead>
        <tbody>
          <Hits Hit={Hit} colspan={2} typeFilter={typeFilter} onClick={onClick} />
        </tbody>
      </table>

      <Pagination />
    </InstantSearch>
  </>
}

export default VendorProductAssignment
