import React, { useEffect, useRef, useState } from 'react';
import { useUpdateTicketMutation, useGetTicketQuery } from '../../../api/tickets';
import { useCreateCommentMutation } from '../../../api/comments';
import I18n from 'i18n-js';

const Comments = ({ id, setTicketId }) => {
  const modalRef = useRef();

  useEffect(() => {
    modalRef?.current?.addEventListener('hide.bs.modal', function (event) {
      setTicketId(null);
    }, false);
  }, []);

  return (
    <>
      <div class="modal" ref={modalRef} id="ticketModal" style="background-color: rgba(255, 255, 255, 0.85);" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content card-default" style="border: 1px solid rgba(0, 0, 0, 0.125);">
            {id != null && <CommentsBody id={id} />}
          </div>
        </div>
      </div>
    </>
  );
};

const CommentsBody = ({ id }) => {
  const commentRef = useRef();
  const bodyRef = useRef();
  const commentWindowRef = useRef();
  const { data: ticket, isLoading: isLoadingTicket } = useGetTicketQuery(id, { pollingInterval: 10000 });

  const [editEnabled, setEditEnabled] = useState(false);

  const [updateTicket, { isLoading }] = useUpdateTicketMutation();
  const [createComment, { isLoading: isLoadingCommentCreate }] = useCreateCommentMutation();

  const onCreateComment = () => {
    createComment({
      parent_id: id,
      parent_type: 'Ticket',
      body: commentRef.current.value
    }).then(() => {
      commentRef.current.value = '';
    })
  };

  useEffect(() => {
    commentWindowRef?.current?.scrollIntoView({
      block: 'end'
    })
  }, [ticket, commentWindowRef]);


  return (
    <>
      <div class="modal-header font-weight-bold border-0">
        {ticket?.ticket_type?.length > 0 ? I18n.t('frontend.tickets.dashboard.comments.support_ticket') : I18n.t('frontend.tickets.dashboard.comments.get_it_done')}
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body border-0 pt-1 ticket-modal-body">
        <textarea ref={bodyRef} onChange={() => setEditEnabled(true)} style={{ minHeight: 'auto' }} placeholder={I18n.t('frontend.tickets.dashboard.comments.enter_description')} class="p-0 py-2 border-0 shadow-none form-control mt-0">{ticket?.body}</textarea>

        <div class="d-flex align-items-center flex-gap-3">
          {editEnabled && <button disabled={isLoading} onClick={() => { updateTicket({ id: id, body: bodyRef.current.value }); setEditEnabled(false) }} className='btn btn-success text-small'>{I18n.t('frontend.general.save')}</button>}

          <div class="text-faded text-small mt-1">
            {ticket?.user?.first_name} {ticket?.user?.last_name[0]}. {ticket?.created_at}
          </div>
        </div>

        {ticket?.comments?.length > 0 &&
          <div className='mt-4' style={{ maxHeight: '30vh', overflowY: 'scroll' }}>
            {ticket?.comments?.map((comment) => (
              <div class="comment is--owned mb-3">
                <div class="comment-header">
                  <div class="comment-author font-weight-bold d-flex align-items-center comment-author-gap">
                    <div class="div comment-avatar"></div>
                    {comment.author.first_name} {comment.author.last_name}
                    <small class="text-faded">{comment.created_at}</small>
                  </div>
                </div>
                <div class="comment-body">
                  <div class="comment-text" dangerouslySetInnerHTML={{ __html: comment.body }}>
                  </div>
                </div>
              </div>
            ))}

            <div ref={commentWindowRef} />
          </div>
        }

        {ticket?.done_at == null &&
          <div className='trix-hide-toolbar mt-3'>
            <trix-editor ref={commentRef} placeholder={I18n.t('frontend.tickets.dashboard.comments.add_your_comment')}></trix-editor>
          </div>
        }
      </div>

      {ticket?.done_at == null && <div class="modal-footer border-0 justify-content-start">
        <button disabled={isLoadingCommentCreate} onClick={() => onCreateComment()} name="button" type="submit" class="btn btn-success text-small mr-2">{I18n.t('frontend.tickets.dashboard.comments.comment')}</button>
      </div>}
    </>
  );
};


export default Comments;
