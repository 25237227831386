import React from 'react'

import I18n from '@/src/i18n.js.erb'

import { kwForQuantity } from '../helpers'

const quantityInfo = (offer) => {
  if (offer.min_quantity === offer.quantity) return I18n.t('frontend.offers_index.hits.only_entire_quantity')

  let minQuantity = offer.min_quantity
  if (offer.quantity_step && minQuantity < offer.quantity_step) minQuantity = offer.quantity_step

  let info = `Min. ${minQuantity} ${I18n.t('frontend.general.pcs')}`

  if (offer.full_pallets_only) info += `, ${I18n.t('frontend.offers_index.hits.full_pallets_only')}`
  if (offer.full_containers_only) info += `, ${I18n.t('frontend.offers_index.hits.full_containers_only')}`

  return info
}

const OfferQuantity = ({ hit, offer }) => {
  let minQuantity = offer.min_quantity
  if (offer.quantity_step && minQuantity < offer.quantity_step) minQuantity = offer.quantity_step

  return <div className='w-100'>
    <div className='text-nowrap'>
      <span className='value'>{offer.quantity}</span> <span className='unit'>{I18n.t('frontend.general.pcs')}</span>
    </div>
    { minQuantity && minQuantity > 1 &&
      <div className='text-faded text-small'>min. {minQuantity} {I18n.t('frontend.general.pcs')}</div>
    }
  </div>
}

export default OfferQuantity
