import React, { useEffect, useRef } from 'react'
import { connectInfiniteHits }      from 'react-instantsearch-dom'

import useHitsScrollPos from '../hooks/useHitsScrollPos'

import NoResults from '../algolia/NoResults'

import Hit from './Hit'

const equal = (prevProps, nextProps) => {
  if(prevProps.hits.length !== nextProps.hits.length) return false

  for(let i = 0; i < nextProps.hits.length; i++) {
    if(nextProps.hits[i].objectID !== prevProps.hits[i].objectID) {
      return false
    }
  }

  return true
}

const Hits = React.memo(({ hits, hasMore, refineNext, ...props }) => {
  const sentinel = useRef()
  useHitsScrollPos(hits)

  useEffect(() => {
    const sentinelEl = sentinel.current

    if(sentinelEl) {
      const handleIntersection = entries => {
        if(hasMore && entries[0].isIntersecting) {

          refineNext()
        }
      }

      const observer = new IntersectionObserver(handleIntersection)
      observer.observe(sentinelEl)

      return () => {
        observer.disconnect()
      }
    }
  }, [sentinel, hasMore, refineNext])

  if(hits.length > 0) {
    return <>
      {hits.map(hit => <Hit key={`${hit.offer_type}/${hit.id}`} hit={hit} {...props} />)}
      <div ref={sentinel} />
    </>
  }

  return <NoResults clearsQuery />
}, equal)

export default connectInfiniteHits(Hits)
