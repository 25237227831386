import React, { useReducer, useState, useEffect } from "react"
import { InstantSearch, Configure } from "react-instantsearch-dom"
import { Toaster } from "react-hot-toast"

import I18n from "@/src/i18n.js.erb"

import useAlgoliaClient from "./hooks/useAlgoliaClient"
import SearchContextProvider from './OffersIndex/SearchContextProvider'
import { Provider } from 'react-redux';
import { store } from '../api/store';
import { fetchOfferStock } from './MyOffersIndex/utils/domHelper';
import FilterOption from './MyOffersIndex/FilterOption'
import CategoryFilter from './MyOffersIndex/CategoryFilter'

import ClearRefinements from './algolia/ClearRefinements'

import SearchBox from "./algolia/SearchBox"
import ActiveFilters from './algolia/ActiveFilters'

import ActiveFilter from './MyOffersIndex/ActiveFilter'

import BulkActions from "./MyOffersIndex/BulkActions"
import Hits from "./MyOffersIndex/Hits"
import Hit from "./MyOffersIndex/Hit"
import offersReducer from "./MyOffersIndex/offersReducer"
import DeletedOffersFilter from "./MyOffersIndex/DeletedOffersFilter"
import RefinementList from "./algolia/RefinementList"
import SourceFilter from "./MyOffersIndex/SourceFilter"
import AvailabilityFilter from "./MyOffersIndex/AvailabilityFilter"
import OfferNotUpdatedFilter from "./MyOffersIndex/OfferNotUpdatedFilter"
import SalesFilter from "./MyOffersIndex/SalesFilter"
import InquiriesFilter from "./MyOffersIndex/InquiriesFilter"
import UpdateEventHandler from "./MyOffersIndex/UpdateEventHandler"

const VALID_ORDERING_ATTRIBUTES = [
  "inquiries_count",
  "min_price",
  "quantity",
  "sold_inquiries_count",
  "turnover_volume",
  "view_count",
]

const getSortIndexName = (defaultIndex, attribute = null, direction = null) => {
  if (!attribute || !direction) { return defaultIndex }
  if (!VALID_ORDERING_ATTRIBUTES.includes(attribute)) { return defaultIndex }

  return `${defaultIndex}_${attribute}_${direction}`
}

const MyOffersIndex = ({ appId, apiKey, defaultIndex, sendImageSrc, xImageSrc, categorySlugs }) => {
  const searchClient = useAlgoliaClient(appId, apiKey)
  const [offers, dispatch] = useReducer(offersReducer, {})
  const [currentIndex, setCurrentIndex] = useState(getSortIndexName(defaultIndex))

  const [refresh, setRefresh] = useState(false);

  const resetSelections = () => {
    dispatch({ type: "RESET_SELECTIONS" })
  }

  const changeSortOrder = (attribute, direction) => {
    setCurrentIndex(getSortIndexName(defaultIndex, attribute, direction))
  }

  useEffect(() => {
    if (Object.keys(offers).length > 0) {
      setTimeout(() => {
        setRefresh(true)
        setTimeout(() => setRefresh(false), 1000);
      }, 2500);
    }
  }, [offers])

  useEffect(() => {
    const handleTurboLoad = (e) => {
      const isPriceRangeUpdated = e.detail.fetchResponse.response.url.includes('price_ranges');

      if (isPriceRangeUpdated) {
        e.detail.fetchResponse.response.text().then(value => {
          const stockData = fetchOfferStock(value);

          dispatch({ type: 'UPDATE_STOCK', id: stockData.offerId, stock: stockData.stock })
          dispatch({ type: "UPDATE_MIN_PRICE", id: stockData.offerId, min_price: stockData.minPrice })
          dispatch({ type: "UPDATE_MAX_PRICE", id: stockData.offerId, max_price: stockData.maxPrice })
        })
      }
    };

    document.addEventListener('turbo:submit-end', handleTurboLoad);

    return () => {
      document.removeEventListener('turbo:submit-end', handleTurboLoad);
    }
  }, []);

  return <InstantSearch refresh={refresh} indexName={currentIndex} searchClient={searchClient} onSearchStateChange={resetSelections}>
    <Configure hitsPerPage={100} />
    <DeletedOffersFilter offers={offers} />
    <UpdateEventHandler dispatch={dispatch} />

    <div className="row">
      <div className="col-md-3 col-xl-2">
        <div className="my-offers__filter filters">
          <SearchContextProvider categorySlugs={categorySlugs}>
            <div style={{ display: 'none' }}>
              <RefinementList showMore={true} attribute='published_until_ts' />
              <RefinementList attribute='updated_at_ts' />
              <RefinementList attribute='sold_inquiries_count' />
              <RefinementList attribute='inquiries_count' />
              <RefinementList attribute='available_starting_at_ts' />
              <RefinementList attribute='source' />
              <RefinementList attribute='archived' />
            </div>

            <ActiveFilters />

            <FilterOption label={I18n.t("frontend.my_offers_index.filter.publishing_status")} collapsible>
              <ActiveFilter />
            </FilterOption>

            {/* This is temporarily disabled until we have finalized the competitive pricing system. */}
            {/* <FilterOption label={I18n.t("frontend.my_offers_index.filter.competitive_pricing")} collapsible>
            </FilterOption> */}

            <FilterOption label={I18n.t("frontend.my_offers_index.filter.filters")} collapsible>
              <OfferNotUpdatedFilter />
              <SalesFilter />
              <InquiriesFilter />
              <SourceFilter />
              <AvailabilityFilter />
            </FilterOption>

            <FilterOption label={I18n.t("frontend.my_offers_index.filter.category")} collapsible>
              <CategoryFilter attribute="offer_type" translations={I18n.t("frontend.my_offers_index.filter")} />
            </FilterOption>

            <FilterOption label={I18n.t("frontend.my_offers_index.filter.warehouse")} collapsible>
              <RefinementList attribute='warehouse_name' />
            </FilterOption>

            <FilterOption label={I18n.t("frontend.my_offers_index.filter.brand")} collapsible searchable>
              <RefinementList searchable attribute='producer_name' limit={100} maxItems={8} />
            </FilterOption>

            <ClearRefinements>
              <button className='btn d-block mt-4 my-offers__filter-clear'>{I18n.t("frontend.my_offers_index.filter.clear_all")}</button>
            </ClearRefinements>
          </SearchContextProvider>
        </div>
      </div>

      <div className="col-md-9 col-xl-10">
        <div className="row mb-4">
          <div className="col-md-7">
            <SearchBox />
          </div>

          <div className="col-md-5 text-right">
            <BulkActions offers={offers} dispatch={dispatch} />
          </div>
        </div>

        <Hits Hit={Hit} offers={offers} dispatch={dispatch} defaultIndex={defaultIndex} indexName={currentIndex} changeSortOrder={changeSortOrder} sendImageSrc={sendImageSrc} xImageSrc={xImageSrc} />
      </div>
    </div>

    <Toaster />
  </InstantSearch >
}

const Index = ({ appId, apiKey, defaultIndex, locale, sendImageSrc, xImageSrc, categorySlugs }) => {
  return (
    <>
      <Provider store={store}>
        <MyOffersIndex appId={appId} apiKey={apiKey} defaultIndex={defaultIndex} locale={locale} sendImageSrc={sendImageSrc} xImageSrc={xImageSrc} categorySlugs={categorySlugs} />
      </Provider>
    </>
  )
}

export default Index
