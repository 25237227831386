import React, { useState, useEffect, useRef } from "react"
import Rails from "@rails/ujs"
import toast from "react-hot-toast"

import I18n from "@/src/i18n.js.erb"
import { PencilIcon } from "../OffersIndex/Icons"


const csrfToken = () => document.querySelector(`meta[name="csrf-token"]`).getAttribute("content")

const getQuantity = (offers, hit) => {
  // Updates always come in number of pieces so we have to calculate the number of volume type units on the fly.
  if (offers[hit.id] && offers[hit.id].updates.quantity) {
    return Math.floor(offers[hit.id].updates.quantity / hit.volume_type_multiplier)
  }

  return hit.quantity_in_volume_type
}

const update = (id, stock, stockInPieces, dispatch, setEdit) => {
  Rails.ajax({
    type: "PUT",
    url: "/" + I18n.locale + "/vendor/offers/" + id + "/stock",
    data: "offer[stock]=" + stock,
    success: (data) => {
      dispatch({type: "UPDATE", id, data: {quantity: stockInPieces, min_price: data.min_price}})
      toast.success(I18n.t("frontend.my_offers_index.messages.success"))
      setEdit(false)
    },
    error: () => { toast.error("An error occured :(") }
  })
}

const deactivate = (id, dispatch, setEdit) => {
  dispatch({ type: "DEACTIVATE", id })
  toast.success(I18n.t("frontend.my_offers_index.messages.deactivated"))
  setEdit(false)
  fetch("/" + I18n.locale + "/vendor/offer_deactivations", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "X-CSRF-Token": csrfToken()
    },
    body: JSON.stringify({
      deactivation: {
        offer_ids: [id],
        drop_ranges: true
      }
    })
  })
}

const Stock = ({ hit, sendImageSrc, xImageSrc, offers, dispatch }) => {
  const [ edit, setEdit ] = useState(false)
  const [ stock, setStock ] = useState(() => getQuantity(offers, hit))
  const input = useRef()

  useEffect(() => {
    const i = input.current

    if(i) {
      const listener = e => {
        if(e.code === "Enter" || e.code === "NumpadEnter") {
          updateStock()
        } if(e.code === "Escape") {
          setEdit(false)
          setStock(getQuantity(offers, hit))
        }
      }

      i.addEventListener("keydown", listener)
      return () => i.removeEventListener("keydown", listener)
    }
  })

  useEffect(() => {
    if(edit && input.current) input.current.focus()
  }, [edit])

  const toggleEdit = () => {
    if(edit) {
      setEdit(false)
      setStock(getQuantity(offers, hit))
    } else {
      setEdit(true)
    }
  }

  const stockChanged = e => {
    setStock(e.target.value)
  }

  const updateStock = () => {
    const newStock = parseInt(stock)

    if(!Number.isInteger(newStock)) {
      toast.error(I18n.t("frontend.my_offers_index.messages.error"))
      return
    }

    if(newStock === 0) {
      deactivate(hit.id, dispatch, setEdit)
      return
    }

    // We can either keep pieces/volume duplicates for every field relating to quantites or jsut calculate the relevant numbers on the fly, which we do here.
    const newStockInPieces = stock * hit.volume_type_multiplier

    if(newStockInPieces !== hit.quantity) {
      update(hit.id, newStock, newStockInPieces, dispatch, setEdit)
    }
  }

  useEffect(() => {
    setStock(getQuantity(offers, hit))
  }, [offers])

  return <>
    {!edit && <>
      <div
        className="d-inline-flex align-items-center justify-content-end flex-gap-1 clickable table-inline-link text-nowrap"
        onClick={toggleEdit}
      >
        <span>{stock || 0} {I18n.t(`frontend.general.volume_type.${hit.volume_type}`)}</span>
        {PencilIcon({ className: "icon-h20" })}
      </div>
    </>}

    {edit && <div className="d-flex flex-row align-items-center justify-content-end" style={{ height: "100%" }}>
      <div className="d-flex justify-content-end">
        <input ref={input} type="number" value={stock} onChange={stockChanged} className="form-control form-control--inline" />
        <button onClick={updateStock} className="btn btn-primary btn-inline px-2">
          <img src={sendImageSrc} className="btn__icon" />
        </button>
        <a className="btn btn-light px-2" onClick={toggleEdit}>
          <img src={xImageSrc} className="btn__icon" />
        </a>
      </div>
    </div>}
  </>
}

export default Stock
