import React, { useEffect, useRef } from 'react';
import IconDonutSmall from '../icons/donut_small.svg';
import BSN from 'bootstrap.native/dist/bootstrap-native.esm.min.js'
import I18n from 'i18n-js';
import useBootstrapDropdown from '../../hooks/useBootstrapDropdown';

const StatusFilter = ({ filters, setFilters }) => {
  const assigneesRef = useBootstrapDropdown();

  return (
    <div className="dropdown mt-5">
      <button ref={assigneesRef} className="btn dropdown-toggle p-2" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <img className="mr-3 align-top" src={IconDonutSmall} />
        {I18n.t('frontend.tickets.dashboard.filters.status')}
      </button>
      <div class="dropdown-menu dropdown-menu-left py-0 min-w-15 dropdown-toggle-highlight py-2" onClick={(e) => e.stopPropagation()} tabindex="0">
        <label class="px-4 py-2 mb-0 text-medium" for={`filter-status-all`}>
          <input onChange={() => setFilters({ ...filters, status: 'all' })} type="checkbox" id={'filter-status-all'} checked={filters.status == 'all'} value={0} class="mr-2" />
          <span class="control"></span>
          <span>{I18n.t('frontend.tickets.dashboard.filters.all')}</span>
        </label>

        <hr class="p-0 m-0 mb-2" />

        <label class="px-4 py-2 mb-0 text-medium" for={`filter-status-active`}>
          <input onChange={() => setFilters({ ...filters, status: 'open' })} type="checkbox" checked={filters.status == 'open'} id={'filter-status-active'} value={0} class="mr-2" />
          <span class="control"></span>
          <span>{I18n.t('frontend.tickets.dashboard.filters.open')}</span>
        </label>

        <label class="px-4 py-2 mb-0 text-medium" for={`filter-status-closed`}>
          <input onChange={() => setFilters({ ...filters, status: 'closed' })} type="checkbox" id={'filter-status-closed'} checked={filters.status == 'closed'} value={0} class="mr-2" />
          <span class="control"></span>
          <span>{I18n.t('frontend.tickets.dashboard.filters.closed')}</span>
        </label>

      </div>
    </div>
  );
}

export default StatusFilter;