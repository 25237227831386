import React, { useRef, useEffect } from 'react';
import IconBadge from '../icons/badge.svg';
import Chip from '../components/Chip'
import BSN from 'bootstrap.native/dist/bootstrap-native.esm.min.js'
import I18n from 'i18n-js';
import useBootstrapDropdown from '../../hooks/useBootstrapDropdown';

const AssigneesFilter = ({ users, filters = {}, setFilters = () => { } }) => {
  const assigneesRef = useBootstrapDropdown();

  const removeAssignee = (userId) => {
    setFilters({ ...filters, user_ids: filters.user_ids?.filter((u) => u != userId) });
  }

  const addAssignee = (userId) => {
    setFilters({ ...filters, user_ids: [...filters.user_ids, userId] });
  }

  const toggleSelectAll = () => {
    if (filters.user_ids.length === users.length) {
      setFilters({ ...filters, user_ids: [] });
    } else {
      setFilters({ ...filters, user_ids: users.map((u) => u.id) });
    }
  }

  return (
    <>
      <div className="dropdown mt-5">
        <button ref={assigneesRef} className="btn dropdown-toggle p-2" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img className="mr-3 align-top" src={IconBadge} />
          {I18n.t('frontend.tickets.dashboard.filters.assigned_to')}
        </button>
        <div class="dropdown-menu dropdown-menu-left py-0 min-w-15 dropdown-toggle-highlight py-2" onClick={(e) => e.stopPropagation()} tabindex="0">
          <div class="my-1 px-4 py-2 text-medium">
            <button onClick={toggleSelectAll} className="link">
              {filters?.user_ids?.length == users?.length && I18n.t('frontend.tickets.dashboard.general.unselect_all')}
              {filters?.user_ids?.length != users?.length && I18n.t('frontend.tickets.dashboard.general.select_all')}
            </button>
          </div>

          <hr class="p-0 m-0 mb-2" />

          <AssigneesList users={users} filters={filters} addAssignee={addAssignee} removeAssignee={removeAssignee} />
        </div>
      </div>

      <div className={'d-flex flex-wrap flex-gap-2'}>
        {filters.user_ids?.map((u) => {
          const user = users?.find((user) => user.id == u);

          if (u == 0) {
            return (
              <Chip onClose={() => removeAssignee(0)} name={I18n.t('frontend.tickets.dashboard.filters.not_assigned')} />
            )
          }

          return (
            <Chip onClose={() => removeAssignee(user.id)} name={`${user?.first_name || ''} ${user?.last_name || ''}`} />
          )
        })}
      </div>
    </>
  );
};

const AssigneesList = ({ users, filters, addAssignee, removeAssignee }) => {
  const notAssignedSelected = filters?.user_ids?.some((u) => u == 0);

  return (
    <>
      {users?.map((user) => {
        const selected = filters?.user_ids?.some((u) => u == user.id);

        return (
          <label class="px-4 py-2 mb-0 text-medium" for={`ticket_filters_user_ids_${user.id}`}>
            <input onChange={() => selected ? removeAssignee(user.id) : addAssignee(user.id)} type="checkbox" id={`ticket_filters_user_ids_${user.id}`} checked={selected} value={user.id} class="mr-2" />
            <span class="control"></span>
            <span>{user.first_name} {user.last_name}</span>
          </label>
        )
      })}

      <label class="px-4 py-2 mb-0 text-medium" for={`ticket_filters_user_ids_0`}>
        <input onChange={() => notAssignedSelected ? removeAssignee(0) : addAssignee(0)} type="checkbox" id={`ticket_filters_user_ids_0`} checked={notAssignedSelected} value={0} class="mr-2" />
        <span class="control"></span>
        <span>{I18n.t('frontend.tickets.dashboard.filters.not_assigned')}</span>
      </label>
    </>
  )
}

export default AssigneesFilter;
