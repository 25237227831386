import React, { useContext }  from 'react'
import { connectHitInsights } from 'react-instantsearch-dom'
import aa                     from 'search-insights'

import { searchContext } from './SearchContextProvider'
import { buildProductUrl, expandProductUrlWithFilters } from '../helpers'

import Card from './Card'

const Hit = ({ hit, insights, index, inquiryId, isLoggedIn = false }) => {
  const context  = useContext(searchContext)
  let productUrl = buildProductUrl(hit, context.categorySlugs)

  if(inquiryId) productUrl += '?inquiry_id=' + inquiryId

  const cardClicked = () => {
    insights('clickedObjectIDsAfterSearch', {
      eventName: 'Product Clicked',
      objectIDs: [hit.objectID],
      positions: [hit.__position],
      queryID: hit.__queryID,
      index
    })

    const productUrlWithFilters = expandProductUrlWithFilters(productUrl, context.filters)

    Turbo.visit(productUrlWithFilters)
  }

  return <Card
    hit={hit}
    productUrl={productUrl}
    onClick={cardClicked}
    isLoggedIn={isLoggedIn}
  />
}

export default connectHitInsights(aa)(Hit)
