import { useRef, useEffect } from 'react'
import BSN from 'bootstrap.native/dist/bootstrap-native.esm.min.js'

export default () => {
  const el = useRef()

  useEffect(() => {
    new BSN.Dropdown(el.current)
  }, []);

  return el;
}
