import React                         from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom'

const ClearRefinements = ({items, refine, children, autoHide = false}) => {
  if(!autoHide || items.length > 0) {
    const reset = () => {
      refine(items)
    }

    return React.Children.map(children, child => {
      return React.isValidElement(child) ? React.cloneElement(child, {onClick: reset}) : child
    })
  }

  return null
}

export default connectCurrentRefinements(ClearRefinements)
