import React, { useEffect, useRef, useState } from 'react';
import IconCalendarMonth from '../../../assets/images/icons/bsi/calendar_month.svg'
import IconFlag from '../../../assets/images/icons/bsi/flag.svg'
import IconFlagRed from '../../../assets/images/icons/bsi/flag-red.svg'
import IconChatBubble from './icons/chat_bubble.svg'
import Chip from './components/Chip'
import { useDeleteTicketMutation, useResolveTicketMutation, useUpdateTicketMutation } from '../../api/tickets';
import I18n from '@/src/i18n.js.erb'
import flatpickr from 'flatpickr';
import Assignees from './TicketCard/Assignees';
import IconDeleteForever from './icons/delete_forever.svg';

const TicketCard = ({ ticket, users, isAdmin, openTicketModal }) => {
  const calendarRef = useRef();
  const bodyRef = useRef();

  const [datePickerInstance, setDatePickerInstance] = useState();
  const [editEnabled, setEditEnabled] = useState(false);

  const [resolveTicket] = useResolveTicketMutation();
  const [updateTicket, { isSuccess }] = useUpdateTicketMutation();
  const [deleteTicket, { isLoading: isLoadingDelete }] = useDeleteTicketMutation();

  const onDelete = () => {
    if (window.confirm(I18n.t('frontend.tickets.dashboard.ticket_card.confirm_delete'))) {
      deleteTicket(ticket.id);
    }
  };

  const unlinkParent = () => {
    if (window.confirm(I18n.t('frontend.tickets.dashboard.ticket_card.confirm_unlink'))) {
      updateTicket({
        id: ticket.id,
        parent_id: null,
        parent_type: null
      });
    }
  }

  useEffect(() => {
    const datePicker = flatpickr(calendarRef.current, {
      altInput: true,
      altFormat: 'D. j M Y',
      disableMobile: true,
      defaultDate: ticket.due_at,
      onChange: (selectedDates, dateStr, instance) => {
        if (dateStr.length > 0) {
          updateTicket({
            id: ticket.id,
            due_at: dateStr
          })
        }
      }
    });

    setDatePickerInstance(datePicker);

    if (ticket.due_at == null) {
      datePicker.clear();
    }

    setEditEnabled(false)
  }, [ticket]);

  return (
    <div className='d-flex flex-gap-4 mt-3 ticket-card-container'>
      <div className='pt-1 d-flex align-items-start'>
        {ticket.done_at != null &&
          <div onClick={() => resolveTicket(ticket.id)} class="checkbox-rounded checkbox-rounded--checked clickable"></div>
        }

        {ticket.done_at == null &&
          <div onClick={() => resolveTicket(ticket.id)} class="checkbox-rounded clickable"></div>
        }
      </div>
      <div className='ticket-card w-100 align-items-center'>
        <div className='d-flex justify-content-between'>
          {editEnabled && <>
            <div className='d-flex w-100 flex-gap-3'>
              <textarea ref={bodyRef} className='rounded form-control min-h-4 w-100'>{ticket.body}</textarea>
              <div className='d-flex align-items-center flex-gap-3'>
                <button disabled={isLoadingDelete} onClick={() => updateTicket({ id: ticket.id, body: bodyRef.current.value })} className='btn btn-success text-small'>{I18n.t('frontend.general.save')}</button>
                <button onClick={() => setEditEnabled(!editEnabled)} className='btn btn-light text-small'>{I18n.t('frontend.general.cancel')}</button>
              </div>
            </div>
          </>}
          {!editEnabled && <>
            <p className='p-0 m-0'>
              <span className='text-dark clickable' onClick={() => openTicketModal(ticket.id)}>
                {ticket.body}
              </span>

              {ticket.is_new && <span class="badge badge-primary rounded ml-3 bg-sky-blue font-weight-normal">{I18n.t('frontend.general.new')}</span>}
            </p>
            <div className='ticket-card-actions'>
              <img onClick={onDelete} className='clickable' src={IconDeleteForever} />
            </div>
          </>}
        </div>

        <div class='d-flex mt-1 flex-gap-4 flex-wrap'>
          <div className='d-flex align-items-center flex-gap-2'>
            <img onClick={() => datePickerInstance.toggle()} className='clickable' src={IconCalendarMonth} />
            <input className={`form-control bg-transparent border-0 ${new Date(ticket.due_at) < new Date().setHours(0, 0, 0, 0) ? 'text-danger' : ''} shadow-none text-small clickable input active ${ticket.due_at == null ? 'max-w-0 p-0' : 'max-w-8'}`} type={'date'} ref={calendarRef} />
          </div>

          {isAdmin && <Assignees ticket={ticket} users={users} />}

          <div onClick={() => openTicketModal(ticket.id)} className='d-flex clickable text-small align-items-center flex-gap-2'>
            <img src={IconChatBubble} />
            <span>{ticket.comments?.length}</span>
          </div>

          <img src={ticket.priority ? IconFlagRed : IconFlag} className='clickable' onClick={() => updateTicket({ id: ticket.id, priority: !ticket.priority })} />

          {ticket.parent_id && <Chip disableClose={ticket.ticket_type?.length > 0} onClose={() => unlinkParent()} hasLink={ticket.parent_url} name={`R-${ticket.parent_id}`} />}
          {ticket.user_id && isAdmin && <Chip disableClose hasLink={ticket.user_url} name={`U-${ticket.user_id}`} />}

          {ticket.ticket_type && <Chip hasColor disableClose name={I18n.t(`frontend.tickets.ticket/ticket_type.${ticket.ticket_type}`)} />}
        </div>
      </div>
    </div >
  );
};

export default TicketCard;
