import React from 'react'

import I18n from '@/src/i18n.js.erb'

const FiltersRegisterCTA = () => <div className='filters__register-cta'>
  <h4>{I18n.t('frontend.offers_index.filters.register_cta.title')}</h4>
  <a href={`/${I18n.locale}/signup`} className='btn btn-primary btn-block'>{I18n.t('frontend.offers_index.filters.register_cta.button')}</a>
  <p>{I18n.t('frontend.offers_index.filters.register_cta.text')}</p>
</div>

export default FiltersRegisterCTA
