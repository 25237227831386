import { useRef }    from 'react'
import algoliasearch from 'algoliasearch/lite'

const useAlgoliaClient = (appId, apiKey) => {
  const client = useRef(null)

  if(!client.current) {
    client.current = algoliasearch(appId, apiKey)
  }

  return client.current
}

export default useAlgoliaClient
