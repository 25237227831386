import React  from 'react'

import AvailableAsOf from '../common/AvailableAsOf'

const getAvailability = offers => {
  let min = null

  for(const offer of offers) {
    if(offer.in_stock) return {
      in_stock: true
    }

    if(min === null || min === undefined || offer.available_starting_at_ts < min)
      min = offer.available_starting_at_ts
  }

  return {
    available_starting_at_ts: min
  }
}

const Availability = ({ hit }) => <AvailableAsOf hit={getAvailability(hit.offers)} />

export default Availability
