import React, { useState } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'

import I18n from "@/src/i18n.js.erb"

import Checkbox from '../common/Checkbox'

const RefinementList = ({ items, refine, label, locales, searchable, maxItems = null, sort = null }) => {
  const [ search, setSearch ] = useState('')

  const toggle = item => () => {
    refine(item.value)
  }

  const searchChanged = e => setSearch(e.target.value)

  const totalItems = items.length

  if (searchable && search != '') {
    items = items.filter(item => item.label.toLowerCase().includes(search.toLowerCase()))
  }

  if (sort) items.sort(sort)
  if (searchable) items = items.slice(0, maxItems)

  return <>
    {label && <label>{label}</label>}
    <ul class='refinement-list'>
      {items.map(item => <li key={item.label}>
        <Checkbox checked={item.isRefined} name={item.label} onChange={toggle(item)}>
          <div className='d-flex align-items-center justify-content-between flex-greed'>
            <div onClick={toggle(item)} className='truncate'>{locales && locales[item.label] ? locales[item.label] : item.label}</div>
            <div className='refinement-list__count'>{item.count}</div>
          </div>
        </Checkbox>
      </li>)}
    </ul>
    {searchable && totalItems > maxItems && <div className='mb-1'>
      <input type='text' className='inline-search' value={search} onChange={searchChanged} placeholder='Suche nach Weiteren...' />
      {search.length > 0 && items.length === 0 && <div>
        {I18n.t('frontend.offers_index.filters.nothing_found')}
      </div>}
    </div>}
  </>
}

export default connectRefinementList(RefinementList)
