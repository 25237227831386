import React, { useState } from 'react'
import { createConnector } from 'react-instantsearch-dom'

const getIndexId = ({ais, multiIndexContext}) => multiIndexContext ? multiIndexContext.targetedIndex : ais.mainTargetedIndex

const connectMax = createConnector({
  displayName: 'MaxInput',

  getProvidedProps(props, searchState) {
    const attribute         = props.attribute + '_max'
    const currentRefinement = searchState[attribute] ? parseFloat(searchState[attribute]) : undefined

    return {
      currentRefinement
    }
  },

  refine(props, searchState, nextRefinement) {
    const attribute = props.attribute + '_max'

    return {
      ...searchState,
      [attribute]: nextRefinement ? nextRefinement : null
    }
  },

  getSearchParameters(searchParameters, props, searchState) {
    const attribute = props.attribute + '_max'

    let newSearchParameters = searchParameters

    if(searchState[attribute])
      newSearchParameters = newSearchParameters.addNumericRefinement(props.attribute, '<=', searchState[attribute])
    else
      newSearchParameters.removeNumericRefinement(props.attribute, '<=')

    return newSearchParameters
  },

  cleanUp(props, searchState) {
    const attribute = props.attribute + '_max'

    const {[attribute]: omit, ...nextSearchState} = searchState

    return nextSearchState
  },

  getMetadata(props, searchState) {
    const attribute = props.attribute + '_max'

    return {
      id: attribute,
      index: getIndexId({
        ais: props.contextValue,
        multiIndexContext: props.indexContextValue
      }),
      items: searchState[attribute]
        ? [
            {
              label: `${searchState[attribute]}`,
              value: (nextState) => ({
                ...nextState,
                [attribute]: null
              })
            }
          ]
        : []
    }
  }
})

const MaxFilter = ({ currentRefinement, refine, label, unit, ...props }) => {
  const [ value, setValue ] = useState(currentRefinement)

  const changed = e => {
    const value = parseFloat(e.target.value.replace(',', '.'))

    setValue(e.target.value)
    refine(!isNaN(value) ? value : undefined)
  }

  const inputClassName = unit ? 'form-control' : 'form-control form-control--small'
  const input = <input type='text' value={value} className={inputClassName} onChange={changed} />

  if(unit) return <div {...props}>
    <div className='input-group input-group--small'>
      {input}
      <div className='input-group-append'>
        <div className='input-group-text'>{unit}</div>
      </div>
    </div>
  </div>

  return <div {...props}>
    {label && <label>{label}</label>}
    {input}
  </div>
}

export default connectMax(MaxFilter)
