import { useState, useEffect } from 'react'
import { InstantSearch } from 'react-instantsearch-dom'

import useAlgoliaClient from '../hooks/useAlgoliaClient'

import Autocomplete from '../algolia/Autocomplete'
import Hit from '../ProductsAutocomplete/Hit'

import ProductPriceIndexChart from '../ProductPriceIndexChart'

import { buildProductUrl } from '../helpers'
import { PRODUCT_TYPES } from '../../src/product_types'

const Product = ({ product, handleCancel, categorySlugs }) => {
  const [ data, setData ] = useState(null)

  const handleOpen = () => {
    const frame = document.querySelector('turbo-frame#modal-frame')
    frame.src = buildProductUrl(product, categorySlugs) + '/price_index'
  }

  useEffect(() => {
    if(!data) {
      fetch(`/api/v1/admin/price_index/product?type=${PRODUCT_TYPES[product.type]}&id=${product.id}`, ).then(response => response.json()).then(data => {
        setData(data)
      })
    }
  }, [product, data])

  return <div>
    {product.name}
    {data && <ProductPriceIndexChart width='100%' aspect={3} data={data} />}
    <button onClick={handleOpen}>modal</button>
    <button onClick={handleCancel}>cancel</button>
  </div>
}

const ProductPriceIndex = ({ appId, apiKey, indexName, categorySlugs }) => {
  const searchClient = useAlgoliaClient(appId, apiKey)
  const [ product, setProduct ] = useState(null)

  const handleSelect = p => {
    setProduct(p)
  }

  const handleCancel = () => {
    setProduct(null)
  }

  return <InstantSearch indexName={indexName} searchClient={searchClient}>
    {!product && <Autocomplete onSelect={handleSelect} placeholder='Search for product' Hit={Hit} />}
    {product && <Product product={product} handleCancel={handleCancel} categorySlugs={categorySlugs} />}
  </InstantSearch>
}

export default ProductPriceIndex
