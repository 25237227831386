import React from 'react'
import { createConnector } from 'react-instantsearch-dom'

import I18n from '@/src/i18n.js.erb'

import Checkbox from '../common/Checkbox'
import { getDefaultMetadata } from './utils/FilterMethods'

const ATTRIBUTE = 'updated_at_ts'
const now = Math.floor(new Date / 1000)
const nowMinusOneMonth = now - 60 * 60 * 24 * 30

const connectOffersNotUpdated = createConnector({
  displayName: 'NoUpdatedOffers',

  getProvidedProps(props, searchState, searchResults) {
    const currentRefinement = searchState[ATTRIBUTE] || null
    const facets = searchResults && searchResults.results?.disjunctiveFacets?.find(f => f.name === ATTRIBUTE)?.data || [];

    return {
      currentRefinement,
      facets
    }
  },

  refine(props, searchState, nextRefinement) {
    return {
      ...searchState,
      [ATTRIBUTE]: nextRefinement
    }
  },

  getSearchParameters(searchParameters, props, searchState) {
    if (searchState[ATTRIBUTE] === 'no_update') {
      return searchParameters.removeNumericRefinement(ATTRIBUTE)
        .addNumericRefinement(ATTRIBUTE, '<=', nowMinusOneMonth)
    } else {
      return searchParameters.removeNumericRefinement(props.attribute)
    }
  },

  cleanUp(props, searchState) {
    const { [ATTRIBUTE]: omit, ...nextSearchState } = searchState

    return nextSearchState
  },

  getMetadata(props, searchState) {
    return getDefaultMetadata(ATTRIBUTE, props, searchState)
  }
})

const OfferNotUpdatedFilter = connectOffersNotUpdated(({ currentRefinement, refine, facets }) => {
  const handleFilterToggle = value => e => {
    e.preventDefault()
    e.stopPropagation()

    if (currentRefinement === null) {
      refine(value)
    } else
      refine(null)
  }

  const facetValues = Object.keys(facets)?.map(f => parseInt(f)) || [];

  const counts = Object.keys(facets).reduce((acc, value) => {
    if (value <= nowMinusOneMonth) {
      acc.noUpdateCount += facets[value];
    }

    return acc;
  }, { noUpdateCount: 0 });


  return <>
    <ul class='refinement-list'>
      <li>
        <Checkbox checked={currentRefinement === 'no_update'} onChange={handleFilterToggle('no_update')}>
          <div className='d-flex align-items-center justify-content-between flex-greed'>
            <div className='truncate'>{I18n.t('frontend.my_offers_index.filter.no_update')}</div>
            <div className='refinement-list__count text-small'>{counts.noUpdateCount}</div>
          </div>
        </Checkbox>
      </li>
    </ul>
  </>
})

export default OfferNotUpdatedFilter
