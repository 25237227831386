import React           from 'react'
import { connectMenu } from 'react-instantsearch-dom'

const MenuSelect = ({items, currentRefinement, refine, sort, label = '--', locales}) => {
  const changed = e => {
    refine(e.target.value)
  }

  if(sort) items.sort(sort)

  return <select className='custom-select' value={currentRefinement} onChange={changed}>
    <option value={null}>{label}</option>
    {items.map(item => <option key={item.label} value={item.label}>
      {locales ? locales[item.label] : item.label}
    </option>)}
  </select>
}

export default connectMenu(MenuSelect)
