import React from "react"
import I18n from "../../src/i18n.js.erb"

const SAVE_SEARCH_URL = `/${I18n.locale}/saved_search_queries/new`

const SaveSearchButton = ({ className, title }) => {
  title ||= I18n.t("frontend.general.no_results.save_search.button")

  return <turbo-frame className="d-block w-100">
    <a href={SAVE_SEARCH_URL}
      data-turbo-frame="modal-frame"
      className={`btn btn-success ${className}`}
      data-saved-search-query-state
    >
      {title}
    </a>
  </turbo-frame>
}

export default SaveSearchButton
