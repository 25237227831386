import React from 'react'

const Chevron = () => <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 8L6.24074 1.8858C6.63984 1.42019 7.36016 1.42019 7.75926 1.8858L13 8" stroke="#003560" stroke-width="2" stroke-linecap="round"/>
</svg>

const FilterOption = ({label, children, collapsible = false, collapsed = false, ...props}) => {
  const [ isCollapsed, setIsCollapsed ] = React.useState(collapsed)

  let className = props.className ? 'filters__option ' + props.className : 'filters__option'

  if(!collapsible) return <div className={className} {...props}>
    {label && <div className='filters__option-head'>
      <h4>{label}</h4>
    </div>}
    {children}
  </div>

  const handleToggle = () => setIsCollapsed(!isCollapsed)

  if(!isCollapsed) className = className + ' filters__option--expanded'

  return <div className={className} {...props}>
    <div className='filters__option-head' onClick={handleToggle}>
      <h4>{label}</h4>
      <Chevron />
    </div>
    {!isCollapsed && children}
  </div>
}

export default FilterOption
