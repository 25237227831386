import React                         from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom'

import I18n from '@/src/i18n.js.erb'

import ClearRefinements from './ClearRefinements'
import SaveSearchButton from '../OffersIndex/SaveSearchButton'

const NoResults = ({ items }) => {
  if(items.length === 0) return null

  const currentSearchQuery = items[0].currentRefinement

  const noResults = <div className="no-results-wrapper mt-4">
    <h2 className="mt-0 mb-2">{I18n.t("frontend.general.no_results.header.title")}</h2>
    <p className="lead mt-0 mb-2">{I18n.t("frontend.general.no_results.header.lead", { query: currentSearchQuery || "" })}</p>
    <p className="mt-0">{I18n.t("frontend.general.no_results.reset_link.before_link")} <ClearRefinements clearsQuery={true}><a className="clickable">{I18n.t("frontend.general.no_results.reset_link.link")}</a></ClearRefinements> {I18n.t("frontend.general.no_results.reset_link.after_link")}</p>
    <hr className="my-5" />
    <div className="smart-search-cta">
      <div className="row align-items-center mb-3">
        <div className="col-md-6">
          <h2 className="smart-search-cta__title mt-0 mb-3" dangerouslySetInnerHTML={{__html: I18n.t("frontend.general.no_results.save_search.cta_html")}}></h2>
          <SaveSearchButton />
        </div>

        <div className="col-md-6">
          <div class="smart-search-cta__image"></div>
        </div>
      </div>

      <p className="mt-4" dangerouslySetInnerHTML={{__html: I18n.t("frontend.general.no_results.save_search.explanation_html")}}></p>
    </div>
  </div>

  return noResults
}

export default connectCurrentRefinements(NoResults)
