import React, { useContext } from 'react'
import CurrencyInput from 'react-currency-input-field'

import { context } from '../PriceRangesForm'
import PriceVisualization from './PriceVisualization'

const Input = ({ value, setValue, unit, icon, name, isInvalid = false, isLocked = false }) => {
  const classNames = ['form-control']

  if(isInvalid) classNames.push('is-invalid')
  if(isLocked) classNames.push('is--disabled')

  function handleChange(v) {
    setValue(v)
  }

  function handleKeyDown(e) {
    if(e.key === 'Enter') {
      e.preventDefault()
      return
    }

    if((!value || value === '') && e.key === '0') e.preventDefault()
  }

  return <div className='input-group price-range__input'>
    <CurrencyInput className={classNames.join(' ')} name={name} value={value} onValueChange={handleChange} onKeyDown={handleKeyDown} disableGroupSeparators={true} allowDecimals={false} allowNegativeValue={false} />
    <div className='input-group-append'>
      <div className='input-group-text'>
        {!icon && unit}
        {icon && <img src={icon} width='30' />}
      </div>
    </div>
  </div>
}

const PriceInput = ({ value, setValue, unit, precision, name, isInvalid = false, isLocked = false }) => {
  const { groupSeparator, decimalSeparator } = useContext(context)

  const classNames = ['form-control']

  if(isInvalid) classNames.push('is-invalid')
  if(isLocked) classNames.push('is--disabled')

  function handleChange(v) {
    setValue(v)
  }

  function handleKeyDown(e) {
    if(e.key === 'Enter') e.preventDefault()
  }

  return <div className='input-group price-range__input'>
    <CurrencyInput className={classNames.join(' ')} name={name} value={value} onValueChange={handleChange} onKeyDown={handleKeyDown} disableGroupSeparators={true} groupSeparator={groupSeparator} decimalSeparator={decimalSeparator} allowDecimals={true} allowNegativeValue={false} decimalsLimit={precision} disabled={isLocked} />
    <div className='input-group-append'>
      <div className='input-group-text'>{unit}</div>
    </div>
  </div>
}

const QuantityFrom = ({ priceRange, setRange, unit, icon, isLocked = false }) => {
  function handleSet(quantity_from) {
    setRange(priceRange.index, { ...priceRange, quantity_from })
  }

  return <div className='col-sm-4 price-range__quantity'>
    <Input name={`offer[price_ranges_attributes][${priceRange.index}][quantity_from]`} value={priceRange.quantity_from} setValue={handleSet} unit={unit} icon={icon} isLocked={isLocked} isInvalid={!!priceRange.errors['quantity_from']} />
  </div>
}

const QuantityTo = ({ priceRange, setRange, unit, icon, isLocked = false }) => {
  function handleSet(quantity_to) {
    setRange(priceRange.index, { ...priceRange, quantity_to })
  }

  return <div className='col-sm-4 price-range__quantity'>
    <Input name={`offer[price_ranges_attributes][${priceRange.index}][quantity_to]`}value={priceRange.quantity_to} setValue={handleSet} unit={unit} icon={icon} isLocked={isLocked} isInvalid={!!priceRange.errors['quantity_to']} />
  </div>
}

const PricePerQuantity = ({ priceRange, setRange, unit, precision }) => {
  function handleSet(price_per_quantity) {
    setRange(priceRange.index, { ...priceRange, price_per_quantity })
  }

  return <div className='col-sm-4 price-range__price'>
    <PriceInput name={`offer[price_ranges_attributes][${priceRange.index}][price_per_quantity]`}value={priceRange.price_per_quantity} setValue={handleSet} unit={unit} precision={precision} isInvalid={!!priceRange.errors['price_per_quantity']} />
  </div>
}

const PriceRange = ({ priceRanges, priceRange, salesUnit, addRange, setRange, removeRange, i, quantityUnit, priceUnit, icon }) => {
  const { images, productType } = useContext(context)

  const priceRangesCount = priceRanges.filter(r => !r.destroy).length
  const isLast = i === priceRangesCount - 1
  const isComplete = !!priceRange.quantity_from && !!priceRange.quantity_to && !!priceRange.price_per_quantity

  function handleAdd(e) {
    e.preventDefault()
    e.stopPropagation()

    addRange()
  }

  function handleRemove(e) {
    e.preventDefault()
    e.stopPropagation()

    removeRange(priceRange)
  }

  return <div className='row align-items-center'>
    <div className='col-sm-6'>
      <div className='row align-items-center'>
        {priceRange.id && <input type='hidden' name={`offer[price_ranges_attributes][${priceRange.index}][id]`} value={priceRange.id} />}
        <QuantityFrom priceRange={priceRange} setRange={setRange} unit={quantityUnit} icon={icon} isLocked={i !== 0} />
        <QuantityTo priceRange={priceRange} setRange={setRange} unit={quantityUnit} icon={icon} />
        <PricePerQuantity priceRange={priceRange} setRange={setRange} unit={priceUnit} precision={productType === 'SolarModule' ? 3 : 2} />
      </div>
    </div>
    <div className='col-sm-1 d-flex aligen-items-center'>
      {priceRangesCount > 1 && <button className='btn btn-unstyled' onClick={handleRemove}>
        <img src={images["icon_remove"]} width="24" height="24" />
      </button>}
      {priceRangesCount < 3 && isLast && isComplete && <button className='btn btn-unstyled' onClick={handleAdd}>
        <img src={images["icon_add"]} width="24" height="24" />
      </button>}
    </div>
    <div className='col-sm-5'>
      <div className='offer-price-range-marketplace px-3 d-flex flex-row align-items-center'>
        <PriceVisualization priceRange={priceRange} salesUnit={salesUnit} quantityUnit={quantityUnit} priceUnit={priceUnit} />
      </div>
    </div>
  </div>
}

export default PriceRange
