import React from 'react'

import Color from '../common/Color'

const SolarModuleHit = ({hit, onSelect, current}) => {
  let className = ''
  if(current) className = 'current'

  return <li className={className} onClick={() => onSelect(hit)}>
    <div className='name'><strong>{hit.producer_name}</strong> - {hit.name} <Color hit={hit} /></div>
    <div className='detail-s'>{hit.single_power}W</div>
    <div className='detail-w'>{hit.measurements}</div>
    <div className='detail-s'>{hit.cell_type_name}</div>
  </li>
}

export default SolarModuleHit
