import React from "react"

import { SortArrowDown, SortArrowUp, SortArrowUpDown } from "@/components/OffersIndex/Icons"

const SORT_DIRECTIONS = {
  asc: "asc",
  desc: "desc",
  none: null
}

const getSortIcon = (direction) => {
  if (direction == SORT_DIRECTIONS.asc) return <SortArrowUp />
  if (direction == SORT_DIRECTIONS.desc) return <SortArrowDown />

  return <SortArrowUpDown />
}

const getSortDirection = (currentIndex, indexes) => {
  if (!indexes.includes(currentIndex)) { return SORT_DIRECTIONS.none }
  if (currentIndex.endsWith(SORT_DIRECTIONS.asc)) { return SORT_DIRECTIONS.asc }

  return SORT_DIRECTIONS.desc
}

const SortHeader = ({defaultIndex, currentIndex, attribute, title, changeSortOrder}) => {
  const indexes = [
    `${defaultIndex}_${attribute}_asc`,
    `${defaultIndex}_${attribute}_desc`
  ]

  const currentSortDirection = getSortDirection(currentIndex, indexes)
  const nextSortDirection = currentSortDirection == SORT_DIRECTIONS.asc ? SORT_DIRECTIONS.desc : SORT_DIRECTIONS.asc

  return <span className="sales-details-header__item clickable d-inline-flex justify-content-center align-items-center flex-gap-1" onClick={() => changeSortOrder(attribute, nextSortDirection)}>
    <span>{title}</span>
    <span className="sales-details-header__sort-icon">{getSortIcon(currentSortDirection)}</span>
  </span>
}

export default SortHeader
