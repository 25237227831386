import React  from 'react'

import dayjs from 'dayjs/esm/index.js'
import relativeTime from 'dayjs/esm/plugin/relativeTime/index.js'

import 'dayjs/esm/locale/da'
import 'dayjs/esm/locale/de'
import 'dayjs/esm/locale/en'
import 'dayjs/esm/locale/es'
import 'dayjs/esm/locale/fr'
import 'dayjs/esm/locale/it'
import 'dayjs/esm/locale/nl'

import I18n from '@/src/i18n.js.erb'

const RelativeTime = ({time, future, past}) => {
  dayjs.locale(I18n.locale)
  dayjs.extend(relativeTime)

  const then = dayjs.unix(time)

  if(future && past) {
    const now = dayjs()

    if(then < now)
      return <span dangerouslySetInnerHTML={{__html: I18n.t(past, {time: then.fromNow(true)})}} />
    else
      return <span dangerouslySetInnerHTML={{__html: I18n.t(future, {time: then.fromNow(true)})}} />
  }

  return then.fromNow()
}

export default RelativeTime
