import React, { useState } from 'react';
import Sidebar from './Sidebar';
import { useGetTicketsQuery } from '../../api/tickets';
import { dateFormatAbbrev } from '../common/DateFormat'
import I18n from '@/src/i18n.js.erb'
import TicketCard from './TicketCard';
import { useGetAdminsQuery } from '../../api/admin/users';
import IconAddTask from './icons/add_task.svg';
import Comments from './TicketCard/Comments';
import BSN from 'bootstrap.native/dist/bootstrap-native.esm.min.js'

const Dashboard = ({ userId, isAdmin }) => {
  const [ticketId, setTicketId] = useState(null);

  const [filters, setFilters] = useState({ timeframe: localStorage.getItem('timeframe') || 'today', user_ids: isAdmin ? [userId] : [], status: 'open' });

  const { data, isLoading, isSuccess, isFetching, refetch } = useGetTicketsQuery(filters, { pollingInterval: 10000 });
  const { data: usersData } = useGetAdminsQuery();

  const openTicketModal = (id) => {
    const ticketModal = document.querySelector('#ticketModal');

    if (ticketModal) {
      const instance = new BSN.Modal('#ticketModal');
      instance.show();

      setTicketId(id);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar isAdmin={isAdmin} meta={data?.meta} users={usersData} filters={filters} setFilters={setFilters} />

        <div className="col-md-9 content">
          <div className='d-flex align-items-center flex-gap-4 justify-content-between flex-wrap'>
            <div className='d-flex align-items-center flex-gap-4'>
              <h2 className='m-0'>
                {I18n.t('frontend.tickets.dashboard.filters.' + filters.timeframe)}
              </h2>
              <span>{dateFormatAbbrev(new Date(), 'DD. dd MMM')}</span>

              {(isLoading || isFetching) && <div class="spinner-border" role="status">
                <span class="sr-only"></span>
              </div>}
            </div>

            <a data-turbo-frame="modal-frame" class="btn add-task-btn" href={`/${I18n.locale}/tickets/new`}>
              <img className='mr-2' src={IconAddTask} />
              {I18n.t('frontend.tickets.dashboard.general.new_task')}
            </a>
          </div>

          <Comments setTicketId={setTicketId} id={ticketId} />

          <div style={{ height: '25px' }}></div>

          {data?.tickets?.map((ticket) => {
            return (
              <TicketCard openTicketModal={openTicketModal} isAdmin={isAdmin} ticket={ticket} users={usersData} />
            )
          })}

          <div style={{ height: '25px' }}></div>
        </div>
      </div>
    </div >
  );
};

export default Dashboard;
