import React from 'react'

const Toggle = ({ checked, onChange, name, children = null, ...props }) => {
  const className = props.className ? props.className + ' toggle-control toggle-control--small' : 'toggle-control toggle-control--small'

  return <label for={name} className={className} {...props}>
    <input type='checkbox' name={name} checked={checked} onChange={onChange} className='control' />
    <span class='control' />
    {children}
  </label>
}

export default Toggle
