import React from 'react'

import I18n from '@/src/i18n.js.erb'
import dayjs from 'dayjs/esm/index.js'

import FormattedPrice from '../common/FormattedPrice'
import RelativeTime from '../common/RelativeTime'

import EscrowContractStatus from './EscrowContractStatus'
import ReceiptIcon from '../../../assets/images/icons/bsi/receipt_long.svg'
import PaidIcon from '../../../assets/images/icons/bsi/paid.svg'
import QuickReorder from '../../../assets/images/icons/bsi/quick_reorder.svg'

import CircleBlueCheck from '../../../assets/images/icons/bsi/circle_blue_check.svg'
import CircleGrayCheck from '../../../assets/images/icons/bsi/circle_dark_gray_check.svg'
import InquiryOffers from './InquiryOffers'
import SendMoneyIcon from '../../../assets/images/icons/bsi/send_money.svg'
import TimeAgoIcon from '../../../assets/images/icons/bsi/update.svg'


const Hit = ({ hit, role, locale, isAdmin }) => {
  const open = () => {
    window.prevScrollY = window.scrollY

    if (role === 'vendor')
      Turbo.visit('/' + locale + '/vendor/inquiries/' + hit.id)
    else
      Turbo.visit('/' + locale + '/inquiries/' + hit.id)
  }

  const freightCostsSet = Number.isFinite(hit.freight_cost)
  const totalAmount = freightCostsSet ? hit.price + hit.freight_cost : hit.price

  return <div className='mb-4 card'>
    <div className={`card-header clickable card-header-${hit.status} ${hit.cancellation_requested && 'card-header-cancellation'}`} onClick={open}>
      <div className='row'>

        <div className='card-status-name pl-5 col-md-6 d-flex flex-row align-items-center'>
          {hit.status == 'accepted_by_vendor' && !hit.cancellation_requested && <>
            <div className='card-status-icons__check mr-4 card-status-icons__check--accepted' />
          </>}

          {hit.status == 'accepted_by_buyer' && role == 'buyer' && <>
            <div className='card-status-icons__check mr-4 card-status-icons__check--accepted' data-toggle='tooltip' data-placement='top' title={I18n.t('frontend.inquiries_index.status_tooltips.accepted_by_buyer', { accepted_at: dayjs(hit.buyer_accepted_at).format('DD.MM.YYYY') })} />

            {I18n.t('frontend.inquiries_index.hit.thanks_for_your_purchase')}
          </>}

          {hit.status == 'accepted_by_buyer' && role == 'vendor' && <>
            <div className='card-status-icons__check mr-4' data-toggle='tooltip' data-placement='top' title={I18n.t('frontend.inquiries_index.status_tooltips.not_accepted_by_vendor')} />

            {I18n.t('frontend.inquiries_index.hit.confirm_the_order')}
          </>}

          {hit.status == 'conditions_changed' && <>
            {I18n.t('frontend.inquiries_index.hit.conditions_changed')}
          </>}

          {hit.status == 'denied' && role == 'buyer' && hit.denial_type == 'denied_by_buyer' && <>
            {I18n.t('frontend.inquiries_index.hit.request_closed_by_you')}
          </>}

          {hit.status == 'denied' && (hit.denial_type == 'denied_by_solartraders' || (role == 'vendor' && (hit.denial_type == 'denied_by_buyer' || hit.denial_type == 'denied_by_vendor'))) && <>
            {I18n.t('frontend.inquiries_index.hit.request_closed')}
          </>}

          {hit.cancellation_requested && hit.status != 'canceled' && <>
            {I18n.t('frontend.inquiries_index.hit.cancelation_under_review')}
          </>}

          {hit.status == 'canceled' && <>
            {I18n.t('frontend.inquiries_index.hit.order_cancelled')}
          </>}
        </div>

        <div className='card-status-name col-md-4 pl-5 d-flex flex-row align-items-center justify-content-end'>
          <img className='pr-2' src={SendMoneyIcon} />
          ID #{hit.potential_id}
        </div>

        <div className='card-price col-md-2 d-flex flex-row align-items-center justify-content-end'>
          <img className='pr-2' src={TimeAgoIcon} />
          <RelativeTime time={hit.latest_update_at_ts} />
        </div>
      </div>
    </div>

    <div className='card-body row clickable' onClick={open}>
      <div className='col-md-12'>
        <InquiryOffers inquiry={hit} />
      </div>
    </div>

    <div className='card-actions card-actions-bar'>
      <div className='col-md-8'>
        {(hit.status == 'accepted_by_vendor' || role == 'buyer') && <div className='d-flex justify-content-between card-actions-bar__left p-2'>
          {hit.status == 'accepted_by_vendor' && <div className=''>
            <div className='d-flex flex-gap-5'>
              <div className='card-actions-bar__icons-set'>
                <img className='card-actions-bar__icons-set-float' src={hit.invoiced_by_vendor_at ? CircleBlueCheck : CircleGrayCheck} />
                <img style={{ opacity: hit.invoiced_by_vendor_at ? 1 : 0.5 }} src={ReceiptIcon} />
              </div>

              {hit.invoiced_by_vendor_at && <>
                <div className='card-actions-bar__icons-set'>
                  <img className='card-actions-bar__icons-set-float' src={hit.paid_by_buyer_at ? CircleBlueCheck : CircleGrayCheck} />
                  <img style={{ opacity: hit.paid_by_buyer_at ? 1 : 0.5 }} src={PaidIcon} />
                </div>
              </>}

              {hit.paid_by_buyer_at && <>
                <div className='card-actions-bar__icons-set'>
                  <img className='card-actions-bar__icons-set-float' src={hit.shipped_at ? CircleBlueCheck : CircleGrayCheck} />
                  <img style={{ opacity: hit.shipped_at ? 1 : 0.5 }} src={QuickReorder} />
                </div>
              </>}

              <div className='card-actions-bar__icons-set'>
                {hit.shipped_at && hit.tracking_link && <a target='_blank' rel='noreferrer noopener nofollow' href={hit.tracking_link}>{I18n.t('frontend.inquiries_index.hit.show_tracking_link')}</a>}
              </div>
            </div>
            <div className='font-italic'>
              {!hit.invoiced_by_vendor_at && <>{I18n.t('frontend.inquiries_index.hit.waiting_for_invoice')}</>}

              {hit.invoiced_by_vendor_at && !hit.paid_by_buyer_at && <>  {I18n.t('frontend.inquiries_index.hit.waiting_confirmation')}</>}

              {hit.invoiced_by_vendor_at && hit.paid_by_buyer_at && !hit.shipped_at && <>{I18n.t('frontend.inquiries_index.hit.goods_not_dispatched')} </>}

              {hit.shipped_at && <>Shipping confirmed ({dayjs(hit.shipped_at).format('DD/MM/YYYY')})</>}
            </div>
          </div>}

          <div className='text-right pr-4 w-100'>
            {(role == 'buyer' || hit.status == 'accepted_by_vendor') && <>
              {hit.shipping_address?.company_name}, {hit.shipping_address?.city}
            </>}
          </div>
        </div>}
      </div>
      <div className='col-md-4 fs-16 px-4 m-auto'>
        <table className='w-100'>
          <tbody>
            <tr>
              <td className='text-right pr-5'>{I18n.t('frontend.inquiries_index.hit.freight_cost')}</td>
              <td className='text-right fs-16'>
                {freightCostsSet && <FormattedPrice price={hit.freight_cost} />}
                {!freightCostsSet && <span >{I18n.t('frontend.inquiries_index.hit.costs_pending')}</span>}
              </td>
            </tr>
            <tr className='font-weight-bolder'>
              <td className='text-right pr-5'>Total</td>
              <td className='text-right'><FormattedPrice price={totalAmount} /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <EscrowContractStatus hit={hit} />

    {isAdmin && <div className='card-actions card-actions-bar' style={{ background: 'white' }}>
      <div className='col-md-8'>
        <div className='d-flex justify-content-between card-actions-bar__left p-2' style={{ color: '#969696' }}>
          <div className='d-flex w-100 flex-gap-5'>
            <div>
              <p className='m-2'>
                <b>{hit.vendor_company}</b><br />
                {hit.vendor_full_name}
              </p>
            </div>
            <div>
              <p className='m-2'>
                {hit.buyer_company && <><b>{hit.buyer_company}</b><br /></>}
                {hit.buyer_full_name}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-4 fs-16 px-4 m-auto'>
        <table className='w-100'>
          <tbody>
            <tr>
              <td className='text-left pr-5'>
                {hit.formatted_invoice_number && hit.formatted_invoice_number != 'ST-XXXX' && <>
                  <a className='d-block m-0 p-0' style={{ lineHeight: '18px' }} href={`/${I18n.locale}/vendor/inquiries/${hit.id}/invoice`} rel='noreferrer noopener nofollow' target='_blank'>
                    {hit.formatted_invoice_number}
                  </a>
                  <span style={{ color: '#969696' }}>
                    <FormattedPrice price={totalAmount + parseFloat(hit.fee)} />
                  </span>
                </>}
              </td>
              <td className='text-right fs-20 font-weight-bolder' style={{ color: '#969696' }}>
                <FormattedPrice price={totalAmount} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>}
  </div >
}

export default Hit
