import dayjs from 'dayjs/esm/index.js'

import I18n from '@/src/i18n.js.erb'

const AvailableAsOf = ({ hit }) => {
  if(hit.in_stock) {
    return I18n.t('frontend.general.in_stock')
  } else {
    const available_starting_at = dayjs.unix(hit.available_starting_at_ts)
    const today = dayjs().startOf('day')

    if(available_starting_at <= today)
      return I18n.t('frontend.general.in_stock')
    else
      return I18n.t('frontend.general.available_as_of', {date: available_starting_at.format('DD.MM.YYYY')})
  }
}

export default AvailableAsOf
