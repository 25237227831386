import React from 'react';
import Checkbox from '../common/Checkbox'
import { connectMenu } from 'react-instantsearch-dom'
import I18n from '@/src/i18n.js.erb'

const MixedFilter = ({ items, currentRefinement, refine, isVendor = false }) => {
  const filter = value => e => {
    e.preventDefault()

    if (value != null && value != currentRefinement) {
      refine(value)
    } else {
      refine(null);
    }
  }

  let count = { all: 0 }
  for (let item of items) {
    count[item.label] = item.count
    count['all'] += item.count
  }

  return (
    <>
      <ul class='refinement-list'>
        { isVendor && <li>
          <Checkbox checked={currentRefinement === 'freight_missing'} onChange={filter('freight_missing')}>
            <div className='d-flex align-items-center justify-content-between flex-greed'>
              <div className='truncate'>{I18n.t('frontend.inquiries_index.filter.freight_missing')}</div>
              <div className='refinement-list__count text-small'>{count['freight_missing'] || 0}</div>
            </div>
          </Checkbox>
        </li> }
        { isVendor && <li>
          <Checkbox checked={currentRefinement === 'invoice_missing'} onChange={filter('invoice_missing')}>
            <div className='d-flex align-items-center justify-content-between flex-greed'>
              <div className='truncate'>{I18n.t('frontend.inquiries_index.filter.invoice_missing')}</div>
              <div className='refinement-list__count text-small'>{count['invoice_missing'] || 0} </div>
            </div>
          </Checkbox>
        </li> }
        <li>
          <Checkbox checked={currentRefinement === 'paid'} onChange={filter('paid')}>
            <div className='d-flex align-items-center justify-content-between flex-greed'>
              <div className='truncate'>{I18n.t('frontend.inquiries_index.filter.paid')}</div>
              <div className='refinement-list__count text-small'>{count['paid'] || 0}</div>
            </div>
          </Checkbox>
        </li>
        <li>
          <Checkbox checked={currentRefinement === 'overdue'} onChange={filter('overdue')}>
            <div className='d-flex align-items-center justify-content-between flex-greed'>
              <div className='truncate'>{I18n.t('frontend.inquiries_index.filter.overdue')}</div>
              <div className='refinement-list__count text-small'>{count['overdue'] || 0}</div>
            </div>
          </Checkbox>
        </li>
        { isVendor && <li>
          <Checkbox checked={currentRefinement === 'ship_now'} onChange={filter('ship_now')}>
            <div className='d-flex align-items-center justify-content-between flex-greed'>
              <div className='truncate'>{I18n.t('frontend.inquiries_index.filter.ship_now')}</div>
              <div className='refinement-list__count text-small'>{count['ship_now'] || 0}</div>
            </div>
          </Checkbox>
        </li> }
      </ul>
    </>
  )
}

export default connectMenu(MixedFilter);
