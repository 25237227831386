import React from 'react';
import { Cart } from './Icons'
import FormattedPriceOptions from '../common/FormattedPriceOptions'

const UserQuantityCheckoutCTA = ({ offer, hit, price, quantity }) => {
  const calculatedPrice = () => {
    if (hit?.category_name == 'solar_modules') {
      return price * quantity * hit?.single_power;
    }

    return price * quantity;
  }

  return <div class="d-flex btn-rounded btn p-0 marketplace__cta-checkout-qty">
    <span className='p-2 px-3 bg-white marketplace__cta-checkout-qty__price'>
      <FormattedPriceOptions price={calculatedPrice()} precision={2} smallEuroSign={true} />
    </span>
    <div className='p-2 marketplace__cta-checkout-qty__icon px-3'>
      <Cart />
    </div>
  </div>
}

export default UserQuantityCheckoutCTA;
