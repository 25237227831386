import React               from 'react'
import { createConnector } from 'react-instantsearch-dom'

const getIndexId = ({ais, multiIndexContext}) => multiIndexContext ? multiIndexContext.targetedIndex : ais.mainTargetedIndex

const connectNumericMin = createConnector({
  displayName: 'NumericMin',

  getProvidedProps(props, searchState) {
    const attribute         = props.attribute + '_min'
    const currentRefinement = searchState[attribute] || ''

    return {
      currentRefinement
    }
  },

  refine(props, searchState, nextRefinement) {
    const attribute = props.attribute + '_min'

    return {
      ...searchState,
      [attribute]: nextRefinement ? parseInt(nextRefinement) : null
    }
  },

  getSearchParameters(searchParameters, props, searchState) {
    const attribute = props.attribute + '_min'

    if(searchState[attribute])
      return searchParameters.addNumericRefinement(props.attribute, '>=', searchState[attribute])
    else
      return searchParameters.removeNumericRefinement(props.attribute)
  },

  cleanUp(props, searchState) {
    const attribute = props.attribute + '_min'

    const {[attribute]: omit, ...nextSearchState} = searchState

    return nextSearchState
  },

  getMetadata(props, searchState) {
    const attribute = props.attribute + '_min'

    return {
      id: attribute,
      index: getIndexId({
        ais: props.contextValue,
        multiIndexContext: props.indexContextValue
      }),
      items: searchState[attribute]
        ? [
            {
              label: `${searchState[attribute]}`,
              value: (nextState) => ({
                ...nextState,
                [attribute]: null
              })
            }
          ]
        : []
    }
  }
})

const NumericMin = ({ currentRefinement, refine, label, placeholder = '', unit, className, ...props }) => {
  const changed = e => {
    refine(e.target.value)
  }

  const klassName = props.className ? 'input-group input-group--small d-inline-flex align-items-center ' + props.className : 'input-group input-group--small d-inline-flex align-items-center'

  const inputClassName = unit ? 'form-control' : 'form-control form-control--small'
  const input = <input type='text' value={currentRefinement || ''} className={inputClassName} onChange={changed} placeholder={placeholder} />

  if(unit) return <div className={klassName} {...props}>
    {input}
    <div className='input-group-append'>
      <div className='input-group-text'>{unit}</div>
    </div>
  </div>

  return <>
    {label && <label>{label}</label>}
    {input}
  </>
}

export default connectNumericMin(NumericMin)
