import { useState, useRef } from 'react'
import qs                   from 'qs'

import I18n from '@/src/i18n.js.erb'

const getCategoryNameBySlug = (slug, slugs) => {
  return Object.keys(slugs).find(s => slugs[s] === slug)
}

const parseQueryString = queryString => {
  const qsData = qs.parse(queryString)

  if(qsData.range) {
    for(const key of Object.keys(qsData.range)) {
      const range = qsData.range[key]

      range.min = parseFloat(range.min)
      range.max = parseFloat(range.max)
    }
  }

  return qsData
}

const urlToSearchState = (baseUrl, categorySlugs, initialState = null) => {
  if(initialState) return parseQueryString(initialState)

  let state = {}

  const [ url, queryString ] = window.location.href.split('?')
  const categoriesString     = url.split(baseUrl + '/')[1]

  if(categoriesString) {
    const categories = categoriesString.split('/').map(c => getCategoryNameBySlug(c, categorySlugs))

    state.hierarchicalMenu = {'categories.lvl0': categories.join(' > ')}
    state.categories = categories
  }

  if(queryString) {
    const qsData = parseQueryString(queryString)

    state = {
      ...state,
      ...qsData
    }
  }

  return state
}

const searchStateToUrl = (baseUrl, categorySlugs, state, prevUrl) => {
  let url = '/' + I18n.locale + baseUrl
  let s = {...state}

  delete s['configure']

  if(s.hierarchicalMenu && s.hierarchicalMenu['categories.lvl0'] !== undefined) {
    const {hierarchicalMenu: hm, ...withoutHm} = s
    const {['categories.lvl0']: categories, ...hmRest} = hm

    const cats = categories.split(' > ').map(c => categorySlugs[c])

    url += '/' + cats.join('/')

    if(Object.values(hmRest).length > 0) {
      s = {
        ...foo,
        hierarchicalMenu: hmRest
      }
    } else {
      s = withoutHm
    }
  }

  delete s.page

  if(Object.values(s)) {
    url += '?' + qs.stringify(s)
  }

  // We need a fully-qualified URL object for Turbo Drive.
  url = `${window.location.origin}${url}`

  // Suppress first state change, as we don't want to write search params to the url at initial page load
  if(prevUrl.current === null) {
    prevUrl.current = url

    return
  }

  if(url !== prevUrl.current) {
    prevUrl.current = url
    history.pushState(state, null, url)
    Turbo.navigator.history.push(new URL(url))
  }
}

export default (baseUrl, categorySlugs, initialState = null) => {
  const [ state, setState ] = useState(() => urlToSearchState(baseUrl, categorySlugs, initialState))
  const prevUrl             = useRef(null)

  const stateChanged = newState => {
    searchStateToUrl(baseUrl, categorySlugs, newState, prevUrl)
    setState(newState)
  }

  return [state, stateChanged]
}
