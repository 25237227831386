import dayjs from "dayjs/esm/index.js"

import I18n from "@/src/i18n.js.erb"

const WILL_EXPIRE_THRESHOLD_IN_DAYS = 7

const OfferStatus = ({ hit }) => {
  const now = dayjs()
  const expiresAt = dayjs.unix(hit.published_until_ts)
  const willExpireSoon = now.add(WILL_EXPIRE_THRESHOLD_IN_DAYS, "days")

  if (expiresAt > willExpireSoon) {
    // Not expired
    return <span class="text-success">{I18n.t("frontend.my_offers_index.hit.status.active")}</span>
  } else if (expiresAt < now) {
    // Expired
    return <span class="text-danger">{I18n.t("frontend.my_offers_index.hit.status.inactive")}</span>
  } else {
    // Will expire soon
    return <>
      <span class="text-warning">{I18n.t("frontend.my_offers_index.hit.status.will_expire_soon")}</span>
      <br /><span>{expiresAt.format('DD.MM.YYYY')}</span>
    </>
  }
}

export default OfferStatus
