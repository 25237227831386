import React, { useState, useContext } from "react"

import I18n from "@/src/i18n.js.erb"

import ValueBetweenFilter from "../algolia/ValueBetweenFilter"
import { searchContext } from "./SearchContextProvider"

const VALUES = {
  "pcs": { attribute1: "offer.min_quantity", attribute2: "offer.quantity" },
  "pallets": { attribute1: "offer.min_quantity_pallets", attribute2: "offer.quantity_pallets" },
  "containers": { attribute1: "offer.min_quantity_containers", attribute2: "offer.quantity_containers" },
  "kw": { attribute1: "offer.min_quantity_kw", attribute2: "offer.quantity_kw" }
}

const Dropdown = ({ value, onChange }) => {
  const applyUnitChanged = (e) => { onChange(e.target.value) }

  return <select className="custom-select pl-3 mr-5" onChange={applyUnitChanged}>
    <option selected={value == "pcs"} value="pcs">{I18n.t("frontend.general.quantity_filter.pcs")}</option>
    <option selected={value == "pallets"} value="pallets">{I18n.t("frontend.general.quantity_filter.pallets")}</option>
    <option selected={value == "containers"} value="containers">{I18n.t("frontend.general.quantity_filter.containers")}</option>
    <option selected={value == "kw"} value="kw">{I18n.t("frontend.general.quantity_filter.kw")}</option>
  </select>
}

const ModuleQuantityFilter = ({ asInputGroup = false }) => {
  const context = useContext(searchContext)
  const [filterBy, setFilterBy] = useState(context.filters.quantity_unit || "pcs")

  const handleChange = value => {
    setFilterBy(value)
  }

  if(asInputGroup) {
    return <div className="input-group product-quantity-filter is--dark is--large">
      <ValueBetweenFilter inputOnly attribute1={VALUES[filterBy].attribute1} attribute2={VALUES[filterBy].attribute2} filterBy={filterBy} key={`${VALUES[filterBy].attribute1}_${VALUES[filterBy].attribute2}`} />
      <div className="input-group-append">
        <div className="product-quantity-filter__label is--right p-0" style={{ width: "6.35rem" }}>
          <Dropdown value={filterBy} onChange={handleChange} />
        </div>
      </div>
    </div>
  }

  return <div className="input-group input-group--small">
    <ValueBetweenFilter inputOnly attribute1={VALUES[filterBy].attribute1} attribute2={VALUES[filterBy].attribute2} placeholder={I18n.t("frontend.offers_index.filters.quantity.solar_modules.placeholder_" + filterBy)} key={`${VALUES[filterBy].attribute1}_${VALUES[filterBy].attribute2}`} />
    <div className="input-group-append">
      <div className="input-group-text">
        <Dropdown value={filterBy} onChange={handleChange} />
      </div>
    </div>
  </div>
}

export default ModuleQuantityFilter
