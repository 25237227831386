import React, { useEffect, useRef } from 'react';
import IconPersonAdd from '../../../../assets/images/icons/bsi/person_add.svg'
import Chip from '../components/Chip'
import BSN from 'bootstrap.native/dist/bootstrap-native.esm.min.js'
import { useUpdateTicketMutation } from '../../../api/tickets';
import I18n from 'i18n-js';
import useBootstrapDropdown from '../../hooks/useBootstrapDropdown';

const Assignees = ({ ticket, users }) => {
  const assigneesRef = useBootstrapDropdown();
  const [updateTicket, { isLoading }] = useUpdateTicketMutation();

  const removeAssignee = (userId) => {
    const updatedUsers = ticket.users.filter(user => user.id !== userId);
    updateTicket({ id: ticket.id, user_ids: updatedUsers.map((u) => u.id)?.join(",") });
  }

  const addAssignee = (userId) => {
    const updatedUsers = [...ticket.users, users.find(user => user.id === userId)];
    updateTicket({ id: ticket.id, user_ids: updatedUsers.map((u) => u.id)?.join(",") });
  }

  const selectAll = () => {
    updateTicket({ id: ticket.id, user_ids: users.map((u) => u.id)?.join(",") });
  }

  const unselectAll = () => {
    updateTicket({ id: ticket.id, user_ids: [] });
  }

  return (
    <div className='d-flex align-items-center flex-gap-3'>
      <div className="dropdown">
        <img ref={assigneesRef} className="dropdown-toggle clickable" src={IconPersonAdd} id={`dropdown-assignee-${ticket.id}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />

        <div className="dropdown-menu dropdown-menu-left py-0 min-w-15 dropdown-toggle-highlight py-2" aria-labelledby={`dropdown-assignee-${ticket.id}`} onClick={(e) => e.stopPropagation()} tabIndex="0">
          <div className="my-1 px-4 py-2 text-medium">

            {ticket?.users?.length == users?.length && <button disabled={isLoading} onClick={() => unselectAll()} className="link">{I18n.t('frontend.tickets.dashboard.general.unselect_all')}</button>}
            {ticket?.users?.length != users?.length && <button disabled={isLoading} onClick={() => selectAll()} className="link">{I18n.t('frontend.tickets.dashboard.general.select_all')} </button>}
          </div>

          <hr className="p-0 m-0 mb-2" />

          <AssigneesList isLoading={isLoading} users={users} ticket={ticket} addAssignee={addAssignee} removeAssignee={removeAssignee} />
        </div>
      </div>

      {
        ticket?.users?.map((user) => (
          <Chip
            onClose={() => removeAssignee(user.id)}
            name={`${user.first_name} ${user.last_name[0]}.`}
            key={user.id}
          />
        ))
      }
    </div >
  );
};

const AssigneesList = ({ users, ticket, addAssignee, removeAssignee, isLoading }) => {
  return (
    <>
      {users?.map((user) => {
        const selected = ticket.users?.some((u) => u.id == user.id);

        return (
          <label class="px-4 py-2 mb-0 text-medium" htmlFor={`ticket_${ticket.id}_user_ids_${user.id}`}>
            <input disabled={isLoading} onChange={() => selected ? removeAssignee(user.id) : addAssignee(user.id)} type="checkbox" id={`ticket_${ticket.id}_user_ids_${user.id}`} checked={selected} value={user.id} class="mr-2" />
            <span class="control"></span>
            <span>{user.first_name} {user.last_name}</span>
          </label>
        )
      })}
    </>
  )
}

export default Assignees;
