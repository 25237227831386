import { ResponsiveContainer, LineChart, CartesianGrid, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts'

const TotalOffersPerDay = ({ data }) => {
  const domain = null // ['dataMin - 10', 'dataMax + 10']

  return <ResponsiveContainer width='100%' aspect={3}>
    <LineChart data={data}>
      <CartesianGrid stroke="#CCC" strokeDasharray="3 3" />
      <Legend />
      <Tooltip  />

      <Line name="Modules" type="monotone" dataKey="sm" stroke="red" />
      <Line name="Inverters" type="monotone" dataKey="i" stroke="blue" />
      <Line name="Batteries" type="monotone" dataKey="b" stroke="green" />
      <Line name="Accessories" type="monotone" dataKey="a" stroke="orange" />

      <XAxis dataKey="date" />
      <YAxis domain={domain} />
    </LineChart>
  </ResponsiveContainer>
}

export default TotalOffersPerDay
