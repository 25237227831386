import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../api/store';
import Dashboard from './Dashboard'

const TicketsDashboard = ({ userId, isAdmin }) => {
  return (
    <Provider store={store}>
      <Dashboard isAdmin={isAdmin} userId={userId} />
    </Provider>
  );
};

export default TicketsDashboard;
