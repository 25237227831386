import React, { useState }     from 'react'
import { connectAutoComplete } from 'react-instantsearch-dom'

import SearchInput from './SearchInput'

const mod = (v, m) => ((v % (m - 1)) + (m - 1)) % (m - 1)

const Autocomplete = ({ refine, currentRefinement, hits, onSelect, selected, placeholder, Hit, defaultHits, ...hitProps }) => {
  const [ current, setCurrent ]   = useState(0)
  const [ focussed, setFocussed ] = useState(false)

  const onKeyPress = e => {
    switch(e.keyCode) {
      case 40:
        e.preventDefault()
        setCurrent(c => mod(c + 1, hits.length + 1))
        return
      case 38:
        e.preventDefault()
        setCurrent(c => mod(c - 1, hits.length + 1))
        return
      case 13:
        onSelect(hits[current])
        e.preventDefault()
        return
    }
  }

  const onFocus = () => {
    if(defaultHits) setFocussed(true)
  }

  const onBlur = () => {
    if(defaultHits) setTimeout(() => setFocussed(false), 200)
  }

  return <div class='search-input'>
    <SearchInput refine={refine} currentRefinement={currentRefinement} placeholder={placeholder} onFocus={onFocus} onBlur={onBlur} onKeyDown={onKeyPress} />
    {hits.length > 0 && (currentRefinement !== '' || selected) && <div className='autocomplete-hits-wrapper'>
      <ol className='autocomplete-hits'>
        {currentRefinement !== '' && !selected && hits.map((hit, i) => <Hit key={hit.objectID} hit={hit} onSelect={onSelect} current={current === i} {...hitProps} />)}
        {focussed && currentRefinement === '' && defaultHits && defaultHits.map((hit, i) => <Hit key={hit.id} hit={hit} onSelect={onSelect} current={current === i} {...hitProps} />)}
      </ol>
    </div>}
  </div>
}

export default connectAutoComplete(Autocomplete)
