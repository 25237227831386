import React from 'react';
import IconClose from '../icons/close.svg';

const Chip = ({ name, disableClose = false, hasColor = false, hasLink = false, onClose = () => { } }) => {
  const textColor = hasColor ? '#FFFFFF' : '#343A40';
  const bgColor = hasColor ? '#003560' : '#F4F4F4';

  return (
    <div className='d-flex align-items-center'>
      <div style={{ color: textColor, backgroundColor: bgColor }} className="d-flex text-small align-items-center text-nowrap max-h-175">

        {!hasLink && <span className="mr-auto px-2">{name}</span>}
        {hasLink && <a className="mr-auto px-2" href={hasLink}>{name}</a>}

        {!disableClose && <img style={{ borderLeftColor: '#D8D8D8' }} className="p-2 border-left clickable" onClick={onClose} src={IconClose} />}
      </div>
    </div>
  )
};

export default Chip;