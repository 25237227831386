import React                       from 'react'
import { connectToggleRefinement } from 'react-instantsearch-dom'

import CheckboxSquare from '../common/CheckboxSquare'

const ToggleRefinementCheckbox = ({ currentRefinement, refine, value, label }) => {
  const toggle = () => {
    if(currentRefinement)
      refine(null)
    else
      refine(value)
  }

  return <div className='toggle-filter'>
    <CheckboxSquare checked={currentRefinement} toggle={toggle} />
    <label onClick={toggle}>{label}</label>
  </div>
}

export default connectToggleRefinement(ToggleRefinementCheckbox)
