import React           from 'react'
import { connectHits } from 'react-instantsearch-dom'

import useHitsScrollPos from '../hooks/useHitsScrollPos'

import NoResults from '../algolia/NoResults'

import Hit from './Hit'

const equal = (prevProps, nextProps) => {
  if(prevProps.view !== nextProps.view) return false
  if(prevProps.hits.length !== nextProps.hits.length) return false

  for(let i = 0; i < nextProps.hits.length; i++) {
    if (nextProps.hits[i].objectID !== prevProps.hits[i].objectID) {
      return false
    }
  }

  return true
}

const Hits = React.memo(({ hits, ...props }) => {
  useHitsScrollPos(hits)

  if(hits.length > 0)
    return hits.map(hit => <Hit key={hit.id} hit={hit} {...props} />)
  else
    return <NoResults clearsQuery />
}, equal)

export default connectHits(Hits)
