import dayjs from 'dayjs/esm/index.js'

export default (state, action) => {
  const now = dayjs()

  switch (action.type) {
    case 'TOGGLE_SELECTION':
      if (!!state[action.id] && state[action.id].selected) {
        const newState = { ...state }
        newState[action.id] = { ...newState[action.id], selected: false }
        return newState
      } else {
        return { ...state, [action.id]: { ...state[action.id], selected: true, updates: {} } }
      }

    case 'RESET_SELECTIONS': {
      const newState = {}
      Object.keys(state).forEach(key => {
        newState[key] = { ...state[key], selected: false }
      })
      return newState

    } case 'SELECT_ALL': {
      const newState = { ...state }

      action.ids.forEach(id => {
        if (newState[id]) {
          if (!newState[id].deleted) newState[id] = { ...newState[id], selected: true }
        } else {
          newState[id] = { selected: true, updates: {} }
        }
      })
      return newState

    } case 'REPUBLISH_SELECTED': {
      const newState = {}

      Object.keys(state).forEach(key => {
        if (!state[key].selected) newState[key] = state[key]

        newState[key] = {
          ...state[key],
          selected: false,
          expiresAt: now.add(action.timeframe, 'weeks').unix(),
          updates: { ...state[key].updates, archived: false, published_until_ts: now.add(action.timeframe, 'weeks').unix(), updated_at_ts: now.unix() }
        }
      })

      return newState

    } case 'REPUBLISH': {
      const offer = state[action.id] || { selected: false, updates: {} }

      return {
        ...state,
        [action.id]: {
          ...offer,
          expiresAt: now.add(action.timeframe, 'weeks').unix(),
          updates: { ...offer.updates, archived: false, published_until_ts: now.add(action.timeframe, 'weeks').unix(), updated_at_ts: now.unix() }
        }
      }
    } case 'DEACTIVATE_SELECTED': {
      const newState = {}

      Object.keys(state).forEach(key => {
        if (!state[key].selected) {
          newState[key] = state[key]
        } else {
          newState[key] = {
            ...state[key],
            selected: false,
            expiresAt: 0,
            updates: { ...state[key].updates, published_until_ts: 0, updated_at_ts: now.unix() }
          }
        }
      })

      return newState

    } case 'DEACTIVATE': {
      const offer = state[action.id] || { selected: false, updates: {} }

      return {
        ...state,
        [action.id]: {
          ...offer,
          expiresAt: 0,
          updates: { ...offer.updates, published_until_ts: 0, updated_at_ts: now.unix(), quantity: 0 }
        }
      }
    } case 'ARCHIVE_SELECTED': {
      const newState = state[action.id] || {}
      const success = action.payload.success

      Object.keys(state).forEach(key => {
        if (!state[key].selected) {
          newState[key] = state[key]
        } else {
          const isSuccess = success.some(id => id == key);
          newState[key] = {
            ...state[key],
            selected: !isSuccess,
            archived: isSuccess,
            updates: { ...state[key].updates, archived: isSuccess, updated_at_ts: now.unix() }
          }
        }
      })

      return newState

    } case 'ARCHIVE': {
      const offer = state[action.id] || { selected: false, updates: {} }
      const success = action.payload.success

      return {
        ...state,
        [action.id]: {
          ...offer,
          archived: success.some(id => id == action.id),
          updates: { ...offer.updates, archived: success.some(id => id == action.id), updated_at_ts: now.unix(), quantity: 0 }
        }
      }
    } case 'DELETE_SELECTED': {
      const newState = {}

      Object.keys(state).forEach(key => {
        if (!state[key].selected) {
          newState[key] = state[key]
        } else {
          newState[key] = {
            ...state[key],
            selected: false,
            deleted: true
          }
        }
      })

      return newState

    } case 'DELETE': {
      const offer = state[action.id] || { selected: false, updates: {} }

      return {
        ...state,
        [action.id]: {
          ...offer,
          selected: false,
          deleted: true
        }
      }
    } case 'UPDATE_STOCK': {
      const offer = state[action.id] || { selected: false, updates: {} }

      return {
        ...state,
        [action.id]: {
          ...offer,
          updates: {
            ...offer.updates,
            quantity: action.stock
          }
        }
      }
    } case 'UPDATE': {
      const offer = state[action.id] || { selected: false, updates: {} }

      const min_quantity = (offer.updates && offer.updates.min_quantity) || offer.min_quantity
      const updates = {...action.data}

      if(min_quantity && updates.quantity && updates.quantity < min_quantity) updates.min_quantity = updates.quantity

      return {
        ...state,
        [action.id]: {
          ...offer,
          updates: {
            ...offer.updates,
            ...updates
          }
        }
      }
    } case 'UPDATE_MAX_PRICE': {
      const offer = state[action.id] || { selected: false, updates: {} }

      return {
        ...state,
        [action.id]: {
          ...offer,
          updates: {
            ...offer.updates,
            max_price: action.max_price
          }
        }
      }
    } case 'UPDATE_MIN_PRICE': {
      const offer = state[action.id] || { selected: false, updates: {} }

      return {
        ...state,
        [action.id]: {
          ...offer,
          updates: {
            ...offer.updates,
            min_price: action.min_price
          }
        }
      }
    } default:
      return state
  }
}
