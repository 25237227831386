import dayjs from 'dayjs/esm/index.js'

import I18n from '@/src/i18n.js.erb'

const Availability = ({ hit }) => {
  if (hit.available_starting_at_ts) {
    const available_starting_at = dayjs.unix(hit.available_starting_at_ts)
    const today = dayjs().startOf('day')

    if (available_starting_at > today) {
      return I18n.t('frontend.general.available_as_of', { date: available_starting_at.format('DD.MM.YYYY') })
    }
  }

  if (hit.in_stock && hit.quantity < hit.stock) {
    return <>
      <div style={{ width: '.875rem', height: '.875rem', verticalAlign: 'baseline' }} className='card-status-icons__check mr-2 card-status-icons__check--accepted' />
      {I18n.t('frontend.inquiries_index.in_stock')}
    </>
  }

  return null
}

export default Availability
