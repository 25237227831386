import React               from 'react'
import { createConnector } from 'react-instantsearch-dom'

import CheckboxSquare from '../common/CheckboxSquare'

const getIndexId = ({ais, multiIndexContext}) => multiIndexContext ? multiIndexContext.targetedIndex : ais.mainTargetedIndex

const ATTRIBUTE = 'active_product'
const INTERVAL  = 31536000 // 1 year

const connectToggleActive = createConnector({
  displayName: 'ToggleActive',

  getProvidedProps(props, searchState) {
    const currentRefinement = searchState[ATTRIBUTE] || false

    return {
      currentRefinement
    }
  },

  refine(props, searchState, nextRefinement) {
    return {
      ...searchState,
      [ATTRIBUTE]: nextRefinement ? nextRefinement : false
    }
  },

  getSearchParameters(searchParameters, props, searchState) {
    const threshold = Math.floor(new Date() / 1000) - INTERVAL

    if(searchState[ATTRIBUTE])
      return searchParameters.addNumericRefinement('latest_activity_at_ts', '>=', threshold)
    else
      return searchParameters.removeNumericRefinement('latest_activity_at_ts')
  },

  cleanUp(props, searchState) {
    const {[ATTRIBUTE]: omit, ...nextSearchState} = searchState

    return nextSearchState
  },

  getMetadata(props, searchState) {
    return {
      id: ATTRIBUTE,
      index: getIndexId({
        ais: props.contextValue,
        multiIndexContext: props.indexContextValue
      }),
      items: searchState[ATTRIBUTE]
        ? [
            {
              label: `${searchState[ATTRIBUTE]}`,
              value: (nextState) => ({
                ...nextState,
                [ATTRIBUTE]: null
              })
            }
          ]
        : []
    }
  }
})

const ToggleActive = ({currentRefinement, refine, label}) => {
  const toggle = () => {
    refine(!currentRefinement)
  }

  return <div className='toggle-active'>
    <CheckboxSquare checked={currentRefinement} toggle={toggle} />
    <label onClick={toggle}>{label}</label>
  </div>
}

export default connectToggleActive(ToggleActive)
