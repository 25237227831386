import React, { useState, useContext, useEffect, useRef } from 'react'
import CurrencyInput from 'react-currency-input-field'

import { context } from '../PriceRangesForm'

const Modal = ({ opened, title, children }) => {
  if(!opened) return null

  return <div id='modal-frame'>
    <div className='card-modal-wrapper'>
      <div className='card card-modal card-default'>
        <h5 className='card-header'>{title}</h5>
        <div className='card-body p-3'>{children}</div>
      </div>
    </div>
  </div>
}

const SizeForm = ({ sizeValue, label, icon, onSubmit, onCancel }) => {
  const { text } = useContext(context)
  const [ value, setValue ] = useState(sizeValue)
  const inputRef = useRef(null)

  useEffect(() => {
    const input = inputRef.current
    if(input) input.focus()
  }, [inputRef])

  function handleChange(v) {
    setValue(v)
  }

  function handleKeyDown(e) {
    if(e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()

      onSubmit(value)
      return
    }

    if((!value || value === '') && e.key === '0') e.preventDefault()
  }

  function handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()

    onSubmit(value)
  }

  function handleCancel(e) {
    e.preventDefault()
    e.stopPropagation()

    onCancel()
  }

  return <>
    <div className='form-group'>
      <label>{label}</label>
      <div className='input-group'>
        <div className='input-group-prepend'>
          <div className='input-group-text'>
            <img src={icon} width='30' />
          </div>
        </div>
        <CurrencyInput value={value} onValueChange={handleChange} onKeyDown={handleKeyDown} ref={inputRef} disableGroupSeparators={true} allowDecimals={false} allowNegativeValue={false} />
      </div>
    </div>
    <div className='actions mt-4'>
      <button className='btn btn-success' onClick={handleSubmit}>{text['common']['button']['save']}</button>
      <button className='btn btn-light' onClick={handleCancel}>{text['common']['button']['cancel']}</button>
    </div>
  </>
}

const DisplayValue = ({ value, errorMessage = '' }) => {
  const { text } = useContext(context)

  if(!value) return <span className='form-display-value is-invalid'>{errorMessage}</span>

  return <span className='form-display-value'>({value})</span>
}

const PalletSize = ({ salesUnit, updateSalesUnit }) => {
  const { text, images } = useContext(context)
  const [ modalOpened, setModalOpened ] = useState(false)

  function handleOpen(e) {
    e.preventDefault()
    e.stopPropagation()

    setModalOpened(true)
  }

  function close() {
    setModalOpened(false)
  }

  function update(palletSize) {
    updateSalesUnit({palletSize})
    close()
  }

  return <>
    <input type='hidden' name='offer[pallet_size]' value={salesUnit.palletSize} />
    <button className='link' onClick={handleOpen}><DisplayValue value={salesUnit.palletSize} errorMessage={text['sales_unit']['pallet_size_missing']} /></button>
    <Modal salesUnit={salesUnit} opened={modalOpened} setOpened={setModalOpened} title={text['sales_unit_modal']['pallets']['header']}>
      <SizeForm sizeValue={salesUnit.palletSize} label={text['sales_unit']['pallets_label']} icon={images['icon_pallets']} onSubmit={update} onCancel={close} />
    </Modal>
  </>
}

const ContainerSize = ({ salesUnit, updateSalesUnit }) => {
  const { text, images } = useContext(context)
  const [ modalOpened, setModalOpened ] = useState(false)

  function handleOpen(e) {
    e.preventDefault()
    e.stopPropagation()

    setModalOpened(true)
  }

  function close() {
    setModalOpened(false)
  }

  function update(containerSize) {
    updateSalesUnit({containerSize})
    close()
  }

  return <>
    <input type='hidden' name='offer[container_size]' value={salesUnit.containerSize} />
    <button className='link' onClick={handleOpen}><DisplayValue value={salesUnit.containerSize} errorMessage={text['sales_unit']['container_size_missing']} /></button>
    <Modal salesUnit={salesUnit} opened={modalOpened} setOpened={setModalOpened} title={text['sales_unit_modal']['containers']['header']}>
      <SizeForm sizeValue={salesUnit.containerSize} label={text['sales_unit']['containers_label']} icon={images['icon_container']} onSubmit={update} onCancel={close} />
    </Modal>
  </>
}

const Info = ({ salesUnit }) => {
  const { text } = useContext(context)

  if(salesUnit.volumeType === 'per_pallet' && salesUnit.palletSize > 0) {
    const info = text.sales_unit.pallets_info.replace('%{quantity}', salesUnit.palletSize)
    return <div dangerouslySetInnerHTML={{__html: info}} />
  }

  if(salesUnit.volumeType === 'per_container') {
    const info = text.sales_unit.containers_info.replace('%{quantity}', salesUnit.containerSize)
    return <div dangerouslySetInnerHTML={{__html: info}} />
  }

  return null
}

const SalesUnit = ({ salesUnit, updateSalesUnit }) => {
  const { text, images } = useContext(context)

  function handleVolumeTypeChange(e) {
    updateSalesUnit({volumeType: e.target.value})
  }

  return <>
    <div className='d-flex flex-gap-5'>
      <label className='d-flex align-items-center flex-gap-2 clickable' for='per_piece'>
        <input type='radio' name='offer[volume_type]' id='per_piece' value='per_piece' onChange={handleVolumeTypeChange} className='primary-radio volume-type-radio' data-label={text['common']['units']['per_piece_short']} checked={salesUnit.volumeType === 'per_piece'} />
        <span>{text['form_package_size']['per_piece']}</span>
      </label>
      <label className='d-flex align-items-center flex-gap-2 clickable' for='per_pallet'>
        <input type='radio' name='offer[volume_type]' id='per_piece' value='per_pallet' onChange={handleVolumeTypeChange} className='primary-radio volume-type-radio' data-label={text['common']['units']['per_pallet_short']} checked={salesUnit.volumeType === 'per_pallet'} />
        <img src={images['icon_pallets']} width='30' />
        <PalletSize salesUnit={salesUnit} updateSalesUnit={updateSalesUnit} />
      </label>
      <label className='d-flex align-items-center flex-gap-2 clickable' for='per_container'>
        <input type='radio' name='offer[volume_type]' id='per_piece' value='per_container' onChange={handleVolumeTypeChange} className='primary-radio volume-type-radio' data-label={text['common']['units']['per_container_short']} checked={salesUnit.volumeType === 'per_container'} />
        <img src={images['icon_container']} width='30' />
        <ContainerSize salesUnit={salesUnit} updateSalesUnit={updateSalesUnit} />
      </label>
    </div>
    <Info salesUnit={salesUnit} />
  </>
}

export default SalesUnit
