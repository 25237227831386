import { useEffect } from "react"
import toast from "react-hot-toast"

import I18n from "@/src/i18n.js.erb"
import EventBus from "@/src/event_bus"


// This is a bridge between the event fired by the the turbo response rendered after the Vendor has updated an Offer and the React/Algolia world
const UpdateEventHandler = ({dispatch}) => {
  useEffect(() => {
    EventBus.on("vendor-offer-index:updateOffer", handleOfferUpdate)
    return () => { EventBus.off("vendor-offer-index:updateOffer", handleOfferUpdate) }
  }, [])

  const handleOfferUpdate = (data) => {
    dispatch({type: "UPDATE", id: data.id, data: {min_price: data.min_price, quantity: data.quantity, quantity_in_volume_type: data.quantity * data.volume_type_multiplier, volume_type: data.volume_type, volume_type_multiplier: data.volume_type_multiplier}})

    toast.success(I18n.t("frontend.my_offers_index.messages.success"))
  }

  return null
}

export default UpdateEventHandler
