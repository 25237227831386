import React from 'react'

import I18n from '@/src/i18n.js.erb'

import { inStock } from '../helpers'

const IconInStock = () => <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
<circle cx="7" cy="7.5" r="7" fill="#15D39A"/>
<path d="M3.5 8.1158L6.22222 10.6112L10.8889 4.77783" stroke="white"/>
</svg>

const IconInInflow = () => <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
<circle cx="7" cy="7.5" r="7" fill="#EBB661"/>
<path d="M3.5 8.1158L6.22222 10.6112L10.8889 4.77783" stroke="white"/>
</svg>

const InStock = (offer) => <>
    <div className='d-flex flex-gap-2'>
      <IconInStock />
      <div className='d-flex flex-column'>
        <div className='line-height-100 text-nowrap'>
          <span>{I18n.t('frontend.offers_index.hits.in_stock')}</span>
          <span className='text-small'>, {offer?.offer?.location}</span>
        </div>
        <span className='text-small text-faded fw-300'>ID {offer?.offer?.offer_no?.replace('OFF ', '')}</span>
      </div>
    </div>
  </>

const InInflow = ({ offer }) => <>
    <div className='d-flex flex-gap-2'>
      <IconInInflow />
      <div className='d-flex flex-column'>
        <div className='line-height-100 text-nowrap'>
          <span className='text-small'>{offer.available_starting_at.format('DD.MM.YYYY')}</span>
          <span className='text-small'>, {offer?.location}</span>
        </div>
        <span className='text-small text-faded fw-300'>ID {offer?.offer_no?.replace('OFF ', '')}</span>
      </div>
    </div>
</>

const OfferAvailability = ({ hit, offer }) => {
  const isInStock = inStock(offer)

  return <div>
    <div className='d-flex align-items-center justify-content-end flex-gap-1'>
      {isInStock && <InStock offer={offer} />}
      {!isInStock && <InInflow offer={offer} />}
    </div>
  </div>
}

export default OfferAvailability
