import React from "react"
import I18n from "@/src/i18n.js.erb"

const NoResults = ({colspan}) => {
  return <tr>
    <td colspan={colspan}>
      <div className="no-results-box">
        <div className="ghost-wrapper"><div className="ghost" /></div>
        <h3>{I18n.t("frontend.general.no_results.title")}</h3>
        <p>{I18n.t("frontend.general.no_results.text")}</p>
      </div>
    </td>
  </tr>
}

export default NoResults
