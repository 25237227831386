import React from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom'

import Hits from '../algolia/Hits'

import Hit from './Hit'

const Hitss = ({ items, locale, role, closeInquiry, clearedIssues, isAdmin }) => {
  if (clearedIssues && items.some(item => item.attribute === 'filter' && item.currentRefinement === 'issues')) return null

  return <Hits isAdmin={isAdmin} Hit={Hit} locale={locale} role={role} closeInquiry={closeInquiry} clearedIssues={clearedIssues} />
}

export default connectCurrentRefinements(Hitss)
