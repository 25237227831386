import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"

import I18n from "@/src/i18n.js.erb"

import ActionsDropdownContent from "./ActionsDropdownContent"

const useOutsideClick = (callback) => {
  const ref = useRef()

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) { callback() }
    }

    document.addEventListener("click", handleClick, true)

    return () => { document.removeEventListener("click", handleClick, true) }
  }, [ref])

  return ref
}

const ActionsDropdown = ({ outlined, republish, deactivate, destroy, archive, numberOfSelectedOffers = 0, ...props }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const handleClickOutside = () => { setDropdownOpen(false) }
  const handleClick = () => { setDropdownOpen(true) }
  const ref = useOutsideClick(handleClickOutside)

  const btnClass = classNames(
    "btn",
    "dropdown-toggle",
    "btn-rounded-right",
    { "btn-outline-primary": outlined },
    { "btn-secondary": !outlined }
  )

  return <div className="dropdown d-inline-block">
    <button className={btnClass} ref={ref} onClick={handleClick}>
      {I18n.t("frontend.my_offers_index.actions.button", { number_of_selected_offers: numberOfSelectedOffers })}
    </button>

    <ActionsDropdownContent archive={archive} republish={republish} deactivate={deactivate} destroy={destroy} useExternalDisplayControl={true} isOpen={isDropdownOpen} {...props} />
  </div>
}

export default ActionsDropdown
