import React, { useMemo }           from 'react'
import { InstantSearch, Configure } from 'react-instantsearch-dom'

import { buildProductUrl } from './helpers'
import useAlgoliaClient from './hooks/useAlgoliaClient'

import Autocomplete from './algolia/Autocomplete'

import Hit from './OffersAutocomplete/Hit'

const ATTRIBUTES = [
  'offer_type',
  'slug',
  'product_name',
  'producer_name',
  'producer_logo',
  'product_image',
  'offer.quantity',
  'offer.min_quantity',
  'offer.in_stock',
  'offer.available_starting_at_ts',
  'single_power',
  'cell_type',
  'pnom_dc',
  'capacity',
  'offers',
  'categories'
]

const OffersAutocomplete = ({ appId, apiKey, indexName, defaultHits, placeholder, categorySlugs, groupSeparator = '.', decimalSeparator = ',' }) => {
  const searchClient = useAlgoliaClient(appId, apiKey)

  const onSelect = hit => {
    window.location = buildProductUrl(hit, categorySlugs)
  }

  const selected = false

  const filters = useMemo(() => {
    return 'offer.published_until_ts >= ' + Math.floor(new Date / 1000)
  }, [])

  return <InstantSearch indexName={indexName} searchClient={searchClient}>
    <Configure attributesToRetrieve={ATTRIBUTES} filters={filters} distinct />
    <Autocomplete onSelect={onSelect} selected={selected} placeholder={placeholder} Hit={Hit} defaultHits={defaultHits} groupSeparator={groupSeparator} decimalSeparator={decimalSeparator} />
  </InstantSearch>
}

export default OffersAutocomplete
