import React                from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'

import SearchInput from './SearchInput'

const SearchBox = ({currentRefinement, refine, ...props}) => {
  return <SearchInput refine={refine} currentRefinement={currentRefinement} {...props} />
}

export default connectSearchBox(SearchBox)
