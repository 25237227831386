import React from 'react'
import { connectHits } from 'react-instantsearch-dom'

import I18n from '@/src/i18n.js.erb'

import ActionsDropdown from './ActionsDropdown'
import toast from "react-hot-toast"

const csrfToken = () => document.querySelector("meta[name='csrf-token']").getAttribute('content')

const getSelectedOfferIds = (offers) => {
  const offerIds = []

  Object.keys(offers).forEach(key => {
    if (offers[key].selected) offerIds.push(key)
  })

  return offerIds
}

const republishOptions = (timeframe, offers) => {
  return {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-CSRF-Token': csrfToken()
    },
    body: JSON.stringify({
      republishing: {
        timeframe,
        offer_ids: getSelectedOfferIds(offers)
      }
    })
  }
}

const deactivateOptions = offers => {
  return {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-CSRF-Token': csrfToken()
    },
    body: JSON.stringify({
      deactivation: {
        offer_ids: getSelectedOfferIds(offers)
      }
    })
  }
}

const deleteOptions = offers => {
  return {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-CSRF-Token': csrfToken()
    },
    body: JSON.stringify({
      deletion: {
        offer_ids: getSelectedOfferIds(offers)
      }
    })
  }
}

const archiveOptions = offers => {
  return {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-CSRF-Token': csrfToken()
    },
    body: JSON.stringify({
      archive: {
        offer_ids: getSelectedOfferIds(offers)
      }
    })
  }
}

const Actions = ({ hits, offers, dispatch }) => {
  const selectAll = () => {
    const ids = hits.map(hit => hit.id)

    dispatch({ type: 'SELECT_ALL', ids })
  }

  const reset = () => {
    dispatch({ type: 'RESET_SELECTIONS' })
  }

  const republish = timeframe => e => {
    e.preventDefault()

    fetch('/de/vendor/offer_republishings', republishOptions(timeframe, offers)).then((response) => {
      toast.success(I18n.t("frontend.my_offers_index.messages.success_republish", { count: Object.keys(offers).length, weeks: timeframe }), { duration: 5000 })
    });

    dispatch({ type: 'REPUBLISH_SELECTED', timeframe })
  }

  const deactivate = e => {
    e.preventDefault()

    fetch('/de/vendor/offer_deactivations', deactivateOptions(offers)).then((response) => {
      toast.success(I18n.t("frontend.my_offers_index.messages.success_deactivate", { count: Object.keys(offers).length }), { duration: 5000 })
    });

    dispatch({ type: 'DEACTIVATE_SELECTED' })
  }

  const destroy = e => {
    e.preventDefault()

    fetch('/de/vendor/offer_deletions', deleteOptions(offers))

    dispatch({ type: 'DELETE_SELECTED' })
  }

  const archive = e => {
    e.preventDefault()

    toast(I18n.t("frontend.my_offers_index.messages.archiving_offers"), { duration: 2000, icon: '⌛' })

    fetch('/de/vendor/offer_archives', archiveOptions(offers)).then(response => response.json()).then((response) => {
      if (response.success?.length > 0) {
        const message = response.success?.length === 1 ? I18n.t("frontend.my_offers_index.messages.success_archive") : I18n.t("frontend.my_offers_index.messages.success_archive_plural", { count: response.success?.length })
        toast.success(message, { duration: 5000 })
      }

      if (response.failed?.length > 0) {
        const messageError = response.failed?.length === 1 ? I18n.t("frontend.my_offers_index.messages.error_archive") : I18n.t("frontend.my_offers_index.messages.error_archive_plural", { count: response.failed?.length })
        toast.error(messageError, { duration: 5000 })
      }

      dispatch({ type: 'ARCHIVE_SELECTED', payload: response })
    });
  }

  const numberOfSelectedOffers = Object.values(offers).filter(offer => offer.selected).length
  const offerSelected = numberOfSelectedOffers > 0

  return <>
    {!offerSelected && <button onClick={selectAll} className='btn btn-primary btn-rounded'>{I18n.t('frontend.my_offers_index.select_all')}</button>}
    {offerSelected && <>
      <button onClick={reset} className='btn btn-primary btn-rounded-left'>&times;</button>
      <ActionsDropdown
        archive={archive}
        republish={republish}
        deactivate={deactivate}
        destroy={destroy}
        numberOfSelectedOffers={numberOfSelectedOffers}
        additionalDropdownClassName="dropdown-menu-right"
      />
    </>}
  </>
}

export default connectHits(Actions)
