import React from 'react'

const COLORS = {
  1: 'silversilver',
  2: 'blacksilver',
  3: 'blackblack'
}

const Color = ({hit}) => {
  if(hit.offer_type === 'solar_module' && COLORS[hit.color]) {
    return <div className={'card__color card__color--' + COLORS[hit.color]} />
  }

  return null
}

export default Color
