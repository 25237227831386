import React, { useContext } from 'react'
import qs                    from 'qs'

import I18n from '@/src/i18n.js.erb'

import { searchContext }       from './SearchContextProvider'
import OfferQuantity           from './OfferQuantity'
import OfferRating             from './OfferRating'
import OfferAvailability       from './OfferAvailability'
import OfferCustomerProtection from './OfferCustomerProtection'
import OfferPricing            from './OfferPricing'
import UserCheckoutCTA         from './UserCheckoutCTA'
import UserQuantityCheckoutCTA from './UserQuantityCheckoutCTA'
import UserRegisterCTA         from './UserRegisterCTA'

import { quantityInPieces } from '../helpers'

const openNewInquiry = (offer, filters) => {
  const params = {
    inquiry: {
      offer_id: offer.id
    }
  }

  if(filters.quantity) params.inquiry.quantity = filters.quantity
  if(filters.quantity_unit) params.inquiry.unit = filters.quantity_unit

  const frame = document.querySelector('turbo-frame#modal-frame')
  frame.src = '/' + I18n.locale + '/inquiries/new?' + qs.stringify(params)
}

const openAddToInquiry = (offer, inquiryId, filters) => {
  const params = {
    inquiry: {
      offer_id: offer.id
    }
  }

  if (filters.quantity) {
    params.inquiry.quantity = filters.quantity
  } else if (filters.quantity_kw) {
    params.inquiry.quantity = filters.quantity_kw
    params.inquiry.unit = "kw"
  }

  const frame = document.querySelector('turbo-frame#modal-frame')
  frame.src = '/' + I18n.locale + '/inquiries/' + inquiryId + '/inquiry_offers/new?' + qs.stringify(params)
}

const redirectToLogin = () => {
  Turbo.visit(`/${I18n.locale}/login`)
}

const Offer = ({ hit, offer, productUrl, matchesFilter = true, isLoggedIn = false, navigateToProductPage = null }) => {
  const { inquiryId, filters, emailConfirmed } = useContext(searchContext)

  const quantity = quantityInPieces(hit, offer, filters.quantity, filters.quantity_unit)

  const showCheckoutButtonWithPrice = matchesFilter && quantity > 0
  const showCheckoutButtonWithoutPrice = !showCheckoutButtonWithPrice

  let className = matchesFilter ? 'row product-offers__offer product-offers__offer--filter-match' : 'row product-offers__offer'

  const handleClick = () => {
    if(!isLoggedIn) {
      redirectToLogin()
      return
    }

    if(!emailConfirmed && navigateToProductPage) {
      navigateToProductPage()
      return
    }

    if(inquiryId) {
      openAddToInquiry(offer, inquiryId, filters)
    } else {
      openNewInquiry(offer, filters)
    }
  }

  return <div className={className} onClick={handleClick}>
    <div className='col-6 col-md-2 order-1 order-md-1 d-flex align-items-right'>
      <OfferQuantity hit={hit} offer={offer} />
    </div>
    <div className='col-6 col-md-1 order-3 order-md-2 mt-3 mt-md-0 d-flex align-items-center justify-content-start justify-content-md-center'>
      <OfferCustomerProtection offer={offer} />
    </div>
    <div className='col-6 col-md-2 order-4 order-md-3 mt-3 mt-md-0 d-flex align-items-center justify-content-end justify-content-md-center'>
      <OfferRating offer={offer} />
    </div>
    <div className='col-6 col-md-2 order-2 order-md-4 d-flex align-items-center text-right justify-content-end'>
      <OfferAvailability hit={hit} offer={offer} />
    </div>
    <div className='col-6 col-md-2 order-5 order-md-4 d-flex align-items-center justify-content-center'>
      {isLoggedIn && emailConfirmed && <OfferPricing hit={hit} offer={offer} />}
      {isLoggedIn && !emailConfirmed && <div className='text-faded text-small' dangerouslySetInnerHTML={{__html: I18n.t('frontend.offers_index.hits.email_confirmation_info_html')}} />}
      {!isLoggedIn && <div className='text-faded text-small' dangerouslySetInnerHTML={{__html: I18n.t('frontend.offers_index.hits.register_cta.text_html')}} />}
    </div>
    <div className='col-6 col-md-3 order-5 order-md-4 d-flex align-items-center justify-content-end'>
      { isLoggedIn && showCheckoutButtonWithoutPrice && <UserCheckoutCTA disabled={!emailConfirmed} /> }
      { isLoggedIn && showCheckoutButtonWithPrice && <UserQuantityCheckoutCTA offer={offer} quantity={quantity} hit={hit} price={offer.price_per_quantity} /> }
      { !isLoggedIn && <UserRegisterCTA productUrl={productUrl} />}
    </div>
  </div>
}

export default Offer
