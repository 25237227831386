import React from 'react'

const RadioCircle = ({ current, small = false}) => {
  let className = current ? 'radio-circle radio-circle--current' : 'radio-circle'
  if(small) className += ' radio-circle--small'

  return <div className={className}>
    <div />
  </div>
}

export default RadioCircle
