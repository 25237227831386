import React from 'react'

import I18n from '@/src/i18n.js.erb'

import Availability from './Availability'
import { cloudinaryCloudName } from '../../src/helpers'
import { getMaxQuantityOffer, formatQuantity } from '../helpers'

const InverterHit = ({ hit, onSelect, current, groupSeparator }) => {
  let className = ''
  let logoSrc   = null

  if(current) className = 'current'

  if(hit.producer_logo)
    logoSrc = `https://res.cloudinary.com/${cloudinaryCloudName()}/h_50,w_60,c_fit,e_make_transparent,e_trim/${hit.producer_logo}`
  else
    logoSrc = 'https://res.cloudinary.com/sonix-solar-gmbh/image/upload/q_auto,f_auto,h_120,w_120,c_fit,o_20/solartraders/placeholder_solar_inverter.svg'

  const maxQuantityOffer = getMaxQuantityOffer(hit.offers)
  const quantity = maxQuantityOffer ? formatQuantity(maxQuantityOffer.quantity, groupSeparator) : 0

  return <li className={className} onClick={() => onSelect(hit)}>
    <div className='logo'><img src={logoSrc} /></div>
    <div className='details'>
      <div className='details-row'><strong>{hit.producer_name}</strong> - {hit.product_name}</div>
      <div className='details-row-2'>{hit.pnom_dc} DC</div>
    </div>

    <div className='details-right'>
      <div className='amount'>
        {quantity} {I18n.t('frontend.general.pcs')}
      </div>
      <div className='availability'>
        <Availability hit={hit} />
      </div>
    </div>
  </li>
}

export default InverterHit
