import React from 'react';
import { ArrowRightGray, Cart } from './Icons'


const UserCheckoutCTA = ({ disabled = false }) => {
  const className = disabled ? "d-flex btn btn-secondary btn-rounded px-3 flex-gap-1 marketplace__cta-checkout is--disabled" : "d-flex btn btn-secondary btn-rounded px-3 flex-gap-1 marketplace__cta-checkout"

  return <div className={className}>
    <ArrowRightGray />
    <span>Checkout</span>
    <Cart />
  </div>
}

export default UserCheckoutCTA;
