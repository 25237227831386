import React, { useEffect, useRef }                 from 'react'
import { connectSortBy, connectCurrentRefinements } from 'react-instantsearch-dom'

const filterRelevantItems = items => {
  return items.filter(item => item.attribute !== 'categories.lvl0')
}

const SortOnFilter = ({ refine, filtered, currentRefinement, indexOnFilter, defaultIndex }) => {
  const pristine = useRef(currentRefinement === defaultIndex)

  useEffect(() => {
    if(pristine.current) {
      if(indexOnFilter && filtered) {
        refine(indexOnFilter)
      } else {
        refine(defaultIndex)
      }
    }
  }, [filtered])

  return null
}

const ConnectedSortBy = connectSortBy(SortOnFilter)

const RefinmentsWrapper = ({ defaultIndex, indexOnFilter, query, items }) => {
  const sortItems = [{
    label: defaultIndex,
    value: defaultIndex
  }, {
    label: indexOnFilter,
    value: indexOnFilter
  }]

  return <ConnectedSortBy items={sortItems} defaultRefinement={defaultIndex} indexOnFilter={indexOnFilter} filtered={!!query || filterRelevantItems(items).length !== 0} defaultIndex={defaultIndex} />
}

export default connectCurrentRefinements(RefinmentsWrapper)
