import { baseApi } from "./base/base";

export const offersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getFacets: builder.query({
      query: () => ({ url: '/offers/facets' }),
      providesTags: ['OfferFacets']
    }),
  }),
  overrideExisting: false
})

export const { useGetFacetsQuery } = offersApi;
