import React from 'react'

import SolarModuleHit      from './SolarModuleHit'
import InverterHit         from './InverterHit'
import BatterHit           from './BatteryHit'
import AccessoryHit        from './AccessoryHit'
import EMobilityProductHit from './EMobilityProductHit'

const Hit = (props) => {
  switch(props.hit.type) {
    case 'solar_module': return <SolarModuleHit {...props} />
    case 'inverter':     return <InverterHit {...props} />
    case 'battery':      return <BatterHit {...props} />
    case 'accessory':    return <AccessoryHit {...props} />
    case 'e_mobility':   return <EMobilityProductHit {...props} />
  }
}

export default Hit
