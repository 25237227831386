import React                 from 'react'
import { connectPagination } from 'react-instantsearch-dom'

const PREV  = '<'
const NEXT  = '>'
const FIRST = '<<'
const LAST  = '>>'

const WINDOW_SIZE = 3

const Pagination = ({currentRefinement, nbPages, refine}) => {
  const pages = []
  let previousEllipsisRendered = false
  let nextEllipsisRendered = false

  const click = page => e => {
    e.preventDefault()

    window.scrollTo(0, 0)

    refine(page)
  }

  for(let i = 1; i <= nbPages; i++) {
    const className = i == currentRefinement ? 'page-item active' : 'page-item'
    const shouldRenderPreviousEllpisis = i < (currentRefinement - WINDOW_SIZE)
    const shouldRenderNextEllpisis = i > (currentRefinement + WINDOW_SIZE)

    if (shouldRenderPreviousEllpisis) {
      if (previousEllipsisRendered) { continue }

      previousEllipsisRendered = true
      pages.push(<li className={className}><span className='page-link'>&hellip;</span></li>)
      continue
    }

    if (shouldRenderNextEllpisis) {
      if (nextEllipsisRendered) { continue }

      nextEllipsisRendered = true
      pages.push(<li className={className}><span className='page-link'>&hellip;</span></li>)
      continue
    }

    pages.push(<li key={i} className={className}>
      <a href='#' className='page-link' onClick={click(i)}>{i}</a>
    </li>)
  }

  return <ul className='pagination justify-content-center'>
    {nbPages > 1 && currentRefinement != 1 && <a href='#' className='page-link' onClick={click(1)}>{FIRST}</a>}
    {nbPages > 1 && currentRefinement != 1 && <a href='#' className='page-link' onClick={click(currentRefinement - 1)}>{PREV}</a>}
    {pages}
    {nbPages > 1 && currentRefinement != nbPages && <a href='#' className='page-link' onClick={click(currentRefinement + 1)}>{NEXT}</a>}
    {nbPages > 1 && currentRefinement != nbPages && <a href='#' className='page-link' onClick={click(nbPages)}>{LAST}</a>}
  </ul>
}

export default connectPagination(Pagination)
