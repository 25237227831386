import React, { useState, useContext, useEffect } from 'react'

import I18n from '@/src/i18n.js.erb'

import Offer from './Offer'

import { searchContext } from './SearchContextProvider'

const ChevronDown = () => <svg width="14" height="14" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21 1.49082L12.6002 9.60722C11.8386 10.3431 10.6354 10.3577 9.85612 9.64063L0.999999 1.49082" stroke="white" stroke-width="2" stroke-linecap="round"/>
</svg>

const PriceTrend = ({ value }) => {
  const rounded = Math.round(value)

  if(rounded === 0) return <> &rarr; {rounded}%</>
  if(rounded < 0) return <> &darr; {rounded}%</>

  return <> &uarr; +{rounded}%</>
}

const Offers = ({ hit, matched, other, productUrl, isLoggedIn = false, navigateToProductPage = null }) => {
  const { offersExpanded } = useContext(searchContext)
  const [ expanded, setExpanded ] = useState(false)

  const handleToggle = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    setExpanded(offersExpanded);
  }, [offersExpanded])

  const headerClassName = expanded ? 'card__offers__header card__offers__header--expanded' : 'card__offers__header'

  return <div className='card__offers'>
    <div className={headerClassName} onClick={handleToggle}>
      <div>
        {(hit.pt2 !== undefined || hit.pt2 !== null) && <><PriceTrend value={hit.pt2} /> <span className="text-small">{I18n.t('frontend.offers_index.hits.price_trend', {days: 30})}</span></>}
      </div>
      <div className="d-flex align-items-center flex-gap-3">
        {!expanded && <>
          {matched.length !== 0 && <span dangerouslySetInnerHTML={{__html: I18n.t('frontend.offers_index.select_offer_html', { count: matched.length })}} />}
          {matched.length === 0 && <span dangerouslySetInnerHTML={{__html: I18n.t('frontend.offers_index.show_available_offers_html', { count: matched.length })}} />}
        </>}
        {expanded && <span dangerouslySetInnerHTML={{__html: I18n.t('frontend.offers_index.select_offer_expanded_html')}} />}
        <ChevronDown />
      </div>
    </div>
    {expanded && <div className='product-offers'>
      {matched.map(offer => <Offer key={offer.id} hit={hit} offer={offer} isLoggedIn={isLoggedIn} navigateToProductPage={navigateToProductPage} productUrl={productUrl} />)}
      {other.length > 0 && <>
        {matched.length > 0 && <div className='further-offers-divider'><span dangerouslySetInnerHTML={{__html: I18n.t('frontend.offers_index.further_offers_html')}} /></div>}
        {other.map(offer => <Offer key={offer.id} hit={hit} offer={offer} matchesFilter={false} isLoggedIn={isLoggedIn} navigateToProductPage={navigateToProductPage} productUrl={productUrl} />)}
      </>}
    </div>}
  </div>
}

export default Offers
