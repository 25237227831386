import React from 'react'
import { createConnector } from 'react-instantsearch-dom'

import I18n from '@/src/i18n.js.erb'

import Checkbox from '../common/Checkbox'

import { getDefaultMetadata } from './utils/FilterMethods'

const ATTRIBUTE = 'available_starting_at_ts'
const now = Math.floor(new Date / 1000)
const nowPlusOneWeek = now + 604800;

const connectAvailabilityOffers = createConnector({
  displayName: 'AvailabilityOffers',

  getProvidedProps(props, searchState, searchResults) {
    const currentRefinement = searchState[ATTRIBUTE] || null
    const facets = searchResults && searchResults.results?.disjunctiveFacets?.find(f => f.name === ATTRIBUTE)?.data || [];

    return {
      currentRefinement,
      facets
    }
  },

  refine(props, searchState, nextRefinement) {
    return {
      ...searchState,
      [ATTRIBUTE]: nextRefinement
    }
  },

  getSearchParameters(searchParameters, props, searchState) {
    if (searchState[ATTRIBUTE] === 'arriving_soon') {
      return searchParameters.removeNumericRefinement(ATTRIBUTE)
        .addNumericRefinement(ATTRIBUTE, '<=', nowPlusOneWeek)
        .addNumericRefinement(ATTRIBUTE, '>=', now)
    } else if (searchState[ATTRIBUTE]) {
      return searchParameters.removeNumericRefinement(ATTRIBUTE)
        .addNumericRefinement(ATTRIBUTE, '<=', now)
    } else {
      return searchParameters.removeNumericRefinement(props.attribute)
    }
  },

  cleanUp(props, searchState) {
    const { [ATTRIBUTE]: omit, ...nextSearchState } = searchState

    return nextSearchState
  },

  getMetadata(props, searchState) {
    return getDefaultMetadata(ATTRIBUTE, props, searchState)
  }
})

const AvailabilityFilter = connectAvailabilityOffers(({ currentRefinement, refine, facets }) => {
  const handleFilterToggle = value => e => {
    e.preventDefault()
    e.stopPropagation()

    if (currentRefinement === value) {
      refine(null)
    } else
      refine(value)
  }

  const facetValues = Object.keys(facets)?.map(f => parseInt(f)) || [];
  const inWarehouseCount = Object.entries(facets)
    .filter(([key]) => parseInt(key) < now)
    .reduce((acc, [, value]) => acc + value, 0);

  const arrivingSoonCount = facetValues?.filter(f => f <= nowPlusOneWeek && f >= now)?.length || 0;

  return <>
    <ul class='refinement-list'>
      <li>
        <Checkbox checked={currentRefinement === 'in_warehouse'} onChange={handleFilterToggle('in_warehouse')}>
          <div className='d-flex align-items-center justify-content-between flex-greed'>
            <div className='truncate'>{I18n.t('frontend.my_offers_index.filter.in_warehouse')}</div>
            <div className='refinement-list__count text-small'>{inWarehouseCount}</div>
          </div>
        </Checkbox>
      </li>
      <li>
        <Checkbox checked={currentRefinement === 'arriving_soon'} onChange={handleFilterToggle('arriving_soon')}>
          <div className='d-flex align-items-center justify-content-between flex-greed'>
            <div className='truncate'>{I18n.t('frontend.my_offers_index.filter.arriving_soon')}</div>
            <div className='refinement-list__count text-small'>{arrivingSoonCount}</div>
          </div>
        </Checkbox>
      </li>
    </ul>
  </>
})

export default AvailabilityFilter
