import TotalOffersPerDay from './AdminPriceIndex/TotalOffersPerDay'
import ProductPriceIndex from './AdminPriceIndex/ProductPriceIndex'
import OfferPriceComparison from './AdminPriceIndex/OfferPriceComparison'

const AdminPriceIndex = ({ appId, apiKey, productsIndexName, totalOffers, categorySlugs }) => {
  return <div>
    <h2>Total offers per day</h2>
    <TotalOffersPerDay data={totalOffers} />
    <div style={{width: '702px'}}>
      <h2>Product price index</h2>
      <ProductPriceIndex appId={appId} apiKey={apiKey} indexName={productsIndexName} categorySlugs={categorySlugs} />
    </div>
    <h2>Offer price comparison (work in progress)</h2>
    <OfferPriceComparison />
  </div>
}

export default AdminPriceIndex
