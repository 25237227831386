import React from 'react';

const FormattedPriceOptions = ({ price, currency = 'EUR', precision = 2, per = '', smallEuroSign = false }) => {
  const formatter = new Intl.NumberFormat('de-DE', { minimumFractionDigits: precision })

  return <>
    <span className='fw-500'>{formatter.format(price)}</span>
    {smallEuroSign ? <small> €</small> : ' €'}
    {per}
  </>;
}

export default FormattedPriceOptions;
