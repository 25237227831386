import React                       from 'react'
import { connectToggleRefinement } from 'react-instantsearch-dom'

import Toggle from '../common/Toggle'

const ToggleRefinement = ({ currentRefinement, refine, value, label, Icon }) => {
  const handleToggle = () => {
    if(currentRefinement)
      refine(null)
    else
      refine(value)
  }

  return <div className='toggle-refinement'>
    {Icon && <Icon />}
    <label onClick={handleToggle}>{label}</label>
    <Toggle checked={currentRefinement} onChange={handleToggle} />
  </div>
}

export default connectToggleRefinement(ToggleRefinement)
