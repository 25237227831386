import React from 'react';
import I18n from '../../src/i18n.js.erb'
import { ArrowRightGray } from './Icons'

const UserRegisterCTA = ({ productUrl }) => {
  return <a href={productUrl} class="btn btn-secondary btn-rounded d-inline-flex marketplace__cta-checkout align-items-center flex-gap-1 p-2 px-3" rel="nofollow">
    <ArrowRightGray />
    <span className='text-nowrap'>{I18n.t('frontend.offers_index.hits.login_cta.text')}</span>
  </a>
}

export default UserRegisterCTA;
