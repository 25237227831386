import React, { useEffect } from 'react';
import IconChronic from './icons/chronic.svg';
import IconCalendarMonth from './icons/calendar_month.svg';
import IconFlag from './icons/flag.svg';
import IconPersonRaisedHand from './icons/person_raised_hand.svg';
import IconSnooze from './icons/snooze.svg';
import IconMagnifyFullscreen from './icons/magnify_fullscreen.svg';
import IconAlarmAdd from './icons/alarm_add.svg';
import AssigneesFilter from './Sidebar/AssigneesFilter'
import StatusFilter from './Sidebar/StatusFilter';
import I18n from '@/src/i18n.js.erb'

const menuItems = [
  { title: I18n.t('frontend.tickets.dashboard.filters.today'), badge: 1, icon: IconChronic, filter: 'today' },
  { title: I18n.t('frontend.tickets.dashboard.filters.this_week'), badge: 4, icon: IconCalendarMonth, filter: 'this_week' },
  { title: I18n.t('frontend.tickets.dashboard.filters.priority'), badge: 5, icon: IconFlag, filter: 'priority' },
  { title: I18n.t('frontend.tickets.dashboard.filters.support'), badge: 4, icon: IconPersonRaisedHand, filter: 'support' },
  { title: I18n.t('frontend.tickets.dashboard.filters.overdue'), badge: 17, icon: IconSnooze, filter: 'overdue' },
  { title: I18n.t('frontend.tickets.dashboard.filters.new'), badge: 1, icon: IconAlarmAdd, filter: 'new' },
  { title: I18n.t('frontend.tickets.dashboard.filters.all'), badge: 1, icon: IconMagnifyFullscreen, filter: 'all' },

]

const Sidebar = ({ meta, users, isAdmin, filters = {}, setFilters = () => { } }) => {

  useEffect(() => {
    localStorage.setItem('timeframe', filters?.timeframe);
  }, [filters])
  return (
    <div style={{ backgroundColor: '#FCFCFC' }} className="col-md-3 sidebar mb-3">
      <button className="navbar-toggler d-md-none" type="button" data-toggle="collapse" data-target="#sidebarCollapse">
        <span className="navbar-toggler-icon">
          {I18n.t('frontend.tickets.dashboard.filters.filters')}
        </span>
      </button>

      <div className="collapse navbar-collapse show" id="sidebarCollapse">
        <ul className="list-unstyled">
          {menuItems.map((item) => (
            <MenuItem onClick={() => setFilters({ ...filters, timeframe: item.filter })} active={filters.timeframe == item.filter} icon={item.icon} text={item.title} badge={meta == undefined ? 0 : meta[item.filter]} />
          ))}
        </ul>

        {isAdmin && <AssigneesFilter users={users} filters={filters} setFilters={setFilters} />}
        <StatusFilter filters={filters} setFilters={setFilters} />

        <button onClick={() => setFilters({ ...filters, user_ids: [], status: 'open' })} className="btn btn-light mt-5 shadow-none text-primary border border-dark">
          {I18n.t('frontend.tickets.dashboard.filters.clear_filters')}
        </button>

        <div style={{ height: '25px' }}></div>
      </div>
    </div>
  );
};

const MenuItem = ({ icon, text, badge, active = false, onClick = () => { } }) => {
  return (
    <li onClick={onClick} className={`menu-item menu-item-sidebar clickable cursor-pointer p-2 ${active ? 'bg-light-blue' : ''}`}>
      <span className="menu-link text-dark">
        <img className="mr-3" src={icon} />
        {text}
        <span className="badge rounded-circle badge-primary badge-pill badge-right float-right pt-1" style={{ fontWeight: '700', color: '#717171', backgroundColor: '#ECEBEB' }}>{badge}</span>
      </span>
    </li >
  );
};


export default Sidebar;
