import React, { useContext } from 'react'
import { connectHierarchicalMenu } from 'react-instantsearch-dom'

import I18n from '@/src/i18n.js.erb'

import { searchContext } from './SearchContextProvider'
import RadioCircle from '../common/RadioCircle'

const ORDER = {
  'solar_modules': 1,
  'inverters': 2,
  'batteries': 3,
  'accessories': 4
}

const ORDER_SUBCATEGORIES = {
  'batteries': {
    'battery_bms_modules': 1,
    'battery_modules': 2,
    'battery_systems': 3,
    'battery_bundles': 4
  }
}

const urlForItem = (item, categorySlugs) => {
  return '/' + I18n.locale + '/products/' + categorySlugs[item.label]
}

const ListItems = ({ items, currentRefinement, sub = false, refine }) => {
  const context = useContext(searchContext)
  const current = currentRefinement ? currentRefinement.split(' > ')[0] : currentRefinement

  if (ORDER_SUBCATEGORIES[current]) {
    items.sort((a, b) => ORDER_SUBCATEGORIES[current][a.label] - ORDER_SUBCATEGORIES[current][b.label])
  }

  const bindClick = value => e => {
    e.preventDefault()

    const v = currentRefinement !== current && items.items ? current : value

    refine(v)
  }

  return items.map(item => {
    return <li key={item.value}>
      <a href={urlForItem(item, context.categorySlugs)} style={{ fontWeight: item.isRefined ? 'bold' : '' }} onClick={bindClick(item.value)}>
        <div className='d-flex align-items-center justify-content-between flex-greed'>
          <div>
            {!sub && <RadioCircle small current={item.label === current} />}
            {I18n.t('frontend.general.categories.' + item.label)}
          </div>
          <div className='refinement-list__count text-small'>{item.count}</div>
        </div>
      </a>


      {item.items && <ul className='algolia-menu algolia-menu--sub'><ListItems currentRefinement={currentRefinement} items={item.items} refine={refine} sub={true} /></ul>}
    </li>
  })
}

const CategoryFilter = ({ items, refine, currentRefinement, rootCategories, fixedCategory = null }) => {
  let rootItems = rootCategories.map(rootCategory => {
    const item = items.find(i => i.label === rootCategory)

    return item || { label: rootCategory, value: rootCategory, count: items.filter(i => i.label === rootCategory).length }
  })

  if (fixedCategory) {
    rootItems = rootItems.filter(item => item.label === fixedCategory)
  }

  rootItems.sort((a, b) => ORDER[a.label] - ORDER[b.label])

  const reset = e => {
    e.preventDefault()

    refine(undefined)
  }

  return <>
    <ul className='algolia-menu'>
      <ListItems items={rootItems} refine={refine} currentRefinement={currentRefinement} />
      {!fixedCategory && <li className={currentRefinement ? null : ''}>
        <a href={'/' + I18n.locale + '/products'} style={{ fontWeight: currentRefinement ? '' : 'bold' }} onClick={reset}>
          <RadioCircle small current={!currentRefinement} />
          {I18n.t('frontend.general.all')}
        </a>
      </li>}
    </ul>
    {!!fixedCategory && <div className='mt-3'>
      <a href={`/${I18n.locale}/products`} className='btn btn-sm btn-outline-primary'>{I18n.t("frontend.general.all_categories")}</a>
    </div>}
  </>
}

export default connectHierarchicalMenu(CategoryFilter)
