import React from 'react'
import { connectHits } from 'react-instantsearch-dom'

import useHitsScrollPos from '@/components/hooks/useHitsScrollPos'
import NoResults from '@/components/algolia/NoResults'

import SortHeader from "./SortHeader"

import I18n from "@/src/i18n.js.erb"

const Hits = ({ hits, Hit, defaultIndex, indexName, changeSortOrder, colspan = null, ...props }) => {
  useHitsScrollPos(hits)

  if (!hits.length) { return <NoResults clearsQuery colspan={colspan} /> }

  return <>
    <div class="product-offers-table-wrapper table-responsive" style={{ overflowX: 'initial' }}>
      <table class="table product-offers-table mb-0">
        <thead>
          <tr>
            <th className="is--small">{I18n.t("frontend.my_offers_index.hits.product")}</th>
            <th>
              <div className="sales-details-header">
                <SortHeader defaultIndex={defaultIndex} currentIndex={indexName} attribute="view_count" changeSortOrder={changeSortOrder} title={I18n.t("frontend.my_offers_index.hits.views")} />

                <SortHeader defaultIndex={defaultIndex} currentIndex={indexName} attribute="inquiries_count" changeSortOrder={changeSortOrder} title={I18n.t("frontend.my_offers_index.hits.requests")} />

                <SortHeader defaultIndex={defaultIndex} currentIndex={indexName} attribute="sold_inquiries_count" changeSortOrder={changeSortOrder} title={I18n.t("frontend.my_offers_index.hits.sales")} />

                <SortHeader defaultIndex={defaultIndex} currentIndex={indexName} attribute="turnover_volume" changeSortOrder={changeSortOrder} title={I18n.t("frontend.my_offers_index.hits.turnover")} />
              </div>
            </th>

            <th className="text-right">
              <SortHeader defaultIndex={defaultIndex} currentIndex={indexName} attribute="quantity" changeSortOrder={changeSortOrder} title={I18n.t("frontend.my_offers_index.hits.stock")} />
            </th>

            <th className="is--small text-right">
              <SortHeader defaultIndex={defaultIndex} currentIndex={indexName} attribute="min_price" changeSortOrder={changeSortOrder} title={I18n.t("frontend.my_offers_index.hits.price")} />
            </th>
          </tr>
        </thead>
        <tbody>
          {hits.map(hit => <Hit key={`${hit.offer_type}/${hit.id}`} hit={hit} changeSortOrder={changeSortOrder} {...props} />)}
        </tbody>
      </table>
    </div>
  </>
}

export default connectHits(Hits)
