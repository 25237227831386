import React from "react"

const VARIANT_STANDALONE = "standalone"

const CheckboxSquare = ({checked, toggle, variant = "inline"}) => {
  let className = checked ? "checkbox-square checkbox-square--checked" : "checkbox-square"

  switch (variant) {
    case VARIANT_STANDALONE:
      className = `${className} checkbox-square--standalone`
      break;
  }

  return <div className={className} onClick={toggle}>
    <div />
  </div>
}

export default CheckboxSquare
