const getIndexId = ({ ais, multiIndexContext }) => multiIndexContext ? multiIndexContext.targetedIndex : ais.mainTargetedIndex

export const getDefaultMetadata = (attribute, props, searchState) => {
  return {
    id: attribute,
    index: getIndexId({
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    }),
    items: searchState[attribute]
      ? [
        {
          label: `${searchState[attribute]}`,
          value: (nextState) => ({
            ...nextState,
            [attribute]: null
          })
        }
      ]
      : []
  }
}