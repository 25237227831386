import { useState } from 'react'
import { InstantSearch } from 'react-instantsearch-dom'
import { ComposedChart, AreaChart, Area, LineChart, CartesianGrid, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts'

const Offer = ({ data, handleCancel }) => {
  return <div>
    [graph]
    <button onClick={handleCancel}>cancel</button>
  </div>

  return <div>
    {data && <ComposedChart width={300} height={200} data={data}>
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#76C0FD" stopOpacity={0.8}/>
          <stop offset="95%" stopColor="#76C0FD" stopOpacity={0}/>
        </linearGradient>
      </defs>

      <Tooltip />
      <Area name='Price' yAxisId='price' type='monotone' dataKey='p' stroke='#3386C3' fillOpacity={1} fill="url(#colorUv)" strokeWidth={2} />
      <Line name='Quantity' yAxisId='quantity' type='monotone' dataKey='q' stroke='orange' strokeWidth={2} dot={false} />
      <XAxis hide dataKey="date" />
      <YAxis hide yAxisId='price' />
      <YAxis hide yAxisId='quantity' />
    </ComposedChart>}
    <button onClick={handleCancel}>cancel</button>
  </div>
}

const OfferPriceComparison = () => {
  const [ offerId, setOfferId ] = useState('')
  const [ offer, setOffer ] = useState(null)

  const handleChange = e => {
    setOfferId(e.target.value)
  }

  const handleSelect = () => {
    fetch(`/api/v1/admin/offers/${offerId}/price_comparison`).then(response => response.json()).then(data => {
      if(data) setOffer(data)
    })
  }

  const handleCancel = () => {
    setOffer(null)
    setOfferId('')
  }

  return <>
    {!offer && <div>
      <input type='text' value={offerId} onChange={handleChange} />
      <button onClick={handleSelect}>select</button>
    </div>}
    {offer && <Offer data={offer} handleCancel={handleCancel} />}
  </>
}

export default OfferPriceComparison
