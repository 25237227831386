import React, { useState } from "react"
import dayjs from "dayjs/esm/index.js"
import classNames from "classnames"

import I18n from "@/src/i18n.js.erb"

import { CaretCircleDownIcon } from "../OffersIndex/Icons"

import AvailableAsOf from "../common/AvailableAsOf"
import CheckboxSquare from "../common/CheckboxSquare"

import Stock from "./Stock"
import SingleActions from "./SingleActions"
import OfferUpdated from "./OfferUpdated"
import OfferStatus from "./OfferStatus"

const OFFER_TYPE_SOLAR_MODULE = "solar_module"
const WILL_EXPIRE_THRESHOLD_IN_DAYS = 7

const CSS_CLASSES = {
  expiry: {
    notExpired: "indicator--green",
    willExpireSoon: "indicator--orange",
    hasExpired: "indicator--red",
    archived: "indicator--grey"
  }
}

const expiryCssClass = (hit) => {
  const now = dayjs()
  const expiresAt = dayjs.unix(hit.published_until_ts)
  const willExpireSoon = now.add(WILL_EXPIRE_THRESHOLD_IN_DAYS, "days")
  if (hit.archived) {
    return CSS_CLASSES.expiry.archived
  }

  if (expiresAt > willExpireSoon) {
    return CSS_CLASSES.expiry.notExpired
  } else if (expiresAt < now) {
    return CSS_CLASSES.expiry.hasExpired
  } else {
    return CSS_CLASSES.expiry.willExpireSoon
  }
}

const formattedMinPrice = (hit) => {
  const precision = hit.offer_type === OFFER_TYPE_SOLAR_MODULE ? 3 : 2
  const formatter = new Intl.NumberFormat("de-DE", { minimumFractionDigits: precision })
  const actualPrice = hit.updates?.min_price ? hit.updates?.min_price : hit.min_price

  return formatter.format(actualPrice)
}

const formattedTurnoverVolume = (hit) => {
  if (!hit.turnover_volume) { return "0" }

  return new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 }).format(hit.turnover_volume)
}

const Hit = ({ hit, offers, dispatch, sendImageSrc, xImageSrc }) => {
  const [isExpanded, setExpanded] = useState(false)

  const toggleRowExpansion = () => { setExpanded(!isExpanded) }
  const toggleSelection = e => {
    e.stopPropagation()

    dispatch({ type: "TOGGLE_SELECTION", id: hit.id })
  }

  const selected = !!offers[hit.id] && offers[hit.id].selected
  const isSolarModule = hit.offer_type === OFFER_TYPE_SOLAR_MODULE
  const editUrl = `/${I18n.locale}/vendor/offers/${hit.id}/edit`
  const translatedBlacklistRegions = hit.region_blacklist?.map(r => I18n.t(`frontend.countries.${r}`))?.sort()?.join(", ")
  const strippedOfferNumber = hit.offer_number.replace("OFF ", "")

  hit = offers[hit.id] ? { ...hit, ...offers[hit.id].updates } : hit

  return <>
    <tr className={hit.archived ? 'bg-archived' : ''}>
      <td className={`td--centered is--small has--indicator px-4 ${expiryCssClass(hit)}`}>
        <div className="d-flex align-items-center justify-content-center flex-gap-2">
          <div className="card__check d-block">
            <CheckboxSquare checked={selected} toggle={toggleSelection} variant="standalone" />
          </div>

          <div className="card__check d-block text-muted clickable pl-1" onClick={toggleRowExpansion}>
            {CaretCircleDownIcon({ className: classNames("card__expand-icon", "fill-current", { "is--expanded": isExpanded }) })}
          </div>

          <div className="card__check d-block text-muted clickable">
            <SingleActions hit={hit} dispatch={dispatch} />
          </div>
        </div>
      </td>

      <td colSpan="4" className="pb-1">
        <div className="card__product-name truncate">
          <a href={editUrl} data-turbo-frame="vendor-offer-edit-overlay" className="default-text-hover-underline">{hit.product_name}</a>
        </div>
      </td>
    </tr>

    <tr className={`tr--no-border ${hit.archived ? 'bg-archived' : ''}`}>
      <td className={`td--centered pt-0 has--indicator ${expiryCssClass(hit)} text-center`}>
        <small className="card__price">ID: {strippedOfferNumber}</small>
      </td>

      <td className="td--centered pt-0">
        <div className="card__sales-details sales-details">
          <div className="sales-details__item text-nowrap">{hit.view_count}</div>
          <div className="sales-details__item text-nowrap">{hit.inquiries_count}</div>
          <div className="sales-details__item text-nowrap">{hit.sold_inquiries_count}</div>
          <div className="sales-details__item text-nowrap">
            {formattedTurnoverVolume(hit)}
            <small className="align-self-end ml-1">€</small>
          </div>
        </div>
      </td>

      <td className="td--bottom pt-0 text-right">
        <Stock hit={hit} dispatch={dispatch} offers={offers} sendImageSrc={sendImageSrc} xImageSrc={xImageSrc} />
      </td>

      <td className="td--bottom pt-0 is--small text-right">
        <div className="card__price text-nowrap align-items-end">
          {hit.min_price !== hit.max_price && <small className="mr-1">{I18n.t("frontend.general.price_from")}</small>}
          {formattedMinPrice(hit)}
          <small className="ml-1">
            €
            {isSolarModule && <span>/Wp</span>}
          </small>
        </div>
      </td>
    </tr>

    {isExpanded && <>
      <tr className="fs-0_9 my-offers-table-sub-row">
        <td className={`td--centered has--indicator has--muted-indicator ${expiryCssClass(hit)}`}></td>
        <td colspan="4">
          <div className="d-flex align-items-start justify-content-between">
            <div>
              <strong className="d-block">{I18n.t("frontend.my_offers_index.hit.warehouse")}</strong>
              {hit.warehouse_name} / <AvailableAsOf hit={hit} />
            </div>

            <div className="text-center">{hit.updated_at_ts && <OfferUpdated hit={hit} />}</div>

            <div className="text-right">
              <strong className="d-block text-right">{I18n.t("frontend.my_offers_index.hit.status.title")}</strong>
              <OfferStatus hit={hit} />
            </div>
          </div>

          {hit.producer_name && <>
            <strong className="d-block pt-2">{I18n.t("frontend.my_offers_index.hit.producer")}</strong>
            <div>{hit.producer_name}</div>
          </>}

          {hit.personal_note && <>
            <strong className="d-block pt-2">{I18n.t("frontend.my_offers_index.hit.personal_note")}</strong>
            <div>{hit.personal_note}</div>
          </>}

          {hit.region_blacklist && <>
            <strong className="d-block pt-2">{I18n.t("frontend.my_offers_index.hit.black_list")}</strong>
            {translatedBlacklistRegions}
          </>}
        </td>
      </tr>
    </>}
  </>
}

export default Hit
