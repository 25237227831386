import React from 'react'

const InverterHit = ({hit, onSelect, current}) => {
  let className = ''
  if(current) className = 'current'

  return <li className={className} onClick={() => onSelect(hit)}>
    <div className='name'><strong>{hit.producer_name}</strong> - {hit.name}</div>
    <div className='detail-s'>{hit.pnom_dc} DC</div>
    <div className='detail-w'>{hit.no_of_mpp} MPP Tracker</div>
    <div className='detail-s'>{hit.no_of_dc} Strings</div>
  </li>
}

export default InverterHit
