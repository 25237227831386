import React, { useContext, useState }from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom'

import I18n from '@/src/i18n.js.erb'
import isbot from 'isbot'

import Checkbox from '../common/Checkbox'

import ToggleRefinementCheckbox from '../algolia/ToggleRefinementCheckbox'
import RefinementList           from '../algolia/RefinementList'
import RangeInput               from '../algolia/RangeInput'
import MaxInput                 from '../algolia/MaxInput'
import NumericMin               from '../algolia/NumericMin'
import NumericMax               from '../algolia/NumericMax'
import MenuSelect               from '../algolia/MenuSelect'
import IsRefined                from '../algolia/IsRefined'
import ActiveFilters            from '../algolia/ActiveFilters'
import ResetFilters             from '../algolia/ResetFilters'
import ToggleRefinement         from '../algolia/ToggleRefinement'

import FilterOption       from './FilterOption'
import CategoryFilter     from './CategoryFilter'
import ToggleInStock      from './ToggleInStock'
import FiltersRegisterCTA from './FiltersRegisterCTA'
import MinRatingInput     from './MinRatingInput'

import { searchContext } from './SearchContextProvider'

const CATEGORY_ATTRIBUTES = ['categories.lvl0', 'categories.lvl1', 'categories.lvl2']

const getCurrentCategory = refinements => {
  let categoryRefinement

  if(categoryRefinement = refinements.find(refinement => refinement.attribute === 'categories.lvl0')) {
    const path = categoryRefinement.currentRefinement.split(' > ')

    if(path[0] === 'accessories' && path[path.length - 1] === 'e_mobility') return 'e_mobility'

    return path[0]
  }

  return null
}

const sortNumerically = (a, b) => {
  if(parseInt(a.value) < parseInt(b.value)) return -1
  if(parseInt(a.value) > parseInt(b.value)) return 1

  return 0
}

const sortByLabel = (a, b) => {
  if(a.label < b.label) return -1
  if(a.label > b.label) return 1

  return 0
}

const sortByCount = (a, b) => {
  if(a.isRefined && !b.isRefined) return -1
  if(!a.isRefined && b.isRefined) return 1

  return b.count - a.count
}

const sortCellTypes = (a, b) => {
  if(a.label === 'other') return 1

  if(a.label < b.label) return -1
  if(a.label > b.label) return 1

  return 0
}

const MODULE_TYPES_ORDER = ['all_black', 'black_frame', 'glass_glass', 'glass_sheet', 'top_efficiency', '54_cell', '60_cell', '72_cell']
const sortByModuleType = (a, b) => MODULE_TYPES_ORDER.indexOf(a.label) - MODULE_TYPES_ORDER.indexOf(b.label)

const INVERTER_TYPES_ORDER = ['single_phase', 'three_phase', 'hybrid_inverter', 'micro_inverter', 'gt_50kw', 'gt_100kw']
const sortByInverterType = (a, b) => INVERTER_TYPES_ORDER.indexOf(a.label) - INVERTER_TYPES_ORDER.indexOf(b.label)

const CloseIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>

const FiltersIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256" class="fill-current"><path d="M64,105V40a8,8,0,0,0-16,0v65a32,32,0,0,0,0,62v49a8,8,0,0,0,16,0V167a32,32,0,0,0,0-62Zm-8,47a16,16,0,1,1,16-16A16,16,0,0,1,56,152Zm80-95V40a8,8,0,0,0-16,0V57a32,32,0,0,0,0,62v97a8,8,0,0,0,16,0V119a32,32,0,0,0,0-62Zm-8,47a16,16,0,1,1,16-16A16,16,0,0,1,128,104Zm104,64a32.06,32.06,0,0,0-24-31V40a8,8,0,0,0-16,0v97a32,32,0,0,0,0,62v17a8,8,0,0,0,16,0V199A32.06,32.06,0,0,0,232,168Zm-32,16a16,16,0,1,1,16-16A16,16,0,0,1,200,184Z"></path></svg>

const TrustServiceIcon = () => <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 9.42896V4.44558C1 4.34871 1.06906 4.26539 1.16345 4.24357C1.5995 4.14278 2.79385 3.8096 3.33333 3.08611C3.83358 2.41524 3.95846 1.57656 3.98963 1.19966C3.99873 1.08958 4.08954 1 4.2 1H13.4667C13.5771 1 13.6679 1.08958 13.677 1.19966C13.7082 1.57656 13.8331 2.41524 14.3333 3.08611C14.8822 3.82222 16.335 4.15428 16.8311 4.24863C16.9285 4.26714 17 4.35151 17 4.45058V9.38183C17 9.55273 16.9571 9.71947 16.8702 9.86663C16.4416 10.5924 15.0037 12.9173 13.3333 14.4107C11.7521 15.8244 9.96256 17.2381 9.28244 17.7681C9.11334 17.8999 8.8817 17.9074 8.70523 17.7856C7.9574 17.2698 5.93231 15.8403 4.33333 14.4107C2.6378 12.8948 1.4214 10.5222 1.09035 9.83493C1.02926 9.70813 1 9.56971 1 9.42896Z" stroke="#003560" stroke-width="0.5"/>
  <path d="M2.95473 9.40443L2.94557 5.73191C2.94532 5.63503 3.01432 5.55152 3.10843 5.52861C3.46553 5.44165 4.31515 5.18698 4.70604 4.66022C5.06274 4.17955 5.16488 3.58573 5.19413 3.28214C5.20472 3.1722 5.29541 3.0827 5.40587 3.08248L12.3103 3.0687C12.4208 3.06848 12.5119 3.15762 12.5231 3.26751C12.5538 3.57099 12.6589 4.16439 13.018 4.64364C13.4198 5.17987 14.4575 5.43023 14.8663 5.51036C14.9635 5.52942 15.0355 5.61371 15.0357 5.71278L15.0448 9.33318C15.0452 9.50408 15.0022 9.6718 14.915 9.81876C14.557 10.4225 13.5035 12.107 12.2837 13.2024C11.1417 14.228 9.85529 15.2538 9.29819 15.6913C9.12962 15.8237 8.89719 15.8316 8.7207 15.7098C8.10515 15.285 6.64568 14.2508 5.48304 13.216C4.23477 12.1049 3.32712 10.3814 3.04648 9.80954C2.98446 9.68316 2.95509 9.54518 2.95473 9.40443Z" fill="#D9D9D9" stroke="#003560" stroke-width="0.5"/>
  <path d="M6 8.15243L8.33333 10.2385L12.3333 6.66235" stroke="#003560" stroke-width="0.5" stroke-linecap="round"/>
</svg>

const Filters = ({ items, fixedCategory, producer, rootCategories }) => {
  const context = useContext(searchContext)
  const [isExpanded, setExpanded] = useState(false);

  // const currentCategory = category || getCurrentCategory(items)
  const currentCategory = getCurrentCategory(items)
  const botCssClass = isbot(navigator.userAgent) ? "d-none" : ""

  const toggleFilters = () => { setExpanded(!isExpanded) }

  return <>
    {!isExpanded && <>
      <div className="mobile-filters-toggle d-flex d-md-none flex-direction-row align-items-center flex-gap-3" onClick={toggleFilters}>
        <div className="flex-greed">
          <FiltersIcon />
          {I18n.t('frontend.offers_index.filters.mobile.show')}
        </div>
        <div className="flex-grow">
          <ResetFilters />
        </div>
      </div>
    </>}

    <div className={`filters ${isExpanded ? "is--expanded" : ""} ${botCssClass}`}>
      <div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <h3>{I18n.t('frontend.offers_index.filters.title')}</h3>
          <div className="d-flex d-md-none" onclick={toggleFilters}><CloseIcon /></div>
        </div>
        <ActiveFilters />
      </div>

      <hr />

      <FilterOption>
        <CategoryFilter attributes={CATEGORY_ATTRIBUTES} rootCategories={rootCategories} fixedCategory={fixedCategory} />
      </FilterOption>

      <hr />

      <div className='d-flex flex-column flex-gap-1'>
        <ToggleInStock />
        <ToggleRefinement attribute='offer.support_trust_service' label={I18n.t('frontend.general.trust_service')} value={true} Icon={TrustServiceIcon} />
      </div>

      <hr />

      {producer && <FilterOption label={I18n.t('frontend.offers_index.filters.producer.title')}>
        <div className='mb-3'>
          <Checkbox disabled checked={true} name={producer}>
            <div className='d-flex align-items-center justify-content-between flex-greed'>
              <div className='truncate'>{producer}</div>
            </div>
          </Checkbox>
        </div>
        <a href={`/${I18n.locale}/products`} className='btn btn-sm btn-outline-primary'>{I18n.t("frontend.general.all_producers")}</a>
      </FilterOption>}

      {!producer && <FilterOption label={I18n.t('frontend.offers_index.filters.producer.title')} collapsible>
        <RefinementList searchable attribute='producer_name' sort={sortByCount} limit={100} maxItems={8} />
      </FilterOption>}

      {context.userStatus && <>
        <hr />
        <FilterOption label={I18n.t('frontend.offers_index.filters.price.title')} collapsible>
          <MaxInput attribute='offer.price_ranges.price_per_quantity' unit={currentCategory === 'solar_modules' ? '€/Wp' : '€'} className='w-50' />
        </FilterOption>
        <hr />
      </>}

      {!context.userStatus && <FiltersRegisterCTA />}

      {currentCategory === 'solar_modules' && <>
        <FilterOption label={I18n.t('frontend.offers_index.filters.module_types.title')} collapsible>
          <RefinementList attribute='module_types' locales={I18n.t('frontend.offers_index.filters.module_types.values')} sort={sortByModuleType} operator='and' />
        </FilterOption>

        <hr />

        <FilterOption label={I18n.t('frontend.offers_index.filters.cell_type.title')} collapsible>
          <RefinementList attribute='cell_type_name' locales={{other: I18n.t('frontend.offers_index.filters.cell_type.other')}} sort={sortCellTypes} />
        </FilterOption>

        <hr />

        <FilterOption label={I18n.t('frontend.offers_index.filters.power.title')} collapsible>
          <RangeInput attribute='single_power' unit='Wp' />
        </FilterOption>

        <hr />

        <FilterOption label={I18n.t('frontend.offers_index.filters.measurements.title_min')} collapsible collapsed>
          <div className='form-group d-flex flex-row flex-gap-2'>
            <NumericMin attribute='length' placeholder={I18n.t('frontend.offers_index.filters.measurements.length')} unit='mm' />
            <NumericMin attribute='width' placeholder={I18n.t('frontend.offers_index.filters.measurements.width')} unit='mm' />
            <NumericMin attribute='thickness' placeholder={I18n.t('frontend.offers_index.filters.measurements.height')} unit='mm' />
          </div>
        </FilterOption>

        <FilterOption label={I18n.t('frontend.offers_index.filters.measurements.title_max')} collapsible collapsed>
          <div className='form-group d-flex flex-row flex-gap-2'>
            <NumericMax attribute='length' placeholder={I18n.t('frontend.offers_index.filters.measurements.length')} unit='mm' />
            <NumericMax attribute='width' placeholder={I18n.t('frontend.offers_index.filters.measurements.width')} unit='mm' />
            <NumericMax attribute='thickness' placeholder={I18n.t('frontend.offers_index.filters.measurements.height')} unit='mm' />
          </div>
        </FilterOption>

        <hr />
      </>}

      {currentCategory === 'inverters' && <>
        <FilterOption label={I18n.t('frontend.offers_index.filters.inverter_types.title')} collapsible>
          <RefinementList attribute='inverter_types' locales={I18n.t('frontend.offers_index.filters.inverter_types.values')} sort={sortByInverterType} operator='and' />
        </FilterOption>

        <hr />

        <FilterOption label={I18n.t('frontend.offers_index.filters.pnom_dc.title')}>
          <RangeInput attribute='pnom_dc' unit='kW' />
        </FilterOption>

        <hr />

        <FilterOption label={I18n.t('frontend.offers_index.filters.pnom_ac.title')}>
          <RangeInput attribute='pnom_ac' unit='kW' />
        </FilterOption>

        <hr />
      </>}

      {currentCategory === 'batteries' && <>
        <FilterOption label={I18n.t('frontend.offers_index.filters.battery_type.title')} collapsible>
          <RefinementList attribute='battery_type' sort={sortByLabel} locales={I18n.t('frontend.offers_index.hits.battery_types')} />
        </FilterOption>

        <hr />

        <FilterOption label={I18n.t('frontend.offers_index.filters.capacity.title')} collapsible>
          <RangeInput attribute='capacity' unit='kWh' />
        </FilterOption>

        <hr />

        <FilterOption label={I18n.t('frontend.offers_index.filters.voltage.title')} collapsible>
          <RangeInput attribute='vnom' unit='V' />
        </FilterOption>

        <hr />
      </>}

      {currentCategory === 'e_mobility' && <>
        <FilterOption label={I18n.t('frontend.offers_index.filters.pnom_input.title')} collapsible>
          <RangeInput attribute='pnom_input' />
        </FilterOption>

        <hr />

        <FilterOption label={I18n.t('frontend.offers_index.filters.pmax_per_charging_point.title')} collapsible>
          <RangeInput attribute='pmax_per_charging_point' />
        </FilterOption>

        <hr />

        <FilterOption label={I18n.t('frontend.offers_index.filters.no_of_input_phases.title')} collapsible>
          <MenuSelect attribute='no_of_input_phases' sort={sortNumerically} />
        </FilterOption>

        <hr />

        <FilterOption label={I18n.t('frontend.offers_index.filters.coupling_type.title')} collapsible>
          <MenuSelect attribute='coupling_type' locales={I18n.t('frontend.offers_index.filters.coupling_type.values')} />
          <IsRefined attribute='coupling_type'>
            <div className='mt-2'>
              <RangeInput attribute='no_of_couplings' label={I18n.t('frontend.offers_index.filters.no_of_couplings.title')} />
            </div>
          </IsRefined>
        </FilterOption>

        <hr />

        <FilterOption label={I18n.t('frontend.offers_index.filters.socket_type.title')} collapsible>
          <MenuSelect attribute='socket_type' locales={I18n.t('frontend.offers_index.filters.socket_type.values')} />
          <IsRefined attribute='socket_type'>
            <div className='mt-2'>
              <RangeInput attribute='no_of_sockets' label={I18n.t('frontend.offers_index.filters.no_of_sockets.title')} />
            </div>
          </IsRefined>
        </FilterOption>

        <FilterOption label={I18n.t('frontend.offers_index.filters.communication_and_monitoring')} collapsible>
          <ToggleRefinementCheckbox attribute='communication_interface' value={true} label={I18n.t('frontend.offers_index.filters.communication_interface.title')} />
          <ToggleRefinementCheckbox attribute='access_control' value={true} label={I18n.t('frontend.offers_index.filters.access_control.title')} />
          <ToggleRefinementCheckbox attribute='energy_meter' value={true} label={I18n.t('frontend.offers_index.filters.energy_meter.title')} />
        </FilterOption>

        <hr />
      </>}

      <FilterOption label={I18n.t('frontend.offers_index.filters.rating.title')} collapsible>
        <MinRatingInput attribute='offer.vendor_rating' min={0} max={5} />
      </FilterOption>

      <hr />

      <FilterOption label={I18n.t('frontend.offers_index.filters.location.title')} collapsible collapsed>
        <RefinementList attribute='offer.location' sort={sortByLabel} />
      </FilterOption>

      <div className="btn btn-outline-primary text-center d-block d-md-none" onClick={toggleFilters}>
        {I18n.t('frontend.offers_index.filters.mobile.hide')}
      </div>
    </div>
  </>
}

export default connectCurrentRefinements(Filters)
