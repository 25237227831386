import React from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom'

export const searchContext = React.createContext({})

const CurrentRefinements = ({ items, children, ...props }) => {
  return <ContextProvider items={items} {...props}>
    {children}
  </ContextProvider>
}

const ContextProvider = ({ searchState, userId, countryCode, vendorId, inquiryId, userStatus, categorySlugs, children, offersExpanded, favoritesUuids, emailConfirmed, items }) => {
  const value = {
    userId,
    countryCode,
    vendorId,
    inquiryId,
    userStatus,
    categorySlugs,
    offersExpanded,
    favoritesUuids,
    emailConfirmed,
    currentRefinements: items,
  }

  if(searchState) {
    value.filters = {
      price: searchState['offer.price_ranges.price_per_quantity_max'],
      location: searchState.refinementList && (searchState.refinementList['offer.location'] || null),
      in_stock: searchState['offer.in_stock'] || null,
      trust_service: searchState.toggle && (searchState['toggle']['offer.support_trust_service'] || null),
      min_rating: searchState.range && searchState.range['offer.vendor_rating'] && (searchState.range['offer.vendor_rating'].min || null),
    }

    if(searchState["offer.min_quantity_offer.quantity"]) {
      value.filters.quantity = parseInt(searchState["offer.min_quantity_offer.quantity"])
      value.filters.quantity_unit = 'pcs'
    }

    if(searchState["offer.min_quantity_kw_offer.quantity_kw"]) {
      value.filters.quantity = parseInt(searchState["offer.min_quantity_kw_offer.quantity_kw"])
      value.filters.quantity_unit = 'kw'
    }

    if(searchState["offer.min_quantity_pallets_offer.quantity_pallets"]) {
      value.filters.quantity = parseInt(searchState["offer.min_quantity_pallets_offer.quantity_pallets"])
      value.filters.quantity_unit = 'pallets'
    }

    if(searchState["offer.min_quantity_containers_offer.quantity_containers"]) {
      value.filters.quantity = parseInt(searchState["offer.min_quantity_containers_offer.quantity_containers"])
      value.filters.quantity_unit = 'containers'
    }

    // for(const item of items) {
    //   if(item.id === 'offer.min_quantity_offer.quantity') {
    //     value.filters.quantity = parseInt(item.label)
    //     value.filters.quantity_unit = 'pcs'

    //     break
    //   }

    //   if(item.id === 'offer.min_quantity_kw_offer.quantity_kw') {
    //     value.filters.quantity = parseInt(item.label)
    //     value.filters.quantity_unit = 'kw'

    //     break
    //   }

    //   if(item.id === 'offer.min_quantity_pallets_offer.quantity_pallets') {
    //     value.filters.quantity = parseInt(item.label)
    //     value.filters.quantity_unit = 'pallets'

    //     break
    //   }

    //   if(item.id === 'offer.min_quantity_containers_offer.quantity_containers') {
    //     value.filters.quantity = parseInt(item.label)
    //     value.filters.quantity_unit = 'containers'

    //     break
    //   }
    // }
  }

  return <searchContext.Provider value={value}>
    {children}
  </searchContext.Provider>
}

export default connectCurrentRefinements(CurrentRefinements)
