import React from 'react'

import { cloudinaryCloudName } from "../../src/helpers"

const PLACEHOLDERS = {
  solar_module: (w, h) => `https://res.cloudinary.com/sonix-solar-gmbh/image/upload/q_auto,f_auto,h_${h},w_${w},c_fit,o_20/solartraders/placeholder_solar_panels.svg`,
  inverter:     (w, h) => `https://res.cloudinary.com/sonix-solar-gmbh/image/upload/q_auto,f_auto,h_${h},w_${w},c_fit,o_20/solartraders/placeholder_solar_inverter.svg`,
  battery:      (w, h) => `https://res.cloudinary.com/sonix-solar-gmbh/image/upload/q_auto,f_auto,h_${h},w_${w},c_fit,o_20/solartraders/placeholder_solar_battery.svg`,
  accessory:    (w, h) => `https://res.cloudinary.com/sonix-solar-gmbh/image/upload/q_auto,f_auto,h_${h},w_${w},c_fit,o_20/solartraders/placeholder_accessory.svg`
}

const ProducerLogo = ({ hit, width = 120, height = 140 }) => {
  if(hit.producer_logo)
    return <img
      src={`https://res.cloudinary.com/${cloudinaryCloudName()}/e_make_transparent:1/q_auto,f_auto,w_${width},h_${height},c_pad,g_west/${hit.producer_logo}`}
      width={width}
      height={height}
      alt={hit.producer_name || "Producer Logo"}
    />

  return null
}

export default ProducerLogo
