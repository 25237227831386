import React from 'react'

import I18n from '@/src/i18n.js.erb'

const Title = ({ hit, productUrl }) => {
  const product_name = hit['product_name_' + I18n.locale] || hit.product_name
  const title = `${hit.producer_name} ${hit['dn_' + I18n.locale] || hit.product_name}`

  return <div className='card__product-info-title-name'>
    <a href={productUrl} title={title}>{product_name}</a>
  </div>
}

export default Title
