import React from 'react'
import { connectMenu } from 'react-instantsearch-dom'

import I18n from '@/src/i18n.js.erb'

import Checkbox from '../common/Checkbox'
import FilterBubble from '../common/FilterBubble'

const ActiveFilter = ({ items, currentRefinement, withIssues, clearedIssues = false, refine, role }) => {
  const filter = value => e => {
    e.preventDefault()

    if (value != null && value != currentRefinement) {
      refine(value)
    } else {
      refine(null);
    }
  }

  let count = { all: 0 }
  for (let item of items) {
    if (item.label !== 'issues') {
      count[item.label] = item.count
      count['all'] += item.count
    } else {
      if (!clearedIssues) count[item.label] = item.count
    }
  }

  return <>
    <ul class='refinement-list'>
      <>
        <li>
          <Checkbox checked={currentRefinement === 'open'} onChange={filter('open')}>
            <div className='d-flex align-items-center justify-content-between flex-greed'>
              <div className='truncate'>{I18n.t('frontend.inquiries_index.filter.open')}</div>
              <div className='refinement-list__count text-small'>{count['open'] || 0}</div>
            </div>
          </Checkbox>
        </li>
        <li>
          <Checkbox checked={currentRefinement === 'approval_needed'} onChange={filter('approval_needed')}>
            <div className='d-flex align-items-center justify-content-between flex-greed'>
              {role == 'vendor' && <div className='truncate'>{I18n.t('frontend.inquiries_index.filter.approval_needed')}</div>}
              {role == 'buyer' && <div className='truncate'>{I18n.t('frontend.inquiries_index.filter.pending')}</div>}
              <div className='refinement-list__count text-small'>{count['approval_needed'] || 0}</div>
            </div>
          </Checkbox>
        </li>
        <li>
          <Checkbox checked={currentRefinement === 'confirmed'} onChange={filter('confirmed')}>
            <div className='d-flex align-items-center justify-content-between flex-greed'>
              {role == 'vendor' && <div className='truncate'>{I18n.t('frontend.inquiries_index.filter.success')}</div>}
              {role == 'buyer' && <div className='truncate'>{I18n.t('frontend.inquiries_index.filter.purchased')}</div>}
              <div className='refinement-list__count text-small'>{count['confirmed'] || 0}</div>
            </div>
          </Checkbox>
        </li>
        {withIssues && <li>
          <Checkbox checked={currentRefinement === 'cancellation_requested'} onChange={filter('cancellation_requested')}>
            <div className='d-flex align-items-center justify-content-between flex-greed'>
              <div className='truncate'>{I18n.t('frontend.inquiries_index.filter.pending_cancellation')}</div>
              <div className='refinement-list__count text-small'>{count['cancellation_requested'] || 0}</div>
            </div>
          </Checkbox>
        </li>}
        <li>
          <Checkbox checked={currentRefinement === 'closed'} onChange={filter('closed')}>
            <div className='d-flex align-items-center justify-content-between flex-greed'>
              <div className='truncate'>{I18n.t('frontend.inquiries_index.filter.closed')}</div>
              <div className='refinement-list__count text-small'>{count['closed'] || 0}</div>
            </div>
          </Checkbox>
        </li>
      </>
    </ul>
  </>
}

export default connectMenu(ActiveFilter)
