import React from "react"

const COLORS = {
  1: "silversilver",
  2: "blacksilver",
  3: "blackblack"
}

const ModuleColorIcon = ({hit}) => {
  const color = COLORS[hit?.color]
  if (!color) { return }

  return <div className={`product-solar-module-color product-solar-module-color--${color}`} />
}

const SolarModule = ({hit}) => <>
  {hit.single_power && <>{hit.single_power}W </>}
  {hit.cell_type_name && <>{hit.cell_type_name} </>}
  <ModuleColorIcon hit={hit} />
</>

const Inverter = ({hit}) => <>
  {hit.pnom_dc && <>{hit.pnom_dc} KW </>}
  {hit.no_of_phases && <>{hit.no_of_phases} Phase</>}
</>

const Battery = ({hit}) => <>
  {hit.capacity && <>{hit.capacity} KWh Capacity </>}
</>

const Hit = ({hit, onClick}) => {
  const triggerClickCallback = () => { onClick(hit) }

  let details = null

  switch(hit.type) {
    case "solar_module": details = <SolarModule hit={hit} />; break
    case "inverter": details = <Inverter hit={hit} />; break
    case "battery": details = <Battery hit={hit} />; break
  }

  return <tr onClick={triggerClickCallback} className="clickable">
    <td className="name">{hit.name}</td>
    <td className="details">{details}</td>
  </tr>
}

export default Hit
