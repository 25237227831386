import React, { useEffect, useState } from 'react'

const ProgressiveImage = ({placeholder, replace, ...props}) => {
  const [src, setSrc] = useState(placeholder)

  useEffect(() => {
    const image = new Image()

    image.srcset = replace

    image.addEventListener('load', () => setSrc(replace))
  }, [])

  return <img srcset={src} {...props} />
}

export default ProgressiveImage
