import { baseApi } from "../base/base";

export const adminUsersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdmins: builder.query({
      query: () => ({ url: '/admin/users/admins' }),
      providesTags: ['User']
    }),
  }),
  overrideExisting: false
})

export const { useGetAdminsQuery } = adminUsersApi;
