import React from 'react'

const EMobilityProductHit = ({hit, onSelect, current}) => {
  let className = ''
  if(current) className = 'current'

  return <li className={className} onClick={() => onSelect(hit)}>
    <div className='name'><strong>{hit.producer_name}</strong> - {hit.name}</div>
  </li>
}

export default EMobilityProductHit
