import React, { useContext }  from 'react'
import { connectHitInsights } from 'react-instantsearch-dom'
import aa                     from 'search-insights'
import qs                     from 'qs'

import I18n from '@/src/i18n.js.erb'

import { searchContext } from '../OffersIndex/SearchContextProvider'

import Card from './Card'

const buildUrl = (hit, categorySlugs) => {
  const categoryString = hit.categories.lvl1 || hit.categories.lvl0
  const categoryPath = categoryString.split(' > ').map(c => categorySlugs[c]).join('/')

  return '/' + I18n.locale + '/products/' + categoryPath + '/' + hit.slug
}

const Hit = ({ hit, inquiryId }) => {
  const context  = useContext(searchContext)
  let productUrl = buildUrl(hit, context.categorySlugs)

  if(inquiryId) productUrl += '?inquiry_id=' + inquiryId

  const cardClicked = () => {
    const queryParams = {}
    for(const filter of Object.keys(context.filters)) {
      if(context.filters[filter] !== null) queryParams[filter] = context.filters[filter]
    }

    const queryString = qs.stringify({filter: queryParams})
    window.prevScrollY = window.scrollY

    if(queryString !== '') productUrl += '?' + queryString

    Turbo.visit(productUrl)
  }

  return <Card hit={hit} productUrl={productUrl} onClick={cardClicked} />
}

export default connectHitInsights(aa)(Hit)
