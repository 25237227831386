import { baseApi } from "./base/base";

export const ticketsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTickets: builder.query({
      query: (data) => ({ url: '/tickets', params: data }),
      providesTags: ['Ticket']
    }),
    getTicket: builder.query({
      query: (id) => ({ url: `/tickets/${id}` }),
      providesTags: ['Ticket']
    }),
    updateTicket: builder.mutation({
      query: (data) => ({ url: `/tickets/${data.id}`, method: 'PATCH', body: data }),
      invalidatesTags: ['Ticket']
    }),
    resolveTicket: builder.mutation({
      query: (id) => ({ url: `/tickets/${id}/resolve`, method: 'POST' }),
      invalidatesTags: ['Ticket']
    }),
    deleteTicket: builder.mutation({
      query: (id) => ({ url: `/tickets/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Ticket']
    }),
  }),
  overrideExisting: false
})

export const { useDeleteTicketMutation, useGetTicketQuery, useGetTicketsQuery, useResolveTicketMutation, useUpdateTicketMutation } = ticketsApi;
