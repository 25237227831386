const normalizedFloatFromInput = (stringValue) => {
  if (!stringValue) { return 0.0 }
  if (typeof stringValue !== "string") { return 0.0 }

  const comma = ","
  const dot = "."
  const commaIndex = stringValue.indexOf(comma)
  const dotIndex = stringValue.indexOf(dot)

  let thousandSeparator = ""
  let fractionSeparator = ""

  // If there is neither comma nor dot, we don't have a decimal so we can just parse the number
  if (commaIndex === -1 && dotIndex === -1) {
    return parseFloat(stringValue)
  // If there is only one comma OR one dot, we can replace the comma with a dot and parse it.
  } else if (commaIndex === -1 || dotIndex === -1) {
    return parseFloat(stringValue.replaceAll(comma, dot))
  } else {
    // If the dot is more to the left than the dot it's the thousand separator, otherwise it's the other way around.
    thousandSeparator = commaIndex < dotIndex ? comma : dot
    fractionSeparator = thousandSeparator === comma ? dot : comma
  }

  // Remove all thousand separators and replace the fractional separator with a dot, then return the parsed value.
  return parseFloat(stringValue.replaceAll(thousandSeparator, "").replaceAll(fractionSeparator, dot))
}

export const fetchOfferStock = (response) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(response, 'text/html');
  const inputs = doc.querySelectorAll('input[name="offer_price_range[quantity_to]"]');
  const priceInputs = doc.querySelectorAll('input[name="offer_price_range[price_per_quantity]"]');
  const form = doc.querySelector('form');

  const actionValue = form.getAttribute('action');
  const offerId = actionValue.match(/\/(\d+)\//)[1];

  let maxValue = -Infinity;
  let maxInput;

  inputs.forEach(input => {
    const inputValue = parseInt(input.value);
    if (!isNaN(inputValue) && inputValue > maxValue) {
      maxValue = inputValue;
      maxInput = input;
    }
  });

  let minPrice = normalizedFloatFromInput(priceInputs[0]?.value)
  let maxPrice = normalizedFloatFromInput(priceInputs[0]?.value)

  priceInputs.forEach(priceInput => {
    const inputPrice = normalizedFloatFromInput(priceInput.value)

    if(inputPrice <= 0) { return }

    if (inputPrice < minPrice) { minPrice = inputPrice }
    if (inputPrice > maxPrice) { maxPrice = inputPrice }
  });

  return {
    offerId: offerId,
    stock: maxInput.value,
    minPrice: minPrice,
    maxPrice: maxPrice,
  }
}
