import React      from 'react'
import InputHints from 'react-input-hints'

const AnimatedInput = ({ placeholder, placeholders, ...props }) => {
  if(placeholders)
    return <InputHints placeholders={placeholders} {...props} />

  return <input placeholder={placeholder} {...props} />
}

export default AnimatedInput
