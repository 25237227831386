import React, { useState } from 'react'
import { createConnector } from 'react-instantsearch-dom'

const getIndexId = ({ ais, multiIndexContext }) => multiIndexContext ? multiIndexContext.targetedIndex : ais.mainTargetedIndex

const connectRange = createConnector({
  displayName: 'RangeInput',

  getProvidedProps(props, searchState) {
    const attribute         = props.attribute + '_range'
    const currentRefinement = searchState[attribute] || {min: undefined, max: undefined}

    return {
      currentRefinement
    }
  },

  refine(props, searchState, nextRefinement) {
    const attribute = props.attribute + '_range'

    return {
      ...searchState,
      [attribute]: nextRefinement ? nextRefinement : null
    }
  },

  getSearchParameters(searchParameters, props, searchState) {
    const attribute = props.attribute + '_range'

    let newSearchParameters = searchParameters

    if(searchState[attribute]) {
      if(searchState[attribute]['min'])
        newSearchParameters = newSearchParameters.addNumericRefinement(props.attribute, '>=', searchState[attribute]['min'])
      else
        newSearchParameters.removeNumericRefinement(props.attribute, '>=')

      if(searchState[attribute]['max'])
        newSearchParameters = newSearchParameters.addNumericRefinement(props.attribute, '<=', searchState[attribute]['max'])
      else
        newSearchParameters.removeNumericRefinement(props.attribute, '<=')
    }

    return newSearchParameters
  },

  cleanUp(props, searchState) {
    const attribute = props.attribute + '_range'

    const {[attribute]: omit, ...nextSearchState} = searchState

    return nextSearchState
  },

  getMetadata(props, searchState) {
    const attribute = props.attribute + '_range'

    return {
      id: attribute,
      index: getIndexId({
        ais: props.contextValue,
        multiIndexContext: props.indexContextValue
      }),
      items: searchState[attribute]
        ? [
            {
              label: `${searchState[attribute]}`,
              value: (nextState) => ({
                ...nextState,
                [attribute]: null
              })
            }
          ]
        : []
    }
  }
})

const RangeFilter = ({ currentRefinement, refine, label, placeholderMin = 'Min.', placeholderMax = 'Max.', unit }) => {
  const [value, setValue] = useState({min: currentRefinement['min'] || '', max: currentRefinement['max'] || ''})

  const minChanged = e => {
    const value = parseFloat(e.target.value.replace(',', '.'))

    setValue(v => {
      return {
        ...v,
        min: e.target.value
      }
    })

    refine({
      ...currentRefinement,
      min: !isNaN(value) ? value : undefined
    })
  }

  const maxChanged = e => {
    const value = parseFloat(e.target.value.replace(',', '.'))

    setValue(v => {
      return {
        ...v,
        max: e.target.value
      }
    })

    refine({
      ...currentRefinement,
      max: !isNaN(value) ? value : undefined
    })
  }

  const inputClassName = unit ? 'form-control' : 'form-control form-control--small'
  const input1 = <input type='text' value={value.min} className={inputClassName} onChange={minChanged} size='6' placeholder={placeholderMin} />
  const input2 = <input type='text' value={value.max} className={inputClassName} onChange={maxChanged} size='6' placeholder={placeholderMax} />

  if(unit) return <div className='form-group'>
    {label && <label>{label}</label>}
    <div className='d-flex flex-row align-items-center justify-content-between'>
      <div className='input-group input-group--small d-inline-flex align-items-center'>
        {input1}
        <div className='input-group-append'>
          <div className='input-group-text'>{unit}</div>
        </div>
      </div>
      <div className='mx-2'>to</div>
      <div className='input-group input-group--small d-inline-flex align-items-center'>
        {input2}
        <div className='input-group-append'>
          <div className='input-group-text'>{unit}</div>
        </div>
      </div>
    </div>
  </div>

  return <div class='form-group inline-fields'>
    {label && <label>{label}</label>}
    {input1} to {input2}
  </div>
}

export default connectRange(RangeFilter)
