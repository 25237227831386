import React from 'react'
import { connectMenu } from 'react-instantsearch-dom'

import I18n from '@/src/i18n.js.erb'

import Checkbox from '../common/Checkbox'

const ATTRIBUTE = 'source'

const Filter = connectMenu(({ currentRefinement, refine, facets, items }) => {
  const handleToggle = value => e => {
    e.preventDefault()
    e.stopPropagation()

    if (currentRefinement === value)
      refine(null)
    else
      refine(value)
  }

  return <>
    <ul class='refinement-list'>
      <li>
        <Checkbox checked={currentRefinement === 'manual'} onChange={handleToggle('manual')}>
          <div className='d-flex align-items-center justify-content-between flex-greed'>
            <div className='truncate'>{I18n.t('frontend.my_offers_index.filter.source_manual')}</div>
            <div className='refinement-list__count text-small'>{items?.find(item => item.label == 'manual')?.count || 0}</div>
          </div>
        </Checkbox>
      </li>
      <li>
        <Checkbox checked={currentRefinement === 'vendor_import'} onChange={handleToggle('vendor_import')}>
          <div className='d-flex align-items-center justify-content-between flex-greed'>
            <div className='truncate'>{I18n.t('frontend.my_offers_index.filter.source_vendor_import')}</div>
            <div className='refinement-list__count text-small'>{items?.find(item => item.label == 'vendor_import')?.count || 0}</div>

          </div>
        </Checkbox>
      </li>
    </ul>
  </>
})

const SourceFilter = () => <Filter attribute={ATTRIBUTE} />

export default SourceFilter
