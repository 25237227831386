import React from 'react'

import ProgressiveImage from '../common/ProgressiveImage'
import { cloudinaryCloudName } from "../../src/helpers"

const PLACEHOLDERS = {
  solar_module: (w, h) => `https://res.cloudinary.com/sonix-solar-gmbh/image/upload/q_auto,f_auto,h_${h},w_${w},c_pad,o_20/solartraders/placeholder_solar_panels.svg`,
  inverter:     (w, h) => `https://res.cloudinary.com/sonix-solar-gmbh/image/upload/q_auto,f_auto,h_${h},w_${w},c_pad,o_20/solartraders/placeholder_solar_inverter.svg`,
  battery:      (w, h) => `https://res.cloudinary.com/sonix-solar-gmbh/image/upload/q_auto,f_auto,h_${h},w_${w},c_pad,o_20/solartraders/placeholder_solar_battery.svg`,
  accessory:    (w, h) => `https://res.cloudinary.com/sonix-solar-gmbh/image/upload/q_auto,f_auto,h_${h},w_${w},c_pad,o_20/solartraders/placeholder_accessory.svg`,
  // This is here to prevent JS errors - we shouldn't really have EMobilityProducts anymore.
  e_mobility:    (w, h) => `https://res.cloudinary.com/sonix-solar-gmbh/image/upload/q_auto,f_auto,h_${h},w_${w},c_pad,o_20/solartraders/placeholder_accessory.svg`,
}

const CLOUDINARIY_PATH = `https://res.cloudinary.com/${cloudinaryCloudName()}/`

const placeholderTransformation = (w, h) => `e_make_transparent:1/e_blur:1000,f_auto,q_1,h_${h},w_${w},c_pad`
const transformation            = (w, h) => `e_make_transparent:1/q_auto,f_auto,h_${h},w_${w},c_pad`

const placeholderSrcset = (w, h, id) => `${CLOUDINARIY_PATH}${placeholderTransformation(w, h)}/${id} 1x, ${CLOUDINARIY_PATH}${placeholderTransformation(w*2, h*2)}/${id} 2x`
const srcset            = (w, h, id) => `${CLOUDINARIY_PATH}${transformation(w, h)}/${id} 1x, ${CLOUDINARIY_PATH}${transformation(w*2, h*2)}/${id} 2x`

const ProductImage = ({ hit, width = 130, height = 300, ...props }) => {
  if(hit.product_image) {
    return <ProgressiveImage
      replace={srcset(width, height, hit.product_image)}
      placeholder={placeholderSrcset(width, height, hit.product_image)}
      width={width}
      height={height}
      alt={hit.product_name || "Main product image"}
      {...props}
    />
  }

  return <img
    src={PLACEHOLDERS[hit.offer_type || hit.type](width, height)}
    width={width}
    height={height}
    alt={hit.product_name || "Main product image placeholder"}
    {...props}
  />
}

export default ProductImage
