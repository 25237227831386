import React, { useRef } from 'react'

import I18n from '@/src/i18n.js.erb'
import useBootstrap from '../hooks/useBootstrap'

const ModuleMeasurements = ({ hit }) => {
  if (hit.length && hit.width && hit.thickness) {
    return (
      <tr>
        <td className='key'>{I18n.t('frontend.offers_index.hits.measurements')}:</td>
        <td>{hit.length} x {hit.width} x {hit.thickness} [mm]</td>
      </tr>
    )
  }

  return null
}

const Badge = ({ condition, tooltip, children }) => {
  if (condition) {
    return (
      <span
        className='badge-top-performer'
        data-toggle='tooltip'
        data-placement='top'
        title={tooltip}
      >
        {children}
      </span>
    )
  }

  return null
}

const BatteryType = ({ value, typeTooltip }) => {
  return value ? (
    <span {...typeTooltip}>
      {I18n.t('frontend.offers_index.hits.battery_types.' + value)}
    </span>
  ) : '-'
}

const Efficiency = ({ value, showBadge }) => {
  return value ? (
    <>
      {Math.round(value)}%&nbsp;
      <Badge
        condition={showBadge}
        tooltip='The efficiency of this panel is in the top 25 percentile compared to all other offers over the last 6 months.'
      >
        top performance
      </Badge>
    </>
  ) : '-'
}

const ValueWithUnit = ({ value, unit }) => {
  return value ? (
    <>
      {value}&nbsp;
      <span class="font-weight-normal">
        {unit}
      </span>
    </>
  ) : '-'
}

const Voltage = ({ value }) => {
  return value ? (
    value < 100
      ? I18n.t('frontend.offers_index.hits.low_voltage')
      : I18n.t('frontend.offers_index.hits.high_voltage')
  ) : '-'
}

const Warranty = ({ years, showBadge = false }) => {
  return years ? (
    <>
      {I18n.t('frontend.offers_index.hits.years', { count: years })}&nbsp;
      <Badge
        condition={showBadge}
        tooltip='This panels offers an outstanding product warranty with a threshold of greater 15 years'
      >
        superior
      </Badge>
    </>
  ) : '-'
}

const SolarModule = ({ hit }) => {
  const el = useBootstrap()

  return <div className='card__product-info-details' ref={el}>
    <div className='d-flex justify-content-between flex-column flex-md-row align-items-start flex-gap-4'>
      <table className='card__product-info-details'>
        <tbody>
          <tr className='font-weight-bold'>
            <td className='key'>{I18n.t('frontend.offers_index.hits.power')}:</td>
            <td><ValueWithUnit value={hit.single_power} unit='W' /></td>
          </tr>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.number_of_cells')}:</td>
            <td>{hit.number_of_cells || '-'}</td>
          </tr>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.cell_type')}:</td>
            <td>{hit.cell_type_name || '-'}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.efficiency')}:</td>
            <td><Efficiency value={hit.efficiency} showBadge={!!hit.module_types.includes('top_efficiency')} /></td>
          </tr>
          <ModuleMeasurements hit={hit} />
          {hit.product_warranty && (
            <tr>
              <td className='key'>{I18n.t('frontend.offers_index.hits.guarantee')}:</td>
              <td><Warranty years={hit.product_warranty} showBadge={hit.product_warranty >= 15} /></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
}

const Inverter = ({ hit }) => {
  return <div className='card__product-info-details'>
    <div className='d-flex justify-content-between flex-column flex-md-row align-items-start flex-gap-4'>
      <table>
        <tbody>
          <tr className='font-weight-bold'>
            <td className='key'>{I18n.t('frontend.offers_index.hits.pnom_ac')}:</td>
            <td><ValueWithUnit value={hit.pnom_ac} unit='KW' /></td>
          </tr>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.pnom_dc')}:</td>
            <td><ValueWithUnit value={hit.pnom_dc} unit='KW' /></td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.mppt')}:</td>
            <td>{hit.no_of_mpp || '-'}</td>
          </tr>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.phases')}:</td>
            <td>{hit.no_of_phases || '-'}</td>
          </tr>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.guarantee')}:</td>
            <td><Warranty years={hit.product_warranty} /></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
}

const BatteryBMSModule = ({ hit }) => {
  const el = useBootstrap()

  return <>
    <div className='main-value' ref={el}></div>
    <div className='card__product-info-details'>
      <div className='d-flex justify-content-between flex-column flex-md-row align-items-start flex-gap-4'>
        <table >
          <tbody>
            <tr>
              <td className='key'>{I18n.t('frontend.offers_index.hits.system_type')}:</td>
              <td><Voltage value={hit.vnom} /></td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td className='key'>{I18n.t('frontend.offers_index.hits.guarantee')}:</td>
              <td><Warranty years={hit.product_warranty} /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </>
}

const BatteryModule = ({ hit }) => {
  const el = useBootstrap()

  const typeTooltip = {}

  if (I18n.t('frontend.offers_index.hits.battery_type_tooltips')[hit.battery_type]) {
    typeTooltip['data-toggle'] = 'tooltip'
    typeTooltip['data-placement'] = 'top'
    typeTooltip['title'] = I18n.t('frontend.offers_index.hits.battery_type_tooltips')[hit.battery_type]
    typeTooltip['class'] = 'has-tooltip'
  }

  return <div className='card__product-info-details' ref={el}>
    <div className='main-value'></div>
    <div className='d-flex justify-content-between flex-column flex-md-row align-items-start flex-gap-4'>
      <table className='card__product-info-details'>
        <tbody>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.capacity')}:</td>
            <td><ValueWithUnit value={hit.capacity} unit='kWh' /></td>
          </tr>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.battery_voltage')}:</td>
            <td><ValueWithUnit value={hit.vnom} unit='V' /></td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.battery_type')}:</td>
            <td><BatteryType value={hit.battery_type} typeTooltip={typeTooltip} /></td>
          </tr>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.guarantee')}:</td>
            <td><Warranty years={hit.product_warranty} /></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
}

const BatterySystem = ({ hit }) => {
  const el = useBootstrap()

  const typeTooltip = {}

  if (I18n.t('frontend.offers_index.hits.battery_type_tooltips')[hit.battery_type]) {
    typeTooltip['data-toggle'] = 'tooltip'
    typeTooltip['data-placement'] = 'top'
    typeTooltip['title'] = I18n.t('frontend.offers_index.hits.battery_type_tooltips')[hit.battery_type]
    typeTooltip['class'] = 'has-tooltip'
  }

  return <div className='card__product-info-details' ref={el}>
    <div className='main-value'></div>
    <div className='d-flex justify-content-between flex-column flex-md-row align-items-start flex-gap-4'>
      <table>
        <tbody>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.capacity')}:</td>
            <td><ValueWithUnit value={hit.capacity} unit='kWh' /></td>
          </tr>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.system_voltage')}:</td>
            <td><Voltage value={hit.vnom} /></td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.battery_type')}:</td>
            <td><BatteryType value={hit.battery_type} typeTooltip={typeTooltip} /></td>
          </tr>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.guarantee')}:</td>
            <td><Warranty years={hit.product_warranty} /></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
}

const BatteryBundle = ({ hit }) => {
  const el = useBootstrap()

  const typeTooltip = {}

  if (I18n.t('frontend.offers_index.hits.battery_type_tooltips')[hit.battery_type]) {
    typeTooltip['data-toggle'] = 'tooltip'
    typeTooltip['data-placement'] = 'top'
    typeTooltip['title'] = I18n.t('frontend.offers_index.hits.battery_type_tooltips')[hit.battery_type]
    typeTooltip['class'] = 'has-tooltip'
  }

  return <div className='card__product-info-details' ref={el}>
    <div className='main-value'></div>
    <div className='d-flex justify-content-between flex-column flex-md-row align-items-start flex-gap-4'>
      <table>
        <tbody>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.capacity')}:</td>
            <td><ValueWithUnit value={hit.capacity} unit='kWh' /></td>
          </tr>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.battery_voltage')}:</td>
            <td><Voltage value={hit.vnom} /></td>
          </tr>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.battery_type')}:</td>
            <td><BatteryType value={hit.battery_type} typeTooltip={typeTooltip} /></td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.inverter_pnom_ac')}:</td>
            <td><ValueWithUnit value={hit.inverter_pnom_ac} unit='KW AC' /></td>
          </tr>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.mppt')}:</td>
            <td>{hit.no_of_mpp || '-'}</td>
          </tr>
          <tr>
            <td className='key'>{I18n.t('frontend.offers_index.hits.guarantee')}:</td>
            <td><Warranty years={hit.product_warranty} /></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
}

const BatteryDefault = ({ hit }) => {
  const el = useBootstrap()

  const typeTooltip = {}
  if (I18n.t('frontend.offers_index.hits.battery_type_tooltips')[hit.battery_type]) {
    typeTooltip['data-toggle'] = 'tooltip'
    typeTooltip['data-placement'] = 'top'
    typeTooltip['title'] = I18n.t('frontend.offers_index.hits.battery_type_tooltips')[hit.battery_type]
    typeTooltip['class'] = 'has-tooltip'
  }

  return <>
    <table className='card__product-info-details' ref={el}>
      <tbody>
        <tr>
          <td className='key'>{I18n.t('frontend.offers_index.hits.capacity')}:</td>
          <td><ValueWithUnit value={hit.capacity} unit='kWh' /></td>
        </tr>
        <tr>
          <td className='key'>{I18n.t('frontend.offers_index.hits.battery_voltage')}:</td>
          <td><Voltage value={hit.vnom} /></td>
        </tr>
        <tr>
          <td className='key'>{I18n.t('frontend.offers_index.hits.battery_type')}:</td>
          <td><BatteryType value={hit.battery_type} typeTooltip={typeTooltip} /></td>
        </tr>
        <tr>
          <td className='key'>{I18n.t('frontend.offers_index.hits.guarantee')}:</td>
          <td><Warranty years={hit.product_warranty} /></td>
        </tr>
      </tbody>
    </table>
  </>
}

const Battery = ({ hit }) => {
  let category

  if (hit.categories.lvl1) {
    category = hit.categories.lvl1.split(' > ')[1]
  } else {
    category = hit.categories.lvl0
  }

  if (category === 'battery_bms_modules') return <BatteryBMSModule hit={hit} />
  if (category === 'battery_modules') return <BatteryModule hit={hit} />
  if (category === 'battery_systems') return <BatterySystem hit={hit} />
  if (category === 'battery_bundles') return <BatteryBundle hit={hit} />

  return <BatteryDefault hit={hit} />
}

const Accessory = ({ hit }) => {
  const el = useRef()

  return <div className='card__product-info-details' />
}

const EMobilityProduct = ({ hit }) => {
  return 'e-mobility attributes tbd...'
}

const ProductInfo = ({ hit, topEfficiencyThreshold }) => {
  // We're getting hits from both OffersIndex as well as FavouritesIndex in here, so we have to do some fall backs in a few places.
  switch (hit.offer_type || hit.type) {
    case 'solar_module': return <SolarModule hit={hit} topEfficiencyThreshold={topEfficiencyThreshold} />
    case 'inverter': return <Inverter hit={hit} />
    case 'battery': return <Battery hit={hit} />
    case 'accessory': return <Accessory hit={hit} />
    case 'e_mobility': return <EMobilityProduct hit={hit} />
  }
}

export default ProductInfo
