import React               from 'react'
import { createConnector } from 'react-instantsearch-dom'

import I18n from '@/src/i18n.js.erb'

import Toggle from '../common/Toggle'

const Icon = () => <svg width="105px" height="106px" viewBox="0 0 105 106" version="1.1">
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
    <g transform="translate(2.000000, 1.000000)" stroke="#003560" strokeWidth="3">
      <g transform="translate(8.395020, 43.000000)">
        <polyline points="2.84217094e-14 16.2560096 2.84217094e-14 0 41.4002404 0 41.4002404 40.7091346 2.84217094e-14 40.7091346 2.84217094e-14 22.9522236"></polyline>
        <line x1="13.6907536" y1="34.7847934" x2="13.6907536" y2="31.5500791"></line>
        <line x1="5.76141827" y1="34.7238969" x2="5.76141827" y2="31.4891827"></line>
        <polyline points="15.5753791 8.8817842e-15 15.5753791 12.6612812 26.0467427 12.6612812 26.0467427 8.8817842e-15"></polyline>
      </g>
      <g transform="translate(50.395020, 43.000000)">
        <polyline transform="translate(20.700120, 20.354567) rotate(-180.000000) translate(-20.700120, -20.354567) " points="0 5.98523768 0 -2.84217094e-14 41.4002404 -2.84217094e-14 41.4002404 40.7091346 0 40.7091346 0 13.8580604"></polyline>
        <line x1="13.6907536" y1="34.7847934" x2="13.6907536" y2="31.5500791"></line>
        <line x1="5.76141827" y1="34.7238969" x2="5.76141827" y2="31.4891827"></line>
        <polyline points="15.5753791 1.42108547e-14 15.5753791 12.6612812 26.0467427 12.6612812 26.0467427 1.42108547e-14"></polyline>
      </g>
      <g transform="translate(7.395020, 0.000000)">
        <polyline transform="translate(21.305101, 21.354567) rotate(-270.000000) translate(-21.305101, -21.354567) " points="0.604980469 6.98523768 0.604980469 1 42.0052209 1 42.0052209 41.7091346 0.604980469 41.7091346 0.604980469 14.8580604"></polyline>
        <line x1="15.6907536" y1="35.7847934" x2="15.6907536" y2="32.5500791"></line>
        <line x1="7.76141827" y1="35.7238969" x2="7.76141827" y2="32.4891827"></line>
        <polyline points="9.60498047 1 9.60498047 13.6612812 20.0763441 13.6612812 20.0763441 1"></polyline>
        <polyline transform="translate(63.959548, 21.700120) rotate(-270.000000) translate(-63.959548, -21.700120) " points="43.2594276 7.33079056 43.2594276 1.34555288 84.659668 1.34555288 84.659668 42.0546875 43.2594276 42.0546875 43.2594276 15.2036133"></polyline>
        <line x1="56.9501812" y1="36.1303463" x2="56.9501812" y2="32.895632"></line>
        <line x1="49.0208459" y1="36.0694498" x2="49.0208459" y2="32.8347356"></line>
        <polyline points="58.8348067 1.34555288 58.8348067 14.0068341 69.3061703 14.0068341 69.3061703 1.34555288"></polyline>
      </g>
      <polyline points="70.2944336 92.159668 0 92.159668 0 84.3647461 101.035156 84.3647461 101.035156 92.159668 84.534668 92.159668"></polyline>
      <line x1="75.9389648" y1="92.159668" x2="78.8447266" y2="92.159668"></line>
      <polyline points="90.1513672 92.159668 90.1513672 102.67041 101.035156 102.67041 101.035156 92.159668"></polyline>
      <polyline points="45.1513672 92.159668 45.1513672 102.67041 56.0351562 102.67041 56.0351562 92.159668"></polyline>
      <polyline points="0.151367188 92.159668 0.151367188 102.67041 11.0351562 102.67041 11.0351562 92.159668"></polyline>
    </g>
  </g>
</svg>

const ATTRIBUTE = 'offer.in_stock'

const getIndexId = ({ ais, multiIndexContext }) => multiIndexContext ? multiIndexContext.targetedIndex : ais.mainTargetedIndex

const connectToggleInStock = createConnector({
  displayName: 'ToggleInStock',

  getProvidedProps(props, searchState) {
    const currentRefinement = searchState[ATTRIBUTE] || null

    return {
      currentRefinement
    }
  },

  refine(props, searchState, nextRefinement) {
    return {
      ...searchState,
      [ATTRIBUTE]: nextRefinement
    }
  },

  getSearchParameters(searchParameters, props, searchState) {
    const now = Math.floor(new Date / 1000)

    if(searchState[ATTRIBUTE]) {
      return searchParameters.removeNumericRefinement('offer.available_starting_at_ts')
                             .addNumericRefinement('offer.available_starting_at_ts', '<=', now)
    } else {
      return searchParameters.removeNumericRefinement('offer.available_starting_at_ts')
    }
  },

  cleanUp(props, searchState) {
    const {[ATTRIBUTE]: omit, ...nextSearchState} = searchState

    return nextSearchState
  },

  getMetadata(props, searchState) {
    return {
      id: ATTRIBUTE,
      index: getIndexId({
        ais: props.contextValue,
        multiIndexContext: props.indexContextValue
      }),
      items: searchState[ATTRIBUTE]
        ? [
            {
              label: `${searchState[ATTRIBUTE]}`,
              value: (nextState) => ({
                ...nextState,
                [ATTRIBUTE]: null
              })
            }
          ]
        : []
    }
  }
})

const ToggleInStock = ({ currentRefinement, refine }) => {
  const handleToggle = () => {
    if(currentRefinement)
      refine(null)
    else
      refine(true)
  }

  return <div className='toggle-refinement'>
    <Icon />
    <div className='label' onClick={handleToggle}>{I18n.t('frontend.general.in_stock')}</div>
    <Toggle checked={currentRefinement} onChange={handleToggle} />
  </div>
}

export default connectToggleInStock(ToggleInStock)
