import { useRef, useEffect } from 'react'
import BSN                   from 'bootstrap.native/dist/bootstrap-native.esm.min.js'

export default () => {
  const el = useRef()

  useEffect(() => {
    BSN.initCallback(el.current)
  })

  return el
}
