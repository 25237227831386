import React from 'react'

const Chevron = () => <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
  <mask id="mask0_3092_1776" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="21">
    <rect y="0.5" width="20" height="20" fill="#D9D9D9" />
  </mask>
  <g mask="url(#mask0_3092_1776)">
    <path d="M4 7.5L10 14L16.5 7.5" stroke="#9747FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </g>
</svg>

const FilterOption = ({ label, children, collapsible = false, collapsed = false, ...props }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(collapsed)

  let className = props.className ? 'filters__option mt-3 ' + props.className : 'filters__option mt-3'

  if (!collapsible) return <div className={className} {...props}>
    {label && <div className='filters__option-head'>
      <h4>{label}</h4>
    </div>}
    {children}
  </div>

  const handleToggle = () => setIsCollapsed(!isCollapsed)

  if (!isCollapsed) className = className + ' filters__option--expanded'

  return <div className={className} {...props}>
    <div className='filters__option-head' onClick={handleToggle}>
      <h4>{label}</h4>
      <Chevron />
    </div>
    {!isCollapsed && children}
  </div>
}

export default FilterOption
