import React, { useEffect, useRef, useState } from "react"
import toast from "react-hot-toast"

import { GearIcon } from "../OffersIndex/Icons"
import I18n from '@/src/i18n.js.erb'

import ActionsDropdownContent from './ActionsDropdownContent'

const csrfToken = () => document.querySelector("meta[name='csrf-token']").getAttribute('content')

const republishOptions = (timeframe, id) => {
  return {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-CSRF-Token': csrfToken()
    },
    body: JSON.stringify({
      republishing: {
        timeframe,
        offer_ids: [id]
      }
    })
  }
}

const deactivateOptions = id => {
  return {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-CSRF-Token': csrfToken()
    },
    body: JSON.stringify({
      deactivation: {
        offer_ids: [id]
      }
    })
  }
}

const deleteOptions = id => {
  return {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-CSRF-Token': csrfToken()
    },
    body: JSON.stringify({
      deletion: {
        offer_ids: [id]
      }
    })
  }
}

const archiveOptions = id => {
  return {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-CSRF-Token': csrfToken()
    },
    body: JSON.stringify({
      archive: {
        offer_ids: [id]
      }
    })
  }
}

const useOutsideClick = (callback) => {
  const ref = useRef()

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) { callback() }
    }

    document.addEventListener("click", handleClick, true)

    return () => { document.removeEventListener("click", handleClick, true) }
  }, [ref])

  return ref
}

const SingleActions = ({ hit, dispatch }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const handleClickOutside = () => { setDropdownOpen(false) }
  const handleClick = () => { setDropdownOpen(true) }
  const ref = useOutsideClick(handleClickOutside)

  const actions = {
    republish: null,
    deactivate: null,
    destroy: null
  }

  if (hit.published_until_ts !== 0) {
    actions.deactivate = e => {
      e.preventDefault()

      fetch('/de/vendor/offer_deactivations', deactivateOptions(hit.id)).then((response) => {
        toast.success(I18n.t("frontend.my_offers_index.messages.success_deactivate", { count: 1 }), { duration: 5000 })
      });

      dispatch({ type: 'DEACTIVATE', id: hit.id })
    }
  }

  actions.republish = timeframe => e => {
    e.preventDefault()

    fetch('/de/vendor/offer_republishings', republishOptions(timeframe, hit.id)).then((response) => {
      toast.success(I18n.t("frontend.my_offers_index.messages.success_republish", { count: 1, weeks: timeframe }), { duration: 5000 })
    });

    dispatch({ type: 'REPUBLISH', id: hit.id, timeframe })
  }

  actions.destroy = e => {
    e.preventDefault()

    fetch('/de/vendor/offer_deletions', deleteOptions(hit.id))

    dispatch({ type: 'DELETE', id: hit.id })
  }

  actions.archive = hit?.published_until_ts < Math.floor(new Date / 1000) ? e => {
    e.preventDefault()

    toast(I18n.t("frontend.my_offers_index.messages.archiving_offers"), { duration: 2000, icon: '⌛' })

    fetch('/de/vendor/offer_archives', archiveOptions(hit.id)).then(response => response.json()).then((response) => {
      if (response.success?.length > 0) {
        const message = response.success?.length === 1 ? I18n.t("frontend.my_offers_index.messages.success_archive") : I18n.t("frontend.my_offers_index.messages.success_archive_plural", { count: response.success?.length })
        toast.success(message, { duration: 5000 })
      }

      if (response.failed?.length > 0) {
        const messageError = response.failed?.length === 1 ? I18n.t("frontend.my_offers_index.messages.error_archive") : I18n.t("frontend.my_offers_index.messages.error_archive_plural", { count: response.failed?.length })
        toast.error(messageError, { duration: 5000 })
      }

      dispatch({ type: 'ARCHIVE', id: hit.id, payload: response })

    });
  } : null;

  return <div className="dropdown d-inline-block">
    <div ref={ref} onClick={handleClick}>{GearIcon({ className: "card__expand-icon fill-current dropdown-toggle" })}</div>
    <ActionsDropdownContent archive={hit.archived ? null : actions.archive} republish={actions.republish} deactivate={actions.deactivate} destroy={actions.destroy} useExternalDisplayControl={true} isOpen={isDropdownOpen} />
  </div>
}

export default SingleActions
