import React from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom'

import I18n from '../../src/i18n.js.erb'

import ValueBetweenFilter from '../algolia/ValueBetweenFilter'

import FilterOption from './FilterOption'
import ModuleQuantityFilter from './ModuleQuantityFilter'

const getCurrentCategory = refinements => {
  let categoryRefinement

  if (categoryRefinement = refinements.find(refinement => refinement.attribute === 'categories.lvl0')) {
    const path = categoryRefinement.currentRefinement.split(' > ')

    if (path[0] === 'accessories' && path[path.length - 1] === 'e_mobility') return 'e_mobility'

    return path[0]
  }

  return null
}

const QuantityFilter = ({ items }) => {
  const currentCategory = getCurrentCategory(items)

  return <>
    <FilterOption>
      {currentCategory === 'solar_modules' && <ModuleQuantityFilter asInputGroup />}
      {currentCategory !== 'solar_modules' && <ValueBetweenFilter asInputGroup attribute1='offer.min_quantity' attribute2='offer.quantity' placeholder={I18n.t('frontend.offers_index.filters.quantity.' + (currentCategory || 'all') + '.placeholder')} unit={I18n.t('frontend.general.pcs')} />}
    </FilterOption>
  </>
}

export default connectCurrentRefinements(QuantityFilter)
