import { createConnector } from 'react-instantsearch-dom'

const DeletedOffersFilter = () => null

const connectDeletedOffersFilter = createConnector({
  displayName: 'DeletedOffersFilter',

  getProvidedProps() {},

  getSearchParameters(searchParameters, props) {
    let newSearchParameters = searchParameters

    Object.keys(props.offers).forEach(id => {
      if(props.offers[id].deleted) newSearchParameters = newSearchParameters.addNumericRefinement('id', '!=', id)
    })

    return newSearchParameters
  }
})

export default connectDeletedOffersFilter(DeletedOffersFilter)
