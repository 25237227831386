import { useState } from 'react'
import qs from 'qs'

const urlToSearchState = baseUrl => {
  let state = {}

  const [url, queryString] = window.location.href.split('?')
  const filterString = url.split(baseUrl + '/')[1]

  if (filterString) state = { menu: { filter: filterString } }

  if (queryString) {
    state = {
      ...state,
      ...qs.parse(queryString)
    }
  }

  return state
}

const searchStateToUrl = (locale, baseUrl, state) => {
  let url = '/' + locale + baseUrl
  let s = state

  if (s.menu && s.menu['filter'] !== undefined) {
    const { menu: menu, ...withoutMenu } = s
    const { ...menuRest } = menu

    if (Object.values(menuRest).length > 0) {
      s = {
        ...s,
        menu: menuRest
      }
    } else {
      s = withoutMenu
    }
  }

  if (Object.values(s)) {
    url += '?' + qs.stringify(s)
  }

  history.pushState(state, null, url)
  Turbo.navigator.history.push(url)
}

export default (locale, baseUrl) => {
  const [state, setState] = useState(urlToSearchState(baseUrl))

  const stateChanged = newState => {
    searchStateToUrl(locale, baseUrl, newState)
    setState(newState)
  }

  return [state, stateChanged]
}
