import React from 'react'
import { EscrowIcon } from './Icons';

const CustomerProtection = ({ offer }) => {
  const className = offer.support_trust_service ? 'customer-protection' : 'customer-protection customer-protection--disabled'

  return <div className={className}>
    {offer.support_trust_service && <EscrowIcon />}
  </div>
}

export default CustomerProtection
