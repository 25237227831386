
export const dateFormatAbbrev = (date, format) => {
  const currentDate = new Date(date);

  const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const dayOfWeekAbbrev = daysOfWeek[currentDate.getDay()];
  const day = currentDate.getDate();
  const monthAbbrev = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  return format.replace('dd', day)
    .replace('MMM', monthAbbrev)
    .replace('YYYY', year)
    .replace('DD', dayOfWeekAbbrev)
};