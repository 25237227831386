import React from 'react'
import { connectMenu } from 'react-instantsearch-dom'

import I18n from '@/src/i18n.js.erb'

import Checkbox from '../common/Checkbox'

const PaymentMethodFilter = ({ items, currentRefinement, withIssues, clearedIssues = false, refine, setFilter }) => {
  const filter = value => e => {
    e.preventDefault()

    if (value != null && value != currentRefinement) {
      refine(value)
    } else {
      refine(null);
    }
  }

  if (setFilter) {
    setFilter.current = refine
  }

  let count = { all: 0 }
  for (let item of items) {
    if (item.label !== 'issues') {
      count[item.label] = item.count
      count['all'] += item.count
    } else {
      if (!clearedIssues) count[item.label] = item.count
    }
  }

  return <>
    <ul class='refinement-list'>
      <li>
        <Checkbox checked={currentRefinement === 'advance_payment'} onChange={filter('advance_payment')}>
          <div className='d-flex align-items-center justify-content-between flex-greed'>
            <div className='truncate'>{I18n.t('frontend.inquiries_index.filter.advanced_payment')}</div>
            <div className='refinement-list__count text-small'>{count['advance_payment'] || 0}</div>
          </div>
        </Checkbox>
      </li>
      <li>
        <Checkbox checked={currentRefinement === 'trust_service'} onChange={filter('trust_service')}>
          <div className='d-flex align-items-center justify-content-between flex-greed'>
            <div className='truncate'>{I18n.t('frontend.inquiries_index.filter.escrow_service')}</div>
            <div className='refinement-list__count text-small'>{count['trust_service'] || 0}</div>
          </div>
        </Checkbox>
      </li>
    </ul>
  </>
}

export default connectMenu(PaymentMethodFilter)
