import React, { useRef } from 'react'
import { InstantSearch } from 'react-instantsearch-dom'

import I18n from '@/src/i18n.js.erb'

import useAlgoliaClient from './hooks/useAlgoliaClient'

import Autocomplete     from './algolia/Autocomplete'
import RefinementList   from './algolia/RefinementList'
import ClearRefinements from './algolia/ClearRefinements'

import Hit from './ProductsAutocomplete/Hit'

import SearchContextProvider from './OffersIndex/SearchContextProvider'
import CategoryFilter        from './OffersIndex/CategoryFilter'
import FilterOption          from './OffersIndex/FilterOption'

import { PRODUCT_TYPES } from '../src/product_types'

const CATEGORY_ATTRIBUTES = ['categories.lvl0', 'categories.lvl1', 'categories.lvl2']

const sortByLabel = (a, b) => {
  if(a.label < b.label) return -1
  if(a.label > b.label) return 1

  return 0
}

const csrfToken = () => document.querySelector("meta[name='csrf-token']").getAttribute('content')

const OffersNew = ({appId, apiKey, indexName, placeholder, createOfferPath, rootCategories, missingProductButtonLabel, categorySlugs}) => {
  const searchClient = useAlgoliaClient(appId, apiKey)
  const formRef      = useRef()
  const typeRef      = useRef()
  const idRef        = useRef()

  const onSelect = product => {
    typeRef.current.value = PRODUCT_TYPES[product.type]
    idRef.current.value   = product.id

    formRef.current.submit()
  }

  return <InstantSearch indexName={indexName} searchClient={searchClient}>
    <form action={createOfferPath} method='POST' ref={formRef}>
      <input type='hidden' name='authenticity_token' value={csrfToken()} />
      <input type='hidden' name='offer[product_type]' ref={typeRef} />
      <input type='hidden' name='offer[product_id]' ref={idRef} />
    </form>

    <SearchContextProvider categorySlugs={categorySlugs}>
      <div className='row'>
        <div className='col-md-3 filters'>
          <FilterOption label={I18n.t('frontend.general.category')}>
            <CategoryFilter attributes={CATEGORY_ATTRIBUTES} rootCategories={rootCategories} />
          </FilterOption>

          <FilterOption label={I18n.t('frontend.offers_index.filters.producer.title')}>
            <RefinementList searchable attribute='producer_name' sort={sortByLabel} limit={100} />
          </FilterOption>

          <ClearRefinements autoHide>
            <button className='btn btn-primary d-block mt-4'>{I18n.t('frontend.general.reset')}</button>
          </ClearRefinements>
        </div>
        <div className='col-md-6'>
          <Autocomplete onSelect={onSelect} placeholder={placeholder} Hit={Hit} />
        </div>
        <div className='col-md-3'>
          <a href='mailto:service@solartraders.com?subject=Missing%20product' className='btn btn-primary'>{missingProductButtonLabel}</a>
        </div>
      </div>
    </SearchContextProvider>
  </InstantSearch>
}

export default OffersNew
