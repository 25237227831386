import React, { useState, useEffect, useRef } from 'react'

export const FlashContext = React.createContext({})

const INITIAL_STATE = {type: null, message: null}
const TYPES = {
  success: 'success',
  notice: 'warning',
  error: 'danger'
}

const Flash = ({type, message, close}) => {
  const className = 'alert alert-' + TYPES[type]

  const handleClose = e => {
    e.stopPropagation()
    close()
  }

  return <div className={className}>
    {message}
    <button className='close' onClick={handleClose}>&times;</button>
  </div>
}

const FlashProvider = ({children}) => {
  const [state, setState] = useState(INITIAL_STATE)
  const timer             = useRef(null)

  const set = s => {
    if(timer.current) {
      clearTimeout(timer.current)
      timer.current = null
    }

    setState(s)
  }

  useEffect(() => {
    if(state.message) {
      timer.current = setTimeout(() => setState(INITIAL_STATE), 5000)
    }
  }, [state])

  const success = message => set({type: 'success', message})
  const notice  = message => set({type: 'notice', message})
  const error   = message => set({type: 'error', message})

  const close = () => set(INITIAL_STATE)

  return <>
    {state.message && <Flash type={state.type} message={state.message} close={close} />}
    <FlashContext.Provider value={{success, notice, error, close}}>
      {children}
    </FlashContext.Provider>
  </>
}

export default FlashProvider
