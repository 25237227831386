import React from 'react'

import I18n from '@/src/i18n.js.erb'

const MissingPriceCTA = ({ productUrl }) => {
  return <div>
    <div>
      <a className='btn btn-emphasized btn-rounded d-inline-flex align-items-center flex-gap-2' href={productUrl}>
        {I18n.t('frontend.offers_index.hits.login_cta.text')}
      </a>
    </div>
    <div className='text-right mt-2' dangerouslySetInnerHTML={{ __html: I18n.t('frontend.offers_index.hits.login_cta.info') }} />
  </div>
}

export default MissingPriceCTA
