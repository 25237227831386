import dayjs from "dayjs/esm/index.js"

import I18n from "@/src/i18n.js.erb"

const FORMAT = "DD.MM.YYYY"

const OfferUpdated = ({ hit }) => <>
  <strong class="d-block">{I18n.t("frontend.my_offers_index.hit.updated_at")}</strong>
  <span>{dayjs.unix(hit.updated_at_ts).format(FORMAT)}</span>
</>

export default OfferUpdated
