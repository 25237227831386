import React from 'react'

import I18n from '@/src/i18n.js.erb'

const Icon = () => <svg viewBox="0 0 420 187" style={{width: '100%'}}>
  <defs>
    <polygon id="path-1" points="173.999924 77.3085516 161 93.6860411 204.994989 125 261 50.0822671 243.309362 39 201.689038 96.4609137"></polygon>
    <filter x="-3.5%" y="-2.9%" width="107.0%" height="108.1%" filterUnits="objectBoundingBox" id="filter-2">
      <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
      <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
      <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
    </filter>
  </defs>
  <g id="Landingpage_Escrow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="Group-14" transform="translate(0.000000, 3.000000)">
      <rect id="Rectangle" fill="#003560" x="0" y="58" width="420" height="44"></rect>
      <path d="M205.19126,0 C205.19126,0 169.815871,11.7416449 122.440139,26.8579736 C122.440139,54.8995524 117.988482,76.982769 137.400226,113.14138 C152.04277,140.416315 174.639781,163.035855 205.19126,181 C235.754939,163.020951 258.68836,140.401411 273.991523,113.14138 C294.302697,76.9604134 289.506473,48.3544977 289.506473,26.8579736 C250.279594,14.0206362 205.19126,0 205.19126,0 Z" id="Path-7-Copy-2" stroke="#003560" strokeWidth="5" fill="#FFFFFF"></path>
      <g id="Path-17-Copy">
        <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
        <use fill="#95DFAD" fillRule="evenodd" xlinkHref="#path-1"></use>
      </g>
      <text id="Escrow" fontFamily="Helvetica" fontSize="28" fontWeight="normal" fill="#FFFFFF">
        <tspan x="17" y="90">Escrow</tspan>
      </text>
      <text id="Service" fontFamily="Helvetica" fontSize="28" fontWeight="normal" fill="#FFFFFF">
        <tspan x="301.317383" y="90">Service</tspan>
      </text>
    </g>
  </g>
</svg>

const Status = ({label, active, className}) => {
  const iconClassName = active ? 'card-status-icons__check card-status-icons__check--accepted' : 'card-status-icons__check'
  const wrapperClassName = className ? className + ' col-sm-4 d-flex align-items-center' : 'col-sm-4 d-flex align-items-center'

  return <div className={wrapperClassName}>
    <div className={iconClassName} />
    <div>{label}</div>
  </div>
}

const StatusCancelled = ({className}) => {
  const wrapperClassName = className ? className + ' col-sm-4 d-flex align-items-center' : 'col-sm-4 d-flex align-items-center'

  return <div className={wrapperClassName}>
    <div className='card-status-icons__check card-status-icons__check--canceled' />
    <div>{I18n.t('frontend.inquiries_index.escrow_status.cancelled')}</div>
  </div>
}

const EscrowContractStatus = ({hit}) => {
  if(hit.escrow_status !== 'null') {
    const cancelled = hit.escrow_status === 'cancelled'

    const payment_received = hit.escrow_status === 'payment_received' || hit.escrow_status === 'delivery_approved' || hit.escrow_status === 'payment_released'
    const delivery_approved = hit.escrow_status === 'delivery_approved' || hit.escrow_status === 'payment_released'
    const payment_released = hit.escrow_status === 'payment_released'

    return <div className='card-actions'>
      <div className='row'>
        <div className='col-sm-1 d-flex align-items-center justify-content-center'>
          <Icon />
        </div>
        <div className='col-sm-11'>
          {cancelled && <div className='row'>
            <StatusCancelled />
          </div>}
          {!cancelled && <div className='row'>
            <Status label={I18n.t('frontend.inquiries_index.escrow_status.payment_received')} active={payment_received} className='pl-5' />
            <Status label={I18n.t('frontend.inquiries_index.escrow_status.delivery_approved')} active={delivery_approved} />
            <Status label={I18n.t('frontend.inquiries_index.escrow_status.payment_released')} active={payment_released} />
          </div>}
        </div>
      </div>
    </div>
  }

  return null
}

export default EscrowContractStatus
