import React, { useState, useEffect } from 'react'
import { createConnector } from 'react-instantsearch-dom'

import I18n from '@/src/i18n.js.erb'

import Checkbox from '../common/Checkbox'
import ToggleRefinement from './ToggleRefinementCheckbox';

import { getDefaultMetadata } from './utils/FilterMethods'
import { useGetFacetsQuery } from '../../api/offers';

const ATTRIBUTE = 'published_until_ts'

const now = Math.floor(new Date / 1000)
const nowPlusOneWeek = now + 604800;

const connectActiveOffers = createConnector({
  displayName: 'ActiveOffers',

  getProvidedProps(props, searchState, searchResults) {
    const currentRefinement = searchState[ATTRIBUTE] || null
    const facets = searchResults && searchResults.results?.disjunctiveFacets?.find(f => f.name === ATTRIBUTE)?.data || [];
    const archivedFacets = searchResults && searchResults.results?.disjunctiveFacets?.find(f => f.name === 'archived')?.data || [];

    return {
      currentRefinement,
      facets,
      archivedFacets
    }
  },

  refine(props, searchState, nextRefinement) {
    return {
      ...searchState,
      [ATTRIBUTE]: nextRefinement
    }
  },

  getSearchParameters(searchParameters, props, searchState) {
    if (searchState[ATTRIBUTE] === 'active') {
      return searchParameters.removeNumericRefinement(ATTRIBUTE)
        .addNumericRefinement(ATTRIBUTE, '>=', now)
        .setQueryParameter('filters', 'archived:false')
    } else if (searchState[ATTRIBUTE] === 'inactive') {
      return searchParameters.removeNumericRefinement(ATTRIBUTE)
        .addNumericRefinement(ATTRIBUTE, '<=', now)
        .setQueryParameter('filters', 'archived:false')
    } else if (searchState[ATTRIBUTE] === 'expiring_soon') {
      return searchParameters.removeNumericRefinement(ATTRIBUTE)
        .addNumericRefinement(ATTRIBUTE, '<=', nowPlusOneWeek)
        .addNumericRefinement(ATTRIBUTE, '>=', now)
        .setQueryParameter('filters', 'archived:false')
    } else if (searchState[ATTRIBUTE] === 'archived') {
      return searchParameters.removeNumericRefinement(ATTRIBUTE)
        .setQueryParameter('filters', 'archived:true');
    } else {
      return searchParameters.removeNumericRefinement(props.attribute)
    }
  },

  cleanUp(props, searchState) {
    const { [ATTRIBUTE]: omit, ...nextSearchState } = searchState

    return nextSearchState
  },

  getMetadata(props, searchState) {
    return getDefaultMetadata(ATTRIBUTE, props, searchState)
  }
})

const ActiveFilter = connectActiveOffers(({ currentRefinement, refine, facets, archivedFacets }) => {
  const [facetsCounts, setFacetsCounts] = useState(null);

  const handleFilterToggle = value => e => {
    e.preventDefault()
    e.stopPropagation()

    if (currentRefinement === value) {
      refine(null)
    } else {
      refine(value)
    }
  }

  useEffect(() => {
    const facetKeys = Object.keys(facets);

    if (facetsCounts == null && facetKeys.length > 0) {
      const archivedCounts = archivedFacets['true'] || 0
      const counts = facetKeys.reduce((acc, value) => {
        if (value >= now && value <= nowPlusOneWeek) {
          acc.expiringSoon += facets[value];
        }
        if (value >= now) {
          acc.active += facets[value];
        }
        if (value < now) {
          acc.inactive += facets[value];
        }
        return acc;
      }, { active: 0, expiringSoon: 0, inactive: 0, archived: archivedCounts });


      counts.inactive = counts.inactive - archivedCounts;

      setFacetsCounts(counts);
    }
  }, [facets])

  useEffect(() => {
    refine('active');
  }, [])

  return <>
    <ul class='refinement-list'>
      <li>
        <Checkbox checked={currentRefinement === 'active'} onChange={handleFilterToggle('active')}>
          <div className='d-flex align-items-center justify-content-between flex-greed'>
            <div className='truncate'>{I18n.t('frontend.my_offers_index.filter.active')}</div>
          </div>
        </Checkbox>
      </li>

      <li>
        <Checkbox checked={currentRefinement === 'expiring_soon'} onChange={handleFilterToggle('expiring_soon')}>
          <div className='d-flex align-items-center justify-content-between flex-greed'>
            <div className='truncate'>{I18n.t('frontend.my_offers_index.filter.expiring_soon')}</div>
          </div>
        </Checkbox>
      </li>

      <li>
        <Checkbox checked={currentRefinement === 'inactive'} onChange={handleFilterToggle('inactive')}>
          <div className='d-flex align-items-center justify-content-between flex-greed'>
            <div className='truncate'>{I18n.t('frontend.my_offers_index.filter.inactive')}</div>
          </div>
        </Checkbox>
      </li>

      <li>
        <Checkbox checked={currentRefinement === 'archived'} onChange={handleFilterToggle('archived')}>
          <div className='d-flex align-items-center justify-content-between flex-greed'>
            <div className='truncate'>{I18n.t('frontend.my_offers_index.filter.archived')}</div>
          </div>
        </Checkbox>
      </li>
    </ul>
  </>
})

export default ActiveFilter
