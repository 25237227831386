import React from 'react'

import I18n from '@/src/i18n.js.erb'

import { inStock } from '../helpers'
import { GreenCheckmark, OrangeCheckmark, GrayCheckmark } from './Icons'

const calculateQuantities = offers => {
  let quantityInStock = 0
  let quantityInInflow = 0
  let offersConsidered = {}

  for(const offer of offers) {
    if(offersConsidered[offer.id]) continue
    offersConsidered[offer.id] = true

    if(inStock(offer))
      quantityInStock += offer.quantity
    else
      quantityInInflow += offer.quantity
  }

  return [quantityInStock, quantityInInflow]
}

const Quantity = ({ offers }) => {
  const [ inStock, inInflow ] = calculateQuantities(offers)

  return <div>
    {inStock > 0 && <div className='d-flex flex-gap-2 align-items-center justify-content-end'>
      <GreenCheckmark />
      {I18n.t('frontend.offers_index.hits.in_stock')}
    </div>}

    {inInflow > 0 && <div className='d-flex flex-gap-2 align-items-center justify-content-end'>
      <OrangeCheckmark />
      {I18n.t('frontend.offers_index.hits.in_in_flow')}
    </div>}

    {inStock <= 0 && inInflow <= 0 && <div className='d-flex flex-gap-2 align-items-center justify-content-end'>
      <GrayCheckmark />
      {I18n.t('frontend.offers_index.hits.sold_out')}
    </div>}
  </div>
}

export default Quantity
